import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { StorageutilityService } from "src/app/Services/storageutility.service";
import { TOAST_TYPE, ToasterService } from "src/app/Services/toaster.service";
import { StorageKey } from "src/environments/environment";

@Component({
  selector: "app-login-boxed",
  templateUrl: "./login-boxed.component.html",
  styles: [],
})
export class LoginBoxedComponent implements OnInit {
  @ViewChild("skey") private skey: ElementRef;
  @ViewChild("uname") private uname: ElementRef;
  @ViewChild("psw") private psw: ElementRef;
  userName: string = "";
  password: String = "";
  SerialKey: string = "";
  Loading: boolean = false;
  year = new Date().getFullYear();
  constructor(
    private router: Router,
    private Api: ApiService,
    private common: CommonService,
    private storage: StorageutilityService,
    private toaster: ToasterService
  ) {}
  token: String = "";
  ngOnInit() {
    var userDetails = this.storage.getValue(StorageKey.UserDetails, true);
    this.token = this.storage.getValue(StorageKey.Token, false);
    if (userDetails) {
      // this.router.navigateByUrl("dashboard");
    }
  }
  ngAfterViewInit() {
    this.uname.nativeElement.focus();
  }

  async logmein() {
    if (!this.token) {
      if (this.SerialKey.length === 0) {
        this.skey.nativeElement.focus();
        this.toaster.showToast("Please Enter Your Serial Key", 2);
        return;
      }
    }

    if (this.userName.trim().length === 0) {
      this.uname.nativeElement.focus();
      this.toaster.showToast("Please Enter Your User Name", 2);
      return;
    }

    if (this.password.trim().length === 0) {
      this.psw.nativeElement.focus();
      this.toaster.showToast("Please Enter Your Password", 2);
      return;
    }
    this.Loading = true;
    var key: any = "";
    if (this.token) {
      key = this.token;
    } else {
      key = `Basic ${this.SerialKey}`;
    }

    await this.storage.setValue(StorageKey.Token, key, false);

    (await this.Api.Login(this.userName, this.password, key)).subscribe(
      (data: any) => {
        this.Loading = false;

        if (data.statusCode == 200) {
          // this.toaster.showToast(data.message, TOAST_TYPE.Success);
          this.storage.setValue(StorageKey.UserDetails, data.data, true);
          this.Api.getCompanyCredencial(key).subscribe({
            next: (res: any) => {
              if (res.statusCode == 200) {
                localStorage.setItem("PLAN", res.data.plan);
              }
            },
            error: (err) => {
              console.log(err);
            },
          });
          setTimeout(() => {
            this.router.navigateByUrl("dashboard");
          }, 10);
        } else {
          this.storage.removeKey(StorageKey.UserDetails);
          this.toaster.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
        this.Loading = false;
        this.storage.clearLocalStorage();
        if (err.status === 401) {
          this.toaster.showToast("Please Enter Valid Details", 3);
        }
      }
    );
  }
}
