import { Component, OnInit } from "@angular/core";
import { Slider } from "src/app/InterFace/category";
import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { DataService } from "src/app/Services/data.service";
import { EventdriverService } from "src/app/Services/eventdriver.service";
import { TOAST_TYPE, ToasterService } from "src/app/Services/toaster.service";
import {
  DELETE_DESC,
  RefreshEvent,
  environment,
  modalOpen,
} from "src/environments/environment";
import swal, { SweetAlertOptions } from "sweetalert2";
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css'; // Import the default DataTables CSS

@Component({
  selector: "app-manageslider",
  templateUrl: "./manageslider.component.html",
  styleUrls: ["./manageslider.component.sass"],
})
export class ManagesliderComponent implements OnInit {
  heading = "Manage Sliders";
  subheading = "Manage the Hotel Slider you would like to organize.";
  icon = "pe-7s-monitor icon-gradient bg-happy-itmeo";
  pageid = modalOpen.SliderModal;
  SliderList:Slider[]=[];
  ApiUrl: string = environment.ImagePath;
  SkletonLoader:boolean=true;
  Plan:any=0;
  constructor(
    private eventDriver: EventdriverService,
    private toasterService: ToasterService,
    private common: CommonService,
    private data: DataService,
    private Api: ApiService
  ) {
    
    this.eventDriver.refreshevent().subscribe((data) => {
      if (data == RefreshEvent.Slider) {
        this.ListSliders();
      }
    });
    this.Plan= localStorage.getItem('PLAN'); 
      this.data.getPlan();
   
  }

  ngOnInit(): void {
    this.ListSliders();
  }

  openModel() {
    this.eventDriver.triggerEvent({
      IsClosed: false,
      Modal: RefreshEvent.Slider,
    });
  }

  do__Delete(id) {
    let soption: SweetAlertOptions = {
      title: `${DELETE_DESC.title}`,
      text: `${DELETE_DESC.desc} Slider Image !`,
      icon: "error",
      showConfirmButton: true,
      showCancelButton: true,
    };

    swal.fire(soption).then((willDelete) => {
      if (willDelete.value) {
        this.DeleteSlider(id.sliderId);
      } else {
      }
 
    });
  }
  async loadDataTable() {
    // new DataTable('#example');
    const tableElement = $("#slider");

    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable("#slider")) {
      // Destroy the existing DataTable
      tableElement.DataTable().destroy();
    }
    setTimeout(() => {
      $("#slider").DataTable({
        pagingType: "full_numbers",
        pageLength: 5,
        processing: true,
        lengthMenu: [5, 10, 25],
        dom: "Blfrtip",
        buttons: [
          {
            extend: "print",
            text: " Print",
            className: "table-button button btn btn-primary mb-2",
          },
          {
            extend: "excel",
            text: " Excel",
            className: "table-button button btn btn-success mb-2",
          },
        ],
        columnDefs: [
          { 
            orderable: false, 
            targets: -1 // This targets the last column
          }
        ]
      } as any);
      setTimeout(() => {
        this.SkletonLoader=false;
        const searchInput = $('#slider_filter input');
        // searchInput.wrap('<div class="search-wrapper"></div>');
        searchInput.after('<span class="searchTableIcon"><i class="fa fa-search"></i></span>');
      }, 10); // Use 'as any' to avoid type checking for this specific object
    }, 1);
  }
  TableLoad: string = "";
  ListSliders() {
    this.SkletonLoader=true;
    this.Api.getSliders().subscribe(
      (data: any) => {
        
        if (data.statusCode == 200) {
          this.SliderList = data.data;
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
        this.loadDataTable();
     
      },
      (err) => {
        console.log(err);
      
      }
    );
  }
  EditSlider(item: any) {
    this.data.SliderModalData = item;
    this.openModel();
  }
  changeStatus(item) {
    this.common.showloading();
    this.Api.updateSlider(item).subscribe(
      (data: any) => {
      
        this.common.dismissloading();
     
        if (data.statusCode == 200) {
          this.ListSliders();
          // this.toasterService.showToast(data.message, TOAST_TYPE.Success);
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
        this.common.dismissloading();
        console.log(err);
      }
    );
  }
  DeleteSlider(id: any) {
    this.common.showloading();
    this.Api.deleteSlider(id).subscribe(
      (data: any) => {
       
        this.common.dismissloading();
        if (data.statusCode == 200) {
          this.toasterService.showToast(data.message, TOAST_TYPE.Warning);
          this.ListSliders();
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
        this.common.dismissloading();
        console.log(err);
      }
    );
  }
}
