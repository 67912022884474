import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { EventdriverService } from "src/app/Services/eventdriver.service";
import { StorageutilityService } from "src/app/Services/storageutility.service";
import { TOAST_TYPE, ToasterService } from "src/app/Services/toaster.service";
import { environment, StorageKey } from "src/environments/environment";

@Component({
  selector: "app-search-box",
  templateUrl: "./search-box.component.html",
  styleUrls: ["./search-box.scss"],
})
export class SearchBoxComponent implements OnInit {
  @ViewChild('search') search!:ElementRef; 
  isAdmin: boolean = false;
  public isActive: any;
  SubscriptionsList: any = [];
  FilterSubscription: any = [];
  filterText:string = "";
  Imagepath: string = environment.ImagePath;
  constructor(
    private storage: StorageutilityService,
    private Api: ApiService,
    private toaster: ToasterService,
    private modalService: NgbModal,private eventmapper:EventdriverService,public Common:CommonService
  ) {
    var data = this.storage.getValue(StorageKey.UserDetails, true);
    if (data) {
      this.isAdmin = data.isSuperAdmin;
    }
    if (this.isAdmin) this.getCompanyList(); else this.getHotelDetails();
    this.eventmapper.getAdminSearch().subscribe((data)=>{
      if(data !==null){
        this.getCompanyList();
      }
    })
    console.log(data)
  }

  ngOnInit() {}
  getCompanyList() {
    this.Api.getAllCompany().subscribe(
      (data: any) => {
        
        if (data.statusCode == 200) {
          this.SubscriptionsList = data.data;
        }
      },
      (err) => {
       
      }
    );
  }
  filterData(ev: Event) {
   
    const target = ev.target as HTMLInputElement;
    const filterValue = target.value.toLowerCase();

    this.FilterSubscription = this.SubscriptionsList.filter((item) => {
      const itemName = item.companyName ? item.companyName.toLowerCase() : "";
      return itemName.includes(filterValue);
    });
  }
  HotelDetail: any = {};
  openModal(item, content) {
    this.HotelDetail = item;
    this.getCredentcial(item.companyId);
    this.modalService.open(content, {
      size: "lg",
    });
  }
  openSearch(){
    this.isActive=true;
    setTimeout(() => {
      this.search.nativeElement.focus();
    }, 100);
  }
  closeSearch(){
    this.filterText='';
    this.isActive=false;
  }
  userCreditial:any={};
  LoadingText:boolean=false;
  getCredentcial(id){
    this.LoadingText=true;
    this.Api.getCompanyCredencial(id).subscribe((data:any)=>{
      this.LoadingText=false;
      
      if (data.statusCode==200) {
        this.userCreditial = data.data;
      }else{
        this.toaster.showToast(data.message,TOAST_TYPE.Error)
      }
    },(err)=>{
      
    })
  }
  openQR(content,){
    this.modalService.open(content, {
      size: "lg",
    });
  }
  HotelDetails:any={};
  getHotelDetails() {
    var UserData = this.storage.getValue(StorageKey.UserDetails, true);
    this.Api.getCompanyFromId(UserData.companyId).subscribe(
      (data: any) => {
         
        if (data.statusCode == 200) {
          this.HotelDetails = data.data;
        } else {
          this.toaster.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  downloadQRimage(val, id) {
    this.Api.downloadQR(this.HotelDetails.companyId).subscribe({
      next: (data: Blob) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement("a");
        a.href = url;
        a.download = "image.png"; // Set the filename here
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
