import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class EventdriverService {
  private eventDirector = new Subject<any>();
  public updated = new Subject<any>();
  public AdminSearch = new Subject<any>();
  public ModalDataTransfer = {}

  constructor() {}

  triggerEvent($payload: any) {
    this.eventDirector.next($payload);
  }

  receiveData(): Subject<any> {
    return this.eventDirector;
  }
  refreshevent() {
    return this.updated;
  }
  getModalData(){
    return this.ModalDataTransfer
  }
  getAdminSearch(){
    return this.AdminSearch
  }
}
