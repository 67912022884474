import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { TimeSlots } from "src/app/InterFace/category";
import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { DataService } from "src/app/Services/data.service";
import { EventdriverService } from "src/app/Services/eventdriver.service";
import { ToasterService, TOAST_TYPE } from "src/app/Services/toaster.service";
import {
  RefreshEvent,
  environment,
  modalOpen,
} from "src/environments/environment";

@Component({
  selector: "app-addtimeslot",
  templateUrl: "./addtimeslot.component.html",
  styleUrls: ["./addtimeslot.component.sass"],
})
export class AddtimeslotComponent implements OnInit {
 
  SlotItem: TimeSlots;
  @Input() products = {
    ProductId: 0,
    Description: "",
    ProductName: "",
    IsActive: false,
  };

  @ViewChild("txtSlotName") private txtSlotName: ElementRef;
  @ViewChild("txtStartTime") private txtStartTime: ElementRef;
  @ViewChild("txtEndTime") private txtEndTime: ElementRef;
  loading:boolean=false;
  constructor(
    private eventDriver: EventdriverService,
    private toasterService: ToasterService,
    private Api: ApiService,
    private Data: DataService,
    private common: CommonService
  ) {
    this.SlotItem= this.Data.TimeSlotModalData;
    if(this.SlotItem==undefined){
      this.SlotItem = {
        slotName: "",
        companyId: "",
        endTime: "",
        startTime: "",
        slotId: "",
        isActive: true,
        isArchived: false,
      };
    } 

  }

  ngOnInit(): void {
  
  }
  ngAfterViewInit() { this.txtSlotName.nativeElement.focus();}
  closeModal(val) {
    this.eventDriver.triggerEvent({
      IsClosed: val,
      Modal: RefreshEvent.ProductSlot,
    });
    this.SlotItem = {
      slotName: "",
      companyId: "",
      endTime: "",
      startTime: "",
      slotId: "",
      isActive: true,
      isArchived: false,
    };
    if(val)this.txtSlotName.nativeElement.focus();
  }

  do__Save(val) {
    if (this.isValidObj()) {
      this.loading=true;
      this.SlotItem.companyId=this.Api.getCompanyId();
      this.Api.addTimeSlots(this.SlotItem).subscribe(
        (data: any) => {
          this.loading=false;
          this.eventDriver.updated.next(RefreshEvent.ProductSlot); 
          if (data.statusCode==200) {
            this.toasterService.showToast(data.message, TOAST_TYPE.Success);
            this.closeModal(val);
            this.Data.TimeSlotModalData = undefined;
          } else {
            this.toasterService.showToast(data.message, TOAST_TYPE.Error);
          }
        },
        (err) => {
          console.log(err);
          this.loading=false;
        }
      );
    }
  }
  UpdateSlots() {
    if (this.isValidObj) {
      this.Api.updateTimeSlots(this.SlotItem).subscribe(
        (data: any) => { 
          if (data.statusCode==200) {
            this.toasterService.showToast(data.message,  TOAST_TYPE.Success);
            this.closeModal(true);
            this.Data.TimeSlotModalData = undefined;
          } else {
            this.toasterService.showToast(data.message,  TOAST_TYPE.Error);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  isValidObj() {
    if (this.SlotItem.slotName.length == 0) {
      this.txtSlotName.nativeElement.focus();
      this.toasterService.showToast(
        "Please enter Slot name to proceed",
        TOAST_TYPE.Warning
      );
      return false;
    }
    if (this.SlotItem.startTime.length == 0) {
      this.txtStartTime.nativeElement.focus();
      this.toasterService.showToast(
        "Please Select Start Time to proceed",
        TOAST_TYPE.Warning
      );
      return false;
    }
    if (this.SlotItem.endTime.length == 0) {
      this.txtEndTime.nativeElement.focus();
      this.toasterService.showToast(
        "Please Select End Time to proceed",
        TOAST_TYPE.Warning
      );
      return false;
    }
    return true
  }

  filesupdated() {
    var data = {
      pageid: modalOpen.SlotModal,
      update: true,
    };
    this.eventDriver.updated.next(data);
  }
}
