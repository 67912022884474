<div class="container mt-3">
    <div class="row">
        <div class="col-12">
            <div class="main-card mb-3 card p-2" [ngClass]="SkletonLoader ? 'd-none' : ''">
                <div class="card-header">
                  <div class="card-header-title font-size-lg text-capitalize fw-normal">
                    Manage Subscriptions
                  </div>
                </div>
                <div class="table-responsive mt-3">
                  <table
                    class="align-middle text-truncate mb-0 table table-borderless table-hover table table-striped table-bordered"
                    id="clientList"
                    style="width: 100%"
                  >
                    <thead>
                      <tr>
                        <th class="text-left">S.No</th>
                        <th class="text-left">Subscribers Name</th> 
                        <th class="text-left">Url</th>
                        <th class="text-left">AC Url</th> 
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let item of RestaruntList; index as i" 
                        class="{{ item.status == 3 ? 'bg-light' : '' }}"
                      >
                        <td class="text-left text-muted" style="width: 80px">{{ i + 1 }}</td>
                        <td class="text-left">{{ item.companyName }}</td>  
              
                        <td class="text-left" style="cursor: pointer">
                          <span
                            (click)="copyURLToClipboard(item.qrURL)"
                            class="cursor-pointer badge bg-primary"
                          >
                            Copy Url <i class="pe-7s-copy-file fa-lg ms-1"></i
                          ></span>
                          <button (click)="openUrl(item.qrURL)" class="btn btn-sm btn-success ms-3">open</button>
                        </td>
                        <td class="text-left" style="cursor: pointer">
                          <span
                            (click)="copyURLToClipboard(item.acQrURL)"
                            class="cursor-pointer badge bg-primary"
                          >
                            Copy Url <i class="pe-7s-copy-file fa-lg ms-1"></i
                          ></span>
                          <button (click)="openUrl(item.acQrURL)" class="btn btn-sm btn-success ms-3">open</button>
                        </td> 
                       
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
        </div>
    </div>
</div>