import { Component, HostListener, Input } from "@angular/core";
import { faStar, faPlus, faCopy } from "@fortawesome/free-solid-svg-icons";
import {
  animate,
  query,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { EventdriverService } from "src/app/Services/eventdriver.service";
import { RefreshEvent, modalOpen } from "src/environments/environment";
import { DataService } from "src/app/Services/data.service";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "src/app/Services/api.service";
import { TOAST_TYPE, ToasterService } from "src/app/Services/toaster.service";
import { CommonService } from "src/app/Services/common.service";
import 'jquery-ui-dist/jquery-ui';
declare var $: any;
@Component({
  selector: "app-page-title",
  templateUrl: "./page-title.component.html",
  styleUrls: ["./style.css"],
})
export class PageTitleComponent {
  faStar = faStar;
  faPlus = faPlus;
  faexcel = faCopy;

  @Input() heading;
  @Input() subheading;
  @Input() icon;
  @Input() pageid;
  @Input() addbtn: boolean = false;
  @Input() bulkbtn: boolean = false;
  excelName: string = "";
  hideDiv: boolean = false;
  isCategoryOpen = false;
  openProductModal: boolean = false;
  openSlotModal: boolean = false;
  openSliderModal: boolean = false;
  openTableModal: boolean = false;
  OpenSubcatmodal:boolean=false;

  constructor(
    private eventDriver: EventdriverService,
    private Data: DataService,
    private router: Router,
    private modalService: NgbModal,
    private http: HttpClient,
    private Api: ApiService,
    private toaster: ToasterService,
    private common: CommonService
  ) {
    this.isCategoryOpen = false;

    this.eventDriver.receiveData().subscribe((data) => {
      if (data.Modal == RefreshEvent.Category) {
        this.isCategoryOpen = !data.IsClosed;
      } else if (data.Modal == RefreshEvent.Product) {
        this.openProductModal = !data.IsClosed;
      } else if (data.Modal == RefreshEvent.ProductSlot) {
        this.openSlotModal = !data.IsClosed;
      } else if (data.Modal == RefreshEvent.Slider) {
        this.openSliderModal = !data.IsClosed;
      } else if (data.Modal == RefreshEvent.Tables) {
        this.openTableModal = !data.IsClosed; 
      } else if (data.Modal == RefreshEvent.SubCategory) {
        this.OpenSubcatmodal = !data.IsClosed;
      }
      // else{
      //   setTimeout(() => {
      //     this.router.navigateByUrl('superadmin/addrestarunt');
      //   }, 10);

      // }
    });
  }
  @HostListener('document:keydown.escape', ['$event'])
  handleEscapeKey(event: KeyboardEvent) {
    this.isCategoryOpen = false;
    this.openProductModal =false;
    this.openSlotModal =false;
    this.openSliderModal =false;
    this.openTableModal = false;
    this.OpenSubcatmodal=false
 
  }
  do__CreateNow($pageid) {
    this.Data.CategoryModalData = undefined;
    this.Data.TimeSlotModalData = undefined;
    this.Data.ProductModalData = undefined;
    this.Data.SliderModalData = undefined;
    this.Data.TableModalData = undefined;
    this.Data.SubCategoryModalData=undefined;
    this.isCategoryOpen = $pageid == modalOpen.CategoryModal;
    this.openProductModal = $pageid == modalOpen.ProductModal;
    this.openSlotModal = $pageid == modalOpen.SlotModal;
    this.openSliderModal = $pageid == modalOpen.SliderModal;
    this.openTableModal = $pageid == modalOpen.Table;
    this.OpenSubcatmodal=$pageid==modalOpen.SubCategoryModal;
    if (this.pageid == "admin") {
      this.router.navigateByUrl("superadmin/addrestarunt");
    }
  }

  toggle() {
    this.isCategoryOpen = !this.isCategoryOpen;
  }

  bulkupload() {
    document.getElementById("bulkupload").click();
  }
  loading: boolean = false;

  selectedFile: File | null = null;
  getfile(event) {
    const file: File = event.target.files[0];
    const validTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    if (file && validTypes.includes(file.type)) {
      this.selectedFile = file;
      this.excelName = this.selectedFile.name;
    } else {
      this.selectedFile = null;
      this.toaster.showToast(
        "Please select a valid Excel file (xlsx or xls).",
        TOAST_TYPE.Error
      );
    }
    this.productNames = "";
  }
  productNames: string = "";
  UploadExcel() {
    const formData = new FormData();
     
    formData.append("CompanyId", this.Api.getCompanyId());
    formData.append("Excel", this.selectedFile, this.selectedFile.name);

    this.loading = true;

    if (this.pageid == "product") {
      this.Api.ProductBulkUpload(formData).subscribe(
        (data: any) => {
          
          this.loading = false;
          if (data.statusCode==200) {
            this.toaster.showToast(data.message, TOAST_TYPE.Success);
            this.excelName = "";
            this.selectedFile = null;
            this.productNames = data.message;
          } else {
            this.toaster.showToast(data.message, TOAST_TYPE.Error);
          }
        },
        (err) => {
          this.loading = false;
         
        }
      );
    } else {
      this.Api.CategoryBulkUpload(formData).subscribe(
        (data: any) => {
          
          this.loading = false;
          if (data.statusCode==200) {
            this.toaster.showToast(data.message, TOAST_TYPE.Success);
            this.excelName = "";
            this.selectedFile = null;
          } else {
            this.toaster.showToast(data.message, TOAST_TYPE.Error);
          }
        },
        (err) => {
          this.loading = false; 
        }
      );
    }
  }

  downloadExcel() {
    // Replace 'sample.xls' with your actual Excel file name
    let filename = "sample_Category.xlsx";
    if (this.pageid == "product") {
      filename = "sample_Product.xlsx";
    }
    const filepath = `assets/images/Excel/${filename}`;

    this.http
      .get(filepath, {
        responseType: "blob", // Set response type to Blob
      })
      .subscribe(
        (response: Blob) => {
          // Create object URL from the Blob
          const blobURL = window.URL.createObjectURL(response);

          // Create a link element
          const link = document.createElement("a");
          link.href = blobURL;

          // Set the filename to download
          link.setAttribute("download", filename);

          // Append the link to the body
          document.body.appendChild(link);

          // Trigger the click event on the link
          link.click();

          // Clean up
          document.body.removeChild(link);
          window.URL.revokeObjectURL(blobURL);
        },
        (error) => {
          console.error("Error downloading file:", error);
        }
      );
  }

  open(content) {
    this.modalService
      .open(content, {
        size: "lg",
        backdrop: 'static',
        keyboard: false,
        scrollable:true,
        animation:true,
        centered:true,  
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
      this.ngAfterViewInit();
  }
  ngAfterViewInit() {
    $(document).ready(function() {
      let modalContent = $('.modal-dialog');
      let modalHeader = $('.modal-header');
      modalHeader.addClass('cursor-all-scroll');
      modalContent.draggable({
        handle: '.modal-header'
      });
    });
  }
}
