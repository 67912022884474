import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { CommonService } from 'src/app/Services/common.service';
import { DataService } from 'src/app/Services/data.service';
import { EventdriverService } from 'src/app/Services/eventdriver.service';
import { ToasterService, TOAST_TYPE } from 'src/app/Services/toaster.service';

@Component({
  selector: 'app-subcategory-reorder',
  templateUrl: './subcategory-reorder.component.html',
  styleUrls: ['./subcategory-reorder.component.scss']
})
export class SubcategoryReorderComponent implements OnInit {


  categoryItem: Category[]=[];
 
  loading:boolean=false; 
  @Output() newItemEvent = new EventEmitter<string>();;
  @ViewChild("categoryNameInput") private txtCategoryName: ElementRef;
  @ViewChild("DisplayInput") private DisplayInput: ElementRef;
  categories: Category[] = [
    {
      id: 1,
      name: 'Category 1',
      expanded: false,
      products: [
        { id: 1, name: 'Subcategory 1' },
        { id: 2, name: 'Subcategory 2' },
      ]
    },
    {
      id: 2,
      name: 'Category 2',
      expanded: false,
      products: [
        { id: 3, name: 'Subcategory 3' },
        { id: 4, name: 'Subcategory 4' }
      ]
    }
  ];
  constructor(
    private eventDriver: EventdriverService,
    private toasterService: ToasterService,
    private Data: DataService,
    private Api: ApiService,
    public common: CommonService,
  ) { 
   this.ListCategories()
  } 
  ngOnInit(): void {}
 

  closeModal() {
    // this.eventDriver.triggerEvent({
    //   IsClosed: val,
    //   Modal: RefreshEvent.Category,
    // });
 
    this.newItemEvent.emit('false');
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   console.log(this.categoryItem, event.previousIndex, event.currentIndex);
  //   moveItemInArray(this.categoryItem, event.previousIndex, event.currentIndex);
  // } 
    // drop(event: CdkDragDrop<string[]>) {
    //   console.log(this.categoryItem, event.previousIndex, event.currentIndex);
    //   moveItemInArray(this.categoryItem, event.previousIndex, event.currentIndex);
    // }
   do__Save(val:boolean) {
    return
 
  }

  ListCategories() {
    this.loading=true;
    this.Api.getCategory().subscribe(
      (data: any) => {
        this.loading=false;
        // var data=JSON.parse(res);
        if (data.statusCode == 200) {
          this.categoryItem = data.data;
         
          
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
     
        
      
      },
      (err) => {
        console.log(err,"API ERROR");
        this.loading=false;
      }
    );
  }
 
  toggleCategory(index: number) {
    this.categories[index].expanded = !this.categories[index].expanded;
  }

  drop(event: CdkDragDrop<Product[]>, categoryIndex: number) {
    const category = this.categories[categoryIndex];
    if (event.previousContainer === event.container) {
      moveItemInArray(category.products, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
}
interface Product {
  id: number;
  name: string;
}

interface Category {
  id: number;
  name: string;
  expanded: boolean;
  products: Product[];
}
