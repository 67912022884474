import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { TOAST_TYPE, ToasterService } from "./toaster.service";
@Injectable({
  providedIn: "root",
})
export class CommonService {
  loader = new BehaviorSubject(false);
  constructor(
    private datePipe: DatePipe,
    private toasterService: ToasterService
  ) {}

  watchLoading() {
    return this.loader.asObservable();
  }
  async showloading() {
    this.loader.next(true);
  }
  async dismissloading() {
    setTimeout(() => {
      this.loader.next(false);
    }, 200);
  }

  onInputNumber(event: any, name) {
    name = event.target.value.replace(/[^0-9]*/g, "");
    return name;
  }
  allowonlynumeric(event: any) {
    const pattern = /[0-9.]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  allowonlynumericMobile(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  convertTo12HourFormat(timeString: string): string {
    const date = new Date(`1970-01-01T${timeString}:00Z`);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // Adjust for midnight (0 hours)

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${formattedMinutes} ${period}`;
  }
  convertAndFormatDate(dateString: string): string {
    const timestampMatch = /\/Date\((\d+)\)\//.exec(dateString);
    if (!timestampMatch) {
      return "Invalid date";
    }

    const timestamp = parseInt(timestampMatch[1], 10);
    const date = new Date(timestamp);

    // Use Angular's DatePipe to format the date
    return this.datePipe.transform(date, "mediumDate"); // Choose your desired format
  }
  copyURLToClipboard(url: string): void {
    this.toasterService.showToast("Copy to Clipboard...", TOAST_TYPE.Success);
    const textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  }
  
}
