<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Controls Types</h5>
        <form>
          <div class="position-relative mb-3">
            <label for="exampleEmail" class="form-label">Email</label>
            <input name="email"  id="exampleEmail" placeholder="with a placeholder" type="email" class="form-control">
          </div>
          <div class="position-relative mb-3">
            <label for="examplePassword" class="form-label">Password</label>
            <input name="password" id="examplePassword" placeholder="password placeholder" type="password" class="form-control">
          </div>
          <div class="position-relative mb-3">
            <label for="exampleSelect" class="form-label">Select</label>
            <select name="select" id="exampleSelect" class="form-select">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
          <div class="position-relative mb-3">
            <label for="exampleSelectMulti" class="form-label">Select Multiple</label>
            <select multiple="multiple" name="selectMulti" id="exampleSelectMulti" class="form-select">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
          <div class="position-relative mb-3">
            <label for="exampleText" class="form-label">Text Area</label>
            <textarea name="text" id="exampleText" class="form-control"></textarea>
          </div>
          <div class="position-relative mb-3">
            <label for="exampleFile" class="form-label">File</label>
            <input name="file" id="exampleFile" type="file" class="form-control">
            <small class="form-text text-muted">This is some placeholder block-level help text for the above
              input. It's a bit lighter and easily wraps to a new line.
            </small>
          </div>
          <button class="mt-1 btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Sizing</h5>
        <form>
          <input placeholder="lg" type="text" class="mb-2 form-control-lg form-control">
          <input placeholder="default" type="text" class="mb-2 form-control">
          <input placeholder="sm" type="text" class="mb-2 form-control-sm form-control">
          <div class="divider"></div>
          <select class="mb-2 form-control-lg form-select">
            <option>Large Select</option>
          </select>
          <select class="mb-2 form-select">
            <option>Default Select</option>
          </select>
          <select class="form-control-sm form-select">
            <option>Small Select</option>
          </select>
        </form>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Checkboxes &amp; Radios</h5>
        <form>
          <fieldset class="position-relative mb-3">
            <div class="position-relative form-check">
              <label class="form-label form-check-label">
                <input name="radio1" type="radio" class="form-check-input">
                Option one is this and that—be sure to include why it's great
              </label>
            </div>
            <div class="position-relative form-check">
              <label class="form-label form-check-label">
                <input name="radio1" type="radio" class="form-check-input">
                Option two can be something else and selecting it will deselect option one
              </label>
            </div>
            <div class="position-relative form-check disabled">
              <label class="form-label form-check-label">
                <input name="radio1" disabled="disabled" type="radio" class="form-check-input"> Option three is
                disabled
              </label>
            </div>
          </fieldset>
          <div class="position-relative form-check">
            <label class="form-label form-check-label">
              <input type="checkbox" class="form-check-input">
              Check me out
            </label>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<form>
  <div class="row">
    <div class="col-md-6">
      <div class="main-card mb-3 card">
        <div class="card-body">
          <h5 class="card-title">Checkboxes</h5>
          <div class="position-relative mb-3">
            <div>
              <div class="custom-checkbox custom-control form-check">
                <input type="checkbox" id="exampleCustomCheckbox" class="form-check-input">
                <label for="exampleCustomCheckbox"class="form-label form-check-label">Check this custom checkbox</label>
              </div>
              <div class="custom-checkbox custom-control form-check">
                <input type="checkbox" id="exampleCustomCheckbox2"class="form-check-input">
                <label for="exampleCustomCheckbox2" class="form-label form-check-label">Or this one</label>
              </div>
              <div class="custom-checkbox custom-control form-check">
                <input type="checkbox" id="exampleCustomCheckbox3" disabled="disabled" class="form-check-input">
                <label for="exampleCustomCheckbox3" class="form-label form-check-label">But not this disabled one</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-card mb-3 card">
        <div class="card-body">
          <h5 class="card-title">Inline</h5>
          <div class="position-relative ">
            <div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                <label class="form-check-label" for="inlineCheckbox1">An inline custom input</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                <label class="form-check-label" for="inlineCheckbox2">and another one</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="main-card mb-3 card">
        <div class="card-body">
          <h5 class="card-title">Radios</h5>
          <div class="position-relative mb-3">
            <div>
              <div class="custom-radio custom-control form-check">
                <input type="radio" id="exampleCustomRadio" name="customRadio" class="form-check-input">
                <label for="exampleCustomRadio" class="form-label form-check-label">Select this custom radio</label>
              </div>
              <div class="custom-radio custom-control form-check">
                <input type="radio" id="exampleCustomRadio2" name="customRadio" class="form-check-input">
                <label for="exampleCustomRadio2" class="form-label form-check-label">Or this one</label>
              </div>
              <div class="custom-radio custom-control form-check">
                <input type="radio" id="exampleCustomRadio3" disabled="disabled" class="form-check-input">
                <label for="exampleCustomRadio3" class="form-label form-check-label">But not this disabled one</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-card mb-3 card">
        <div class="card-body">
          <h5 class="card-title">Form Select</h5>
          <div class="row">
            <div class="col-md-6">
              <div class="position-relative mb-3">
                <label for="exampleCustomSelect" class="form-label">Custom Select</label>
                <select type="select" id="exampleCustomSelect" name="customSelect" class="form-select">
                  <option value="">Select</option>
                  <option>Value 1</option>
                  <option>Value 2</option>
                  <option>Value 3</option>
                  <option>Value 4</option>
                  <option>Value 5</option>
                </select>
              </div>
              <div class="position-relative mb-3">
                <label for="exampleCustomMutlipleSelect" class="form-label">Custom Multiple Select</label>
                <select multiple="multiple" type="select" id="exampleCustomMutlipleSelect" name="customSelect" class="form-select">
                  <option value="">Select</option>
                  <option>Value 1</option>
                  <option>Value 2</option>
                  <option>Value 3</option>
                  <option>Value 4</option>
                  <option>Value 5</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="position-relative mb-3">
                <label for="exampleCustomSelectDisabled" class="form-label">Custom Select Disabled</label>
                <select type="select" id="exampleCustomSelectDisabled" name="customSelect" disabled="disabled" class="form-select">
                  <option value="">Select</option>
                  <option>Value 1</option>
                  <option>Value 2</option>
                  <option>Value 3</option>
                  <option>Value 4</option>
                  <option>Value 5</option>
                </select>
              </div>
              <div class="position-relative mb-3">
                <label for="exampleCustomMutlipleSelectDisabled" class="form-label">Custom Multiple Select Disabled</label>
                <select multiple="multiple" type="select" id="exampleCustomMutlipleSelectDisabled" name="customSelect" disabled="disabled" class="form-select">
                  <option value="">Select</option>
                  <option>Value 1</option>
                  <option>Value 2</option>
                  <option>Value 3</option>
                  <option>Value 4</option>
                  <option>Value 5</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="row">
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Input Groups</h5>
        <div>
          <div class="input-group">
            <div class="input-group-text">
              <span class="">@</span>
            </div>
            <input placeholder="username" type="text" class="form-control">
          </div>
          <br>
          <div class="input-group">
            <input placeholder="username" type="text" class="form-control">
            <div class="input-group-text">
              <span class="">@example.com</span>
            </div>
          </div>
          <br>
          <div class="input-group">
            <div class="input-group-text">
              <span class="">$</span>
              <span class="">$</span>
            </div>
            <input placeholder="Dolla dolla billz yo!" type="text" class="form-control">
            <div class="input-group-text">
              <span class="">$</span>
              <span class="">$</span>
            </div>
          </div>
          <br>
          <div class="input-group">
            <div class="input-group-text">
              <span class="">$</span>
            </div>
            <input placeholder="Amount" step="1" type="number" class="form-control">
            <div class="input-group-text">
              <span class="">.00</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Input Group Button Dropdown</h5>
        <div role="group" class="input-group">
          <div class="">
            <button type="button" class="btn btn-info">
              Button
            </button>
          </div>
          <input type="text" class="form-control">
          <div class="">
            <button type="button" class="btn btn-outline-secondary">
              Button
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Input Group Button Shorthand</h5>
        <div>
          <div class="input-group">
            <div class="">
              <button class="btn btn-secondary">To the Left!</button>
            </div>
            <input type="text" class="form-control"></div>
          <br>
          <div class="input-group">
            <input type="text" class="form-control">
            <div class="">
              <button class="btn btn-secondary">To the Right!</button>
            </div>
          </div>
          <br>
          <div class="input-group">
            <div class="">
              <button class="btn btn-danger">To the Left!</button>
            </div>
            <input placeholder="and..." type="text" class="form-control">
            <div class="">
              <button class="btn btn-success">To the Right!</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Input Group Sizing</h5>
        <div>
          <div class="input-group input-group-lg">
            <div class="input-group-text">
              <span class="">@lg</span>
            </div>
            <input type="text" class="form-control">
          </div>
          <br>
          <div class="input-group">
            <div class="input-group-text">
              <span class="">@normal</span>
            </div>
            <input type="text" class="form-control">
          </div>
          <br>
          <div class="input-group input-group-sm">
            <div class="input-group-text">
              <span class="">@sm</span>
            </div>
            <input type="text" class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Input Group Addon</h5>
        <div>
          <div class="input-group">
            <div class="input-group-text">
              <span class="">To the Left!</span>
            </div>
            <input type="text" class="form-control">
          </div>
          <br>
          <div class="input-group">
            <input type="text" class="form-control">
            <div class="input-group-text">
              <span class="">To the Right!</span>
            </div>
          </div>
          <br>
          <div class="input-group">
            <div class="input-group-text">
              <span class="">To the Left!</span>
            </div>
            <input placeholder="and..." type="text" class="form-control">
            <div class="input-group-text">
              <span class="">To the Right!</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Input Group Button</h5>
        <div>
          <div class="input-group">
            <button class="btn btn-secondary">I'm a button</button>
            <input type="text" class="form-control"></div>
          <br>
          <div role="group" class="input-group">
            <input type="text" class="form-control">
            <button type="button" class="btn btn-secondary">
              Button
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
