import { Component } from "@angular/core";
import { CommonService } from "./Services/common.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.css"],
})
export class AppComponent {
  loader: boolean = false;
  constructor(private common: CommonService) {
    // this.watchLoading()
  }
  title = "";

  // watchLoading() {
  //   this.common.watchLoading().subscribe((data: any) => {
  //     this.loader = data;

  //   });
  // }
}
