import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { faStar, faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HotelDetails } from 'src/app/InterFace/category';
import { ApiService } from 'src/app/Services/api.service';
import { CommonService } from 'src/app/Services/common.service';
import { EventdriverService } from 'src/app/Services/eventdriver.service';
import { StorageutilityService } from 'src/app/Services/storageutility.service';
import { ToasterService, TOAST_TYPE } from 'src/app/Services/toaster.service';
import { ValidateService } from 'src/app/Services/validate.service';
import { SUBSCRIPTION_STATUS } from 'src/environments/environment';
import swal, { SweetAlertOptions, SweetAlertIcon } from "sweetalert2"; 
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css'; // Import the default DataTables CSS
declare var $: any;
@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.css']
})
export class CurrencyComponent implements OnInit {

  heading = "Manage Restaraunt";
  subheading =
    " Add any pertinent details or updates about restaurant listings here for internal reference.";
  icon = "pe-7s-graph1 icon-gradient bg-tempting-azure";
  faStar = faStar;
  faPlus = faPlus;
  SkletonLoader: boolean = true;
  CurrencyList: any = [];
  // @ViewChild("bname") bnameInput: ElementRef;
  @ViewChild("bname") bname!: ElementRef;
  @ViewChild("contactno") contactnoInput: ElementRef;
  @ViewChild("skey") skeyInput: ElementRef;
  @ViewChild("email") emailInput: ElementRef;
  private modalRef: NgbModalRef | undefined;

  @ViewChild("content") content!: ElementRef;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private toasterService: ToasterService,
    private Api: ApiService,
    private storage: StorageutilityService,
    private validator: ValidateService,
    public common: CommonService,
    private eventmapper: EventdriverService
  ) {}

  ngOnInit(): void {
    this.getCurrencyList();
   
  }

  ngAfterViewInit() {
    $(document).ready(function() {
      let modalContent = $('.modal-dialog');
      let modalHeader = $('.modal-header');
      modalHeader.addClass('cursor-all-scroll');
      modalContent.draggable({
        handle: '.modal-header'
      });
    });
  }
  getCurrencyList() {
    this.SkletonLoader = true;
    this.Api.GetCurrency().subscribe(
      (res: any) => {
        this.SkletonLoader = false;
        if (res.statusCode == 200) {
          this.CurrencyList = res.data;
          this.loadDataTable();
        } else {
          this.toasterService.showToast(res.message, TOAST_TYPE.Error);
        }
         
      },
      (err) => {
        console.log(err);
        this.SkletonLoader = false;
      }
    );
  }
  updateHotel(item: any, content) {
    this.currencyDetail = item; 
    this.openLarge(content, 1);
  }
  showDetails(item, content) { 
    this.currencyDetail = item;
    this.openLarge(content, 1);
  }
  async loadDataTable() {
    // new DataTable('#example');
    // new DataTable('#example');
    const tableElement = $("#CurrencyList");

    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable("#CurrencyList")) {
      // Destroy the existing DataTable
      tableElement.DataTable().destroy();
    }
    setTimeout(() => {
      $("#CurrencyList").DataTable({
        pagingType: "full_numbers",
        pageLength: 5,
        processing: true,
        lengthMenu: [5, 10, 25],
        dom: "Blfrtip",
        buttons: [
          {
            extend: "print",
            text: " Print",
            className: "table-button button btn btn-primary mb-2",
          },
          {
            extend: "excel",
            text: " Excel",
            className: "table-button button btn btn-success mb-2",
          },
        ],
        columnDefs: [
          {
            orderable: false,
            targets: -1, // This targets the last column
          },
        ],
      } as any);
      const searchInput = $("#CurrencyList_filter input");
      // searchInput.wrap('<div class="search-wrapper"></div>');
      searchInput.after(
        '<span class="searchTableIcon"><i class="fa fa-search"></i></span>'
      ); // Use 'as any' to avoid type checking for this specific object
    }, 1);
  }
 

  do__Delete() {
    let soption: SweetAlertOptions = {
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "error",
      showConfirmButton: true,
      showCancelButton: true,
    };

    swal.fire(soption).then((willDelete) => {
      if (willDelete.value) {
        swal.fire("Success");
      } else {
        swal.fire("Fail");
      }
    });
  }

  openLarge(content, val) {
    this.modalService.open(content, {
      size: "sm",
      backdrop: 'static',
      keyboard: true,
      scrollable:true,
      animation:true,
      centered:true,  
    });
    if (val == 0) {
      this.reset();
      document.getElementById("cname").focus();
    }
    this.ngAfterViewInit();
  }
  closeModal(): void {
    document.getElementById("closemodal").click();
    // if (this.modalRef) {
    //   this.modalRef.close();
    // }
  } 
  reset(){
    this.currencyDetail={currencyId: '',currencyName:'',currencySymbol:'',isArchived:false}
  }
  currencyDetail:Currency={
    currencyId: '',
    currencyName: '',
    currencySymbol: '',
    isArchived: false
  };
  submit(form: NgForm) {
    if (this.isValObj()) {
       
      this.loading = true;
      this.Api.AddCurrency(this.currencyDetail).subscribe(
        (data: any) => {
          this.loading = false;

          if (data.statusCode == 200) {
            this.toasterService.showToast(data.message, TOAST_TYPE.Success);
            this.getCurrencyList();
            this.closeModal(); 
          } else {
            this.toasterService.showToast(data.message, TOAST_TYPE.Error);
          }
        },
        (err) => {
          this.loading = false;
          console.log(err);
        }
      );
    }
  }
  isValObj() {
    if (!this.currencyDetail.currencyName.trim()) {
      document.getElementById("cname").focus();
      this.toasterService.showToast(
        "Please enter Currency Name to proceed",
        TOAST_TYPE.Warning
      );
      return;
    
    } else if (!this.currencyDetail.currencySymbol.trim()) {
      document.getElementById("csymbol").focus();
      this.toasterService.showToast(
        "Please enter Currency Symbol to proceed",
        TOAST_TYPE.Warning
      );
      return;
    }
    return true;
  }
  loading: boolean = false;
  EditModal(item: any, content) {
    this.currencyDetail = item; 
    this.openLarge(content, 1);
  }
  
  UpdateCurrency() {
    if (this.isValObj()) { 
      this.Api.UpdateCompany(this.currencyDetail).subscribe(
        (data: any) => {
          this.loading = false;
          if (data.statusCode == 200) {
            this.closeModal();
            this.getCurrencyList();
            this.toasterService.showToast(data.message, TOAST_TYPE.Success); 
          } else {
            this.toasterService.showToast(data.message, TOAST_TYPE.Error);
          }
        },
        (err) => {
          console.log(err);
          this.loading = false;
        }
      );
    }
  } 
}
export interface Currency {
  currencyId: string
  currencyName: string
  currencySymbol: string
  isArchived: boolean
}
