import { Component, OnInit } from "@angular/core";
import { Category, SubCategory } from "src/app/InterFace/category";
import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { DataService } from "src/app/Services/data.service";
import { EventdriverService } from "src/app/Services/eventdriver.service";
import { ToasterService, TOAST_TYPE } from "src/app/Services/toaster.service";
import {
  modalOpen,
  RefreshEvent,
  DELETE_DESC,
} from "src/environments/environment";
import swal, { SweetAlertOptions } from "sweetalert2";
declare var $: any;
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css'; // Import the default DataTables CSS

@Component({
  selector: "app-subcategory",
  templateUrl: "./subcategory.component.html",
  styleUrls: ["./subcategory.component.sass"],
})
export class SubcategoryComponent implements OnInit {
  heading = "Manage SubCategories";
  subheading = "Manage the food SubCategories you would like to organize.";
  icon = "pe-7s-note2 icon-gradient bg-happy-itmeo";
  pageid = modalOpen.SubCategoryModal;
  SubCategoryList: SubCategory[] = [];
  SkletonLoader: boolean = true;
  TableLoad = "";
  openReorderModal:boolean=false;
  Plan:any=0;
  constructor(
    private eventDriver: EventdriverService,
    private toasterService: ToasterService,
    private Api: ApiService,
    private common: CommonService,
    private data: DataService
  ) {
    this.eventDriver.refreshevent().subscribe((data) => {
      if (data == RefreshEvent.SubCategory) {
        this.ListSubCategories();
      }
    });
    this.data.getPlan();
    this.Plan= localStorage.getItem('PLAN');
    if(this.Plan==null){
      this.data.getPlan();
    }
  }

 

  ngOnInit(): void {
    this.ListSubCategories();
  }
  

  // ngAfterViewInit() {
  //   $(document).ready(function() {
  //     $('#Subcategory').DataTable();

  //     // Add search icon to the search input
  //     const searchInput = $('#Subcategory_filter input');
  //     searchInput.wrap('<div class="search-wrapper"></div>');
  //     searchInput.before('<span class="search-icon"><i class="fa fa-search"></i></span>');
  //   });
  // }

  openModel() {
    this.eventDriver.triggerEvent({
      IsClosed: false,
      Modal: RefreshEvent.SubCategory,
    });
  }

  do__Delete(id) {
    let soption: SweetAlertOptions = {
      title: `${DELETE_DESC.title}`,
      text: `${DELETE_DESC.desc} ${id.categoryName} !`,
      icon: "error",
      showConfirmButton: true,
      showCancelButton: true,
    };

    swal.fire(soption).then((willDelete) => {
      if (willDelete.value) {
        this.DeleteCategory(id.subcategoryId);
      } else {
        // swal.fire("Fail");
      }

       
    });
  }
  openReOrderModal(){
    this.openReorderModal=false;
    setTimeout(() => {
      this.openReorderModal=true;      
    }, 10);

  }
  closeModal(ev){
    this.openReorderModal= ev =='false'?false:true; 
 }
  async loadDataTable() {
    // new DataTable('#example');
    const tableElement = $("#Subcategory");

    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable("#Subcategory")) {
      // Destroy the existing DataTable
      tableElement.DataTable().destroy();
    }

    setTimeout(() => {
      $("#Subcategory").DataTable({
        pagingType: "full_numbers",
        pageLength: 5,
        processing: true,
        lengthMenu: [5, 10, 25],
        dom: "Blfrtip",
        buttons: [
          {
            extend: "print",
            text: " Print",
            className: "table-button button btn btn-primary mb-2",
          },
          {
            extend: "excel",
            text: " Excel",
            className: "table-button button btn btn-success mb-2",
          },
        ],
        columnDefs: [
          {
            orderable: false,
            targets: -1, // This targets the last column
          },
        ],
      } as any);
      setTimeout(() => {
        this.SkletonLoader = false;
        this.addSearchIcon();
      }, 10);
      // Use 'as any' to avoid type checking for this specific object
    }, 1);
    this.addSearchIcon();
  }

  addSearchIcon() {
    const searchInput = $('#Subcategory_filter input');
    // searchInput.wrap('<div class="search-wrapper"></div>');
    searchInput.after('<span class="searchTableIcon"><i class="fa fa-search"></i></span>');
  }

  ListSubCategories() {
    this.SkletonLoader = true;
    this.Api.getSubCategory().subscribe(
      (data: any) => {
        this.common.dismissloading();
        // var data=JSON.parse(res);
        if (data.statusCode == 200) {
          this.SubCategoryList = data.data;
          this.data.SubCategoryList=data.data;
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
        this.loadDataTable();
      },
      (err) => {
        
        this.common.dismissloading();
      }
    );
  }
  EditCategory(item: any) {
    this.data.SubCategoryModalData = item;
    this.openModel();
  }
  changeStatus(item) {
    this.common.showloading();
    this.Api.UpdateSubCategory(item).subscribe(
      (data: any) => {
        this.common.dismissloading();
        if (data.statusCode == 200) {
this.ListSubCategories();
          // this.toasterService.showToast(data.message, TOAST_TYPE.Success);
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Success);
        }
      },
      (err) => {
        this.common.dismissloading();
     
      }
    );
  }
  DeleteCategory(id: any) {
    this.common.showloading();
    this.Api.deleteSubCategory(id).subscribe(
      (data: any) => {
        this.common.dismissloading();
        if (data.statusCode == 200) {
          this.toasterService.showToast(data.message, TOAST_TYPE.Warning);
          this.ListSubCategories();
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
        this.common.dismissloading();
       
      }
    );
  }
}
