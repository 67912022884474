<!-- <app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon" [addbtn]="true" ></app-page-title> -->
<div class="main-card mb-3 card">
  <div class="card-body">
    <form>
      <div class="row">
        <div class="col-md-6">
          <div class="position-relative mb-3">
            <label for="exampleEmail11" class="form-label">Busniess name</label>
            <input
              name="text"
              id="exampleEmail11"
              placeholder="Enter Busniess name"
              class="form-control" #bname  [(ngModel)]="resObj.businessName" [ngModelOptions]="{standalone: true}"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="position-relative mb-3">
            <label for="examplePassword11" class="form-label"
              >Contact Person Name</label
            >
            <input
              name="text"
              id="examplePassword11"
              placeholder="Person Name" #cpname
              class="form-control" [(ngModel)]="resObj.contactPersonName" [ngModelOptions]="{standalone: true}"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="position-relative mb-3">
            <label for="contactno" class="form-label">Contact No</label>
            <input
              name="text"
              id="contactno"
              placeholder="Enter Contact Number" #contactno
              class="form-control" [(ngModel)]="resObj.contactNo" [ngModelOptions]="{standalone: true}"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="position-relative mb-3">
            <label for="userEmail" class="form-label">E-mail</label>
            <input
              name="text"
              id="userEmail"
              placeholder="Enter Email" #email
              class="form-control" [(ngModel)]="resObj.email" [ngModelOptions]="{standalone: true}"
            />
          </div>
        </div>
      </div>
      <div class="position-relative mb-3">
        <label for="exampleAddress" class="form-label">Serial Key</label>
        <input
          name="address"
          id="exampleAddress"
          placeholder="Serial Key"
          readonly
          class="form-control" [(ngModel)]="resObj.serialKey" [ngModelOptions]="{standalone: true}"
        />
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="position-relative mb-3">
            <label for="expiry" class="form-label"
              >Subscription Expiry On</label
            >
            <input
              name="text"
              id="expiry"
              placeholder="Subscriptions"
              class="form-control" [(ngModel)]="resObj.subscribtion" [ngModelOptions]="{standalone: true}"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="position-relative mb-3">
            <label class="form-label d-block mb-0">Status</label>
            <label
              class="wraper"
              for="something150"
              style="display: inline-block"
            >
              <div class="switch-wrap">
                <input type="checkbox" id="something150" [(ngModel)]="resObj.active" [ngModelOptions]="{standalone: true}"/>
                <div class="switch"></div>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="position-relative mb-3">
            <label for="userName" class="form-label">User Name</label>
            <input
              name="text"
              id="userName" 
              placeholder="User Name"
              class="form-control" [(ngModel)]="resObj.contactNo" [ngModelOptions]="{standalone: true}"
              readonly
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="position-relative mb-3">
            <label for="userPsw" class="form-label">Tenant Code</label>
            <input
              name="text"
              id="userPsw"
              placeholder="Tenant Code" [(ngModel)]="resObj.tenentCode" [ngModelOptions]="{standalone: true}"
              class="form-control" readonly
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="position-relative mb-3">
            <label for="psw" class="form-label">Password</label>
            <input
              name="text" #psw
              id="psw" [(ngModel)]="resObj.password" [ngModelOptions]="{standalone: true}"
              placeholder="Password"
              class="form-control" 
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="position-relative mb-3">
            <label for="psw" class="form-label d-block ">Password Generator</label>
            <button
            type="button" (click)="generatePassword(12)"
            class="btn-shadow d-inline-flex align-items-center btn btn-success ms-2"
          > <i class="pe-7s-config me-1"></i>
            Generate  
          </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-end">
          <button
            type="button"
            class="btn-shadow d-inline-flex align-items-center btn btn-danger"
          ><i class="fa fa-close me-2"></i>
            Reset
          </button>
          <button
            type="button" (click)="submit()"
            class="btn-shadow d-inline-flex align-items-center btn btn-success ms-2"
          > <i class="fa fa-floppy-o me-2"></i>
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
