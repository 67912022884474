<div class="side-drawer-modal my-class">
    <app-sidebarload *ngIf="loading"></app-sidebarload>
    <div class="drawer-header modal-header">
      <h5 *ngIf="TablesItem.tableId.length !==0">Update Tables</h5>
      <h5 *ngIf="TablesItem.tableId.length ==0">
        Add Tables
    </h5>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="closeModal(true)"
      ></button>
    </div>
  
    <div class="drawer-container">
      <div class="row">
        <div class="col-md-12">
          <div class="card-body">
            <!-- <h5 class="card-title">Product Details</h5> -->
  
            <div class="position-relative mb-3">
              <label for="exampleEmail" class="form-label"
                >Table Caption <span class="mandate">*</span></label
              >
              <input
                name="email"
                id="txtSlotName"
                #txtSlotName #cap="ngModel"
                [(ngModel)]="TablesItem.tableCaption"
                #categoryNameInput  [ngClass]="{ 'is-invalid':!cap.value && cap.touched }" 
                placeholder="Enter Table Caption"
                type="text"
                class="form-control" 
              />
            </div>
            
  
            <div class="position-relative mb-3">
                <label for="exampleEmail" class="form-label"
                >Table Type</label>
              <label class="wraper" for="something">
                <div class="switch-wrap">
                  <input
                    type="checkbox"
                    id="something"
                    [(ngModel)]="TablesItem.isAc"
                    [checked]="TablesItem.isAc"
                  />
                  <div class="switch"></div>
                </div>
                <span class="label-text">{{
                  TablesItem.isAc ? "(AC)" : "(Non-AC)"
                }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="drawer-footer">
      <button
        type="button"
        class="btn-shadow align-items-center btn btn-lg btn-danger"
        (click)="closeModal(true)"
      >
        <i class="fa fa-close"></i>&nbsp; Close</button
      >&nbsp;
      <button
        type="button" *ngIf="TablesItem?.tableId.length==0"
        class="btn-shadow align-items-center btn btn-lg btn-primary me-2"
        (click)="do__Save(true)" 
      >
        <i class="fa fa-floppy-o"></i>&nbsp; Save & Close
      </button>
      <button
        type="button" *ngIf="TablesItem.tableId.length==0"
        class="btn-shadow align-items-center btn btn-lg btn-success"
        (click)="do__Save(false)" 
      >
        <i class="fa fa-floppy-o"></i>&nbsp; Save & New
      </button>
      <button
        type="button" *ngIf="TablesItem.tableId.length !==0"
        class="btn-shadow align-items-center btn btn-lg btn-primary"
        (click)="UpdateSlots()"
      >
        <i class="fa fa-floppy-o"></i>&nbsp; Update
      </button>
    </div>
  </div>
  <div class="overlayDrawer my-class"></div>