import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Config } from "datatables.net";
import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { StorageutilityService } from "src/app/Services/storageutility.service";
import { TOAST_TYPE, ToasterService } from "src/app/Services/toaster.service";
import { ValidateService } from "src/app/Services/validate.service";
import { StorageKey, environment } from "src/environments/environment";
import { saveAs } from "file-saver";
import { DataService } from "src/app/Services/data.service";
@Component({
  selector: "app-hoteldetails",
  templateUrl: "./hoteldetails.component.html",
  styleUrls: ["./hoteldetail.css"],
})
export class HoteldetailsComponent implements OnInit {
  heading = "Hotel Details";
  subheading = "Manage the Hotel Details you would like to organize.";
  icon = "pe-7s-note icon-gradient bg-tempting-azure";
  @ViewChild("hname") private hname: ElementRef;
  @ViewChild("mail") private mail: ElementRef;
  @ViewChild("address") private address: ElementRef;
  HotelDetails: any = {
    logoPath: "",
  };
  Plan: any = 0;
  Imagepath: string = environment.ImagePath;
  IsEdit: boolean = true;
  constructor(
    private Api: ApiService,
    public Common: CommonService,
    private storage: StorageutilityService,
    private toaster: ToasterService,
    private Data: DataService,
    private validator: ValidateService,
    private http: HttpClient
  ) {
    this.Plan = localStorage.getItem("PLAN");
    if (this.Plan == null) {
      this.Data.getPlan();
    }
  }
  onColorChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.HotelDetails.brandColor = input.value;
  }
  ngOnInit(): void {
    this.getHotelDetails();
  }
  printQr(val) {
    var content;
    if (val == 0) {
      content = document.getElementById("contentToPrint").innerHTML;
    } else {
      content = document.getElementById("ACQrPrint").innerHTML;
    }

    var printWindow = window.open("", "_blank");

    printWindow.document.write(
      "<html><head><title>Print</title>" +
        "<style>" +
        "body { margin: 0; width: 100vw; height: 100vh; display: flex; justify-content: center; align-items: center; }" +
        "img { max-width: 100%; max-height:100%; }" +
        "</style>" +
        "</head><body>" +
        content +
        "</body></html>"
    );

    // Wait for the print window to fully load the content before printing
    printWindow.document.close();
    printWindow.onload = function () {
      setTimeout(function () {
        // Print the content
        printWindow.print();
        // Close the print window
        printWindow.close();
      }, 500); // Adjust the delay as needed
    };
  }

  extFn(url) {
    const match = url.match(/\.[0-9a-z]+$/i);
    return match ? match[0].slice(1) : "";
  }

  downFn(url) {
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    // if (!pattern.test(url)) {
    //     errMsg.textContent = "Wrong URL Entered";
    //     dBtn.innerText = "Download File";
    //     return;
    // }
    // errMsg.textContent = "";
    fetch(url)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network Problem");
        }
        return res.blob();
      })
      .then((file) => {
        const ex = this.extFn(url);
        let tUrl = URL.createObjectURL(file);
        const tmp1 = document.createElement("a");
        tmp1.href = tUrl;
        tmp1.download = `downloaded_file.${ex}`;
        document.body.appendChild(tmp1);
        tmp1.click();
        //dBtn.innerText = "Download File";
        URL.revokeObjectURL(tUrl);
        tmp1.remove();
      })
      .catch((excep) => {
        console.log("Handled Exception", excep);
        alert("some exception");
        // errMsg.textContent =
        //     "Cannot Download Restricted Content!";
        // dBtn.innerText = "Download File";
      });
  }

  downloadQR(url: string, id: number) {
    const fullUrl = `${this.Imagepath}${url}`; // Ensure this.Imagepath is a valid string

     
    const response: any = fetch(fullUrl, {
      // Change header to headers here
      headers: {
        "Content-Type": "image/png",
        Authorization: `${this.Api.getKey()}`,
      },
    });

    if (response.ok) {
      const blob = response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "unik-photography-image";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
 
  }

  getHotelDetails() {
    var UserData = this.storage.getValue(StorageKey.UserDetails, true);
    this.Api.getCompanyFromId(UserData.companyId).subscribe(
      (data: any) => {
         
        if (data.statusCode == 200) {
          this.HotelDetails = data.data;
        } else {
          this.toaster.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  UpdateHotelDetails() {
    if (this.validator.validateString(this.HotelDetails.companyName)) {
      this.validator.focusElement(this.hname);
      this.toaster.showToast("Please Enter Hotel Name", TOAST_TYPE.Warning);
      return;
    }
    if (this.validator.validateString(this.HotelDetails.contact)) {
      this.toaster.showToast(
        "Please Enter Your Contact Number",
        TOAST_TYPE.Warning
      );
      return;
    }
    if (this.HotelDetails.email.length !== 0) {
      if (!this.validator.validateEmail(this.HotelDetails.email)) {
        this.validator.focusElement(this.mail);
        this.toaster.showToast(
          "Please Enter Valid Email to Proceed",
          TOAST_TYPE.Warning
        );
        return;
      }
    }
    if (this.validator.validateString(this.HotelDetails.address)) {
      this.validator.focusElement(this.address);
      this.toaster.showToast("Please Enter Your Address", TOAST_TYPE.Warning);
      return;
    }
    this.loading = true;
    this.Api.UpdateCompany(this.HotelDetails).subscribe(
      (data: any) => {
        if (data.statusCode == 200) {
          this.IsEdit = true;
          this.toaster.showToast(data.message, TOAST_TYPE.Success);
        } else {
          this.toaster.showToast(data.message, TOAST_TYPE.Error);
        }
        this.loading = false;
      },
      (err) => {
        console.log(err);
        this.IsEdit = true;
      }
    );
  }
  openUploader() {
    document.getElementById("fileCDP").click();
  }
  loading: boolean = false;
  UploadImage(event) {
    this.loading = true;
    const formData = new FormData();
    const files = event.target.files[0];
    formData.append("files", files); // Append file with key 'files'

    this.Api.imageUpload(formData).subscribe(
      (data: any) => {
        this.loading = false;

        if (data.isSucceeded) {
          this.HotelDetails.LogoPath = data.errorNote;
          this.IsEdit = false;
        } else {
          this.toaster.showToast(data.errorNote, TOAST_TYPE.Error);
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }
  getImagePath(path:any){
    return this.Imagepath+path
  }
  DisplayImage(event) {
    this.loading = true;
    const formData = new FormData();
    const files = event.target.files[0];
    formData.append("files", files); // Append file with key 'files'

    this.Api.imageUpload(formData).subscribe(
      (data: any) => {
        this.loading = false;

        if (data.isSucceeded) {
          // this.HotelDetails.LogoPath = data.errorNote;
          // this.IsEdit = false;
        } else {
          this.toaster.showToast(data.errorNote, TOAST_TYPE.Error);
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }
  downloadQRimage(val, id) {
    this.Api.downloadQR(this.HotelDetails.companyId).subscribe({
      next: (data: Blob) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement("a");
        a.href = url;
        a.download = "image.png"; // Set the filename here
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}

export class hotelDetail {
  "expiresOn": string;
  "isArchived": boolean;
  "isActive": boolean;
  "status": Number;
  "brandColor": string;
  "logoPath": string;
  "companyCode": string;
  "serialKey": string;
  "contact": string;
  "email": string;
  "address": string;
  "companyName": string;
  "companyId": string;
}
