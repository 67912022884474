<app-page-title
  [heading]="heading"
  [subheading]="subheading"
  [icon]="icon"
  [addbtn]="true"
></app-page-title>

<div class="mb-3 card">
  <div class="card-header-tab card-header">
    <div class="card-header-title font-size-lg text-capitalize fw-normal">
      <i class="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
      Total  
    </div>
  </div>
  <div class="g-0 row" *ngIf="isAdmin">
    <div class="col-sm-12 col-md-3 col-xl-3">
      <div
        class="card no-shadow rm-border bg-transparent widget-chart text-start"
      >
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-10 bg-warning"></div>
          <i class="pe-7s-shopbag text-white opacity-8"></i>
        </div>
        <div class="widget-chart-content">
          <div class="widget-subheading fw-bolder">Vendors</div>
          <div class="spinner-border text-primary" role="status" *ngIf="loading">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="widget-numbers" *ngIf="!loading">{{ DashboardDetails.total }}</div>
          <!-- <div class="widget-description opacity-8 ">
            <div class="d-inline text-danger pe-1">
              <fa-icon [icon]="faAngleDown"></fa-icon>
              <span class="ps-1">54.1%</span>
            </div>
            less earnings
          </div> -->
        </div>
      </div>
      <div class="divider m-0 d-md-none d-sm-block"></div>
    </div>
    <div class="col-sm-12 col-md-3 col-xl-3">
      <div
        class="card no-shadow rm-border bg-transparent widget-chart text-start"
      >
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-9 bg-danger"></div>
          <i class="pe-7s-pin text-white"></i>
        </div>
        <div class="widget-chart-content">
          <div class="widget-subheading fw-bolder">Active Vendors</div>
          <div class="widget-numbers" *ngIf="!loading">
            
            <span >{{ DashboardDetails.active }}</span>
          </div>
          <div class="spinner-border text-primary" role="status" *ngIf="loading">
            <span class="sr-only">Loading...</span>
          </div>
          <!-- <div class="widget-description opacity-8 ">
            Grow Rate:
            <span class="text-info ps-1">
              <fa-icon [icon]="faAngleDown"></fa-icon>
                <span class="ps-1">14.1%</span>
            </span>
          </div> -->
        </div>
      </div>
      <div class="divider m-0 d-md-none d-sm-block"></div>
    </div>
    <div class="col-sm-12 col-md-3 col-xl-3">
      <div
        class="card no-shadow rm-border bg-transparent widget-chart text-start"
      >
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-9 bg-success"></div>
          <i class="pe-7s-shield text-white"></i>
        </div>
        <div class="widget-chart-content">
          <div class="widget-subheading fw-bolder text-nowrap">Expired Subscriptions</div>
          <div class="widget-numbers text-success" *ngIf="!loading">
            
            <span >{{ DashboardDetails.expired }}</span>
          </div>
          <div class="spinner-border text-primary" role="status" *ngIf="loading">
            <span class="sr-only">Loading...</span>
          </div>
          <!-- <div class="widget-description ">
            Increased by
            <span class="text-warning ps-1">
              <fa-icon [icon]="faAngleUp"></fa-icon>
                <span class="ps-1">7.35%</span>
            </span>
          </div> -->
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-3 col-xl-3">
      <div
        class="card no-shadow rm-border bg-transparent widget-chart text-start"
      >
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-9 bg-success"></div>
          <i class="pe-7s-hourglass text-white"></i>
        </div>
        <div class="widget-chart-content">
          <div class="widget-subheading fw-bolder">Shortly Expiring</div>
          <div class="widget-numbers text-success" *ngIf="!loading">
           
            <span >{{ DashboardDetails.shortlyExpiring }}</span>
          </div>
          <div class="spinner-border text-primary" role="status" *ngIf="loading">
            <span class="sr-only">Loading...</span>
          </div>
          <!-- <div class="widget-description ">
            Increased by
            <span class="text-warning ps-1">
              <fa-icon [icon]="faAngleUp"></fa-icon>
                <span class="ps-1">7.35%</span>
            </span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="g-0 row" *ngIf="!isAdmin">
    <div class="col-sm-12 col-md-4 col-xl-4">
      <div
        class="card no-shadow rm-border bg-transparent widget-chart text-start"
      >
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-9 bg-danger"></div>
          <i class="pe-7s-safe text-white"></i>
        </div>
        <div class="widget-chart-content">
          <div class="widget-subheading fw-bolder">Tables</div>
          <div class="widget-numbers" *ngIf="!loading">
          
            <span >{{ DashboardDetails.tables }}</span>
          </div>
          <div class="spinner-border text-primary" role="status" *ngIf="loading">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <div class="divider m-0 d-md-none d-sm-block"></div>
    </div>
    <div class="col-sm-12 col-md-4 col-xl-4">
      <div
        class="card no-shadow rm-border bg-transparent widget-chart text-start"
      >
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-9 bg-success"></div>
          <i class="pe-7s-server text-white"></i>
        </div>
        <div class="widget-chart-content">
          <div class="widget-subheading fw-bolder">Total Categories</div>
          <div class="widget-numbers text-success" *ngIf="!loading">
            
            <span >{{ DashboardDetails.categories }}</span>
          </div>
          <div class="spinner-border text-primary" role="status" *ngIf="loading">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-4 col-xl-4">
      <div
        class="card no-shadow rm-border bg-transparent widget-chart text-start"
      >
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-10 bg-warning"></div>
          <i class="pe-7s-shopbag text-white opacity-8"></i>
        </div>
        <div class="widget-chart-content">
          <div class="widget-subheading fw-bolder">Products</div>
          <div class="widget-numbers" *ngIf="!loading">
            {{ DashboardDetails.products }}</div>
            <div class="spinner-border text-primary" role="status" *ngIf="loading">
              <span class="sr-only">Loading...</span>
            </div>
        </div>
      </div>
      <div class="divider m-0 d-md-none d-sm-block"></div>
    </div>
   
  </div>
</div>
