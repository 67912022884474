
<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon" [addbtn]="true" ></app-page-title>
<div class="main-card card p-2">
    <app-sidebarload *ngIf="loading"></app-sidebarload>
    <div class="container ">
        <div class="row ">

            <div class="col-md-12 text-center pt-4 pb-4"  *ngIf="Plan!=0">
                <div class="profile d-flex align-items-center justify-content-center" >
                    <img src="../../../assets/images/restaurant.png" alt="" srcset="" class="w-100" style="border-radius: 50%;object-fit: contain;" *ngIf="HotelDetails.logoPath.length==0">
                    <img [src]="Imagepath+HotelDetails.logoPath" alt="" srcset="" class="w-100" style="border-radius: 50%;object-fit: contain;" *ngIf="HotelDetails.logoPath.length !==0">
                    <i class="fa fa-edit fa-lg text-primary" style="position: absolute; bottom: -10px; left: 0; right: 0;cursor: pointer;" (click)="openUploader()"></i>
                    <input type="file" hidden id="fileCDP" (change)="UploadImage($event)" accept="image/*"/>
                </div>
                <!-- <div class="card hotelimg p-3 mb-3 mt-3 m-auto" > 
                    <div  > 
                        <div class="position-relative mb-3 text-center ">
                            <label for="exampleEmail" class="form-label">Hotel Image </label>
                            <div >
                                <img src="../../../assets/images/restaurant.png" alt="" srcset="" class="w-100" >
                            </div> 
                        </div>
                    </div> 
                </div> -->
            </div>
            <div class="col-md-6">
                <div  > 
                    <div class="position-relative mb-3">
                        <label for="exampleEmail" class="form-label">Hotel Name<span
                                class="mandate">*</span></label>
                        <input name="text" [(ngModel)]="HotelDetails.companyName" [disabled]="IsEdit"  placeholder="Enter Hotel name" type="text" #hname
                            class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div  > 
                    <div class="position-relative mb-3">
                        <label for="exampleEmail" class="form-label">Contact <span
                                class="mandate">*</span></label>
                        <input name="tel" maxlength="10" [(ngModel)]="HotelDetails.contact" [disabled]="true"  placeholder="Enter Contact Number" (keypress)="Common.allowonlynumericMobile($event)" type="text"
                            class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div  > 
                    <div class="position-relative mb-3">
                        <label for="exampleEmail" class="form-label">Mail </label>
                        <input name="text"   [(ngModel)]="HotelDetails.email" [disabled]="IsEdit"  placeholder="Enter Email"   type="text" #mail
                            class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div  > 
                    <div class="position-relative mb-3">
                        <label for="exampleEmail" class="form-label">Your Subscriptions Expired On </label>
                        <input name="text"     value="{{HotelDetails.expiresOn|date}}" [disabled]="true"  placeholder="Expires On"   type="text" #mail
                            class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div  > 
                    <div class="position-relative mb-3 color-input">
                        <label for="exampleEmail" class="form-label">App Background Image </label>
                        <input type="color"  [(ngModel)]="HotelDetails.brandColor" (input)="onColorChange($event)">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="">
                  <form id="form-upload">
                    <div class="form-group ">
                      <label for="">Choose Background image <span style="    font-size: 10px; color: red;">(1920*1080 pixels for best preview)</span></label>
                      <input
                        type="file"
                        class="form-control mt-1"
                        (change)="DisplayImage($event)"
                      />
                    </div>
                    <!-- <div
                      class="img-thumbs"
                      *ngIf="HotelDetails.displayPicturePath.length != 0"
                    >
                      <div class="wrapper-thumb">
                        <img
                          [src]="getImagePath(inserObject.displayPicturePath)"
                          class="img-preview-thumb"
                          alt="Image 2"
                        />
                        <span
                          class="remove-btn"
                          (click)="inserObject.displayPicturePath = ''"
                          >x</span
                        >
                      </div>
                    </div> -->
                  </form>
                </div>
              </div>
            <div class="col-md-12">
                <div  > 
                    <div class="position-relative mb-3">
                        <label for="exampleEmail" class="form-label">Address<span
                                class="mandate">*</span></label>
                         <textarea class="form-control" placeholder="Enter Your Address..."   [readonly]="IsEdit" [(ngModel)]="HotelDetails.address" #address ></textarea>
                    </div>
                </div>
            </div>
            <div class="col-md-12 text-end mt-3 mb-3">
                <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-primary" (click)="IsEdit=false" *ngIf="IsEdit" ><i class="fa fa-edit text-white me-2 fa-lg"></i> Edit</button> 
                <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success"  *ngIf="!IsEdit" (click)="UpdateHotelDetails()" ><i class="fa fa-upload me-2 text-white fa-lg " ></i>Update</button>
            </div>

            <div class="col-md-6 d-flex align-items-center justify-content-center mt-3" *ngIf="HotelDetails.qrCode">
                <div class="card qr p-3 mb-3" > 
                    <h5 class="fw-bolder text-center">QR Code</h5>
                    <div id="contentToPrint" class="m-3">
                        <img src="{{Imagepath+HotelDetails.qrCode}}" alt="" srcset="" class="w-100" >
                    </div> 
                    <div class="text-center">
                        <button (click)="downloadQRimage(HotelDetails.qrCode,0)" class="btn-shadow d-inline-flex align-items-center btn btn-primary me-3"><i class="fa fa-download fa-lg me-2"></i> Download</button>
                        <!-- <button type="button" (click)="printQr(0)" class="btn-shadow d-inline-flex align-items-center btn btn-primary me-3"> <i class="fa fa-print fa-lg me-2"></i> Print</button>   -->
                        <button class="btn-shadow d-inline-flex align-items-center btn btn-success" (click)="Common.copyURLToClipboard(HotelDetails.qrURL)"><i class="fa fa-copy fa-lg me-2"></i>  Copy</button>
                    </div>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center justify-content-center mt-3" *ngIf="HotelDetails.acQrCode&&HotelDetails.isAcTableAvailable">
                <div class="card qr p-3 mb-3" > 
                    <h5 class="fw-bolder text-center">AC QR Code</h5>
                    <div id="ACQrPrint" class="m-3">
                        <img src="{{Imagepath+HotelDetails.acQrCode}}" alt="" srcset="" class="w-100" >
                    </div> 
                    <div class="text-center">
                        <button (click)="downloadQRimage(HotelDetails.acQrCode,1)"class="btn-shadow d-inline-flex align-items-center btn btn-primary me-3"><i class="fa fa-download fa-lg me-2"></i> Download</button>
                        <!-- <button type="button" (click)="printQr(1)" class="btn-shadow d-inline-flex align-items-center btn btn-primary me-3"> <i class="fa fa-print fa-lg me-2"></i> Print</button>   -->
                        <button class="btn-shadow d-inline-flex align-items-center btn btn-success" (click)="Common.copyURLToClipboard(HotelDetails.acQrURL)"><i class="fa fa-copy fa-lg me-2"></i>  Copy</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


 
  
  
 