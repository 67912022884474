import { Component, OnInit } from "@angular/core";
import { TimeSlots } from "src/app/InterFace/category";
import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { DataService } from "src/app/Services/data.service";
import { EventdriverService } from "src/app/Services/eventdriver.service";
import { TOAST_TYPE, ToasterService } from "src/app/Services/toaster.service";
import { DELETE_DESC, RefreshEvent, modalOpen } from "src/environments/environment";
import swal, { SweetAlertOptions, SweetAlertIcon } from "sweetalert2";
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css'; // Import the default DataTables CSS
@Component({
  selector: "app-manageslot",
  templateUrl: "./manageslot.component.html",
  styleUrls: ["./manageslot.component.sass"],
})
export class ManageslotComponent implements OnInit {
  heading = "Manage TimeSlots";
  subheading = "Manage the food Timings you would like to organize.";
  icon = "pe-7s-alarm icon-gradient bg-happy-itmeo";
  pageid = modalOpen.SlotModal;
  SkletonLoader:boolean=true;
  Plan:any=0;
  constructor(
    private eventDriver: EventdriverService,
    private toasterService: ToasterService,
    public common: CommonService,
    private data: DataService,
    private Api: ApiService
  ) {
    this.eventDriver.refreshevent().subscribe((data) => {
      if (data == RefreshEvent.ProductSlot) {
        this.ListSlots();
      }
    });
    this.Plan= localStorage.getItem('PLAN'); 
      this.data.getPlan();
    
  }

  ngOnInit(): void {
    this.ListSlots();
  }

  openModel() {
    this.eventDriver.triggerEvent({
      IsClosed: false,
      Modal: RefreshEvent.ProductSlot,
    });
  }

  do__Delete(id) {
    let soption: SweetAlertOptions = {
      title: `${DELETE_DESC.title}`,
      text: `${DELETE_DESC.desc} ${id.slotName} !`,
      icon: "error",
      showConfirmButton: true,
      showCancelButton: true,
    };

    swal.fire(soption).then((willDelete) => {
      if (willDelete.value) {
         this.DeleteSlots(id.slotId);
      } else {
        // swal.fire("Fail");
      }
 
    });
  }
  async loadDataTable() {
    // new DataTable('#example');
    const tableElement = $("#manageslot");

    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable("#manageslot")) {
      // Destroy the existing DataTable
      tableElement.DataTable().destroy();
    }
    setTimeout(() => {
      $("#manageslot").DataTable({
        pagingType: "full_numbers",
        pageLength: 5,
        processing: true,
        lengthMenu: [5, 10, 25],
        dom: "Blfrtip",
        buttons: [
          {
            extend: "print",
            text: " Print",
            className: "table-button button btn btn-primary mb-2",

          },
          {
            extend: "excel",
            text: " Excel",
            className: "table-button button btn btn-success mb-2",
          },
        ],
        columnDefs: [
          { 
            orderable: false, 
            targets: -1 // This targets the last column
          }
        ]
      } as any);
      setTimeout(() => {
        this.SkletonLoader=false;
        const searchInput = $('#manageslot_filter input');
        // searchInput.wrap('<div class="search-wrapper"></div>');
        searchInput.after('<span class="searchTableIcon"><i class="fa fa-search"></i></span>');
      }, 10); // Use 'as any' to avoid type checking for this specific object
    }, 1);
  }
  TimeSlotList: TimeSlots[]=[];
  TableLoad: string = "";
  ListSlots() {
   this.SkletonLoader=true;
    this.Api.getTimeSlots().subscribe(
      (data: any) => {
         
        if (data.statusCode == 200) {
          this.TimeSlotList = data.data;
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
       this.loadDataTable();
     
      },
      (err) => {
        console.log(err);
        
      }
    );
  }
  updateTimeSlots(item) {
    this.Api.updateTimeSlots(item).subscribe(
      (data: any) => { 
        if (data.statusCode == 200) {
          this.ListSlots()
          // this.toasterService.showToast(data.message, TOAST_TYPE.Success);
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  EditSlots(item: any) {
    this.data.TimeSlotModalData = item;
    this.openModel();
  }
  DeleteSlots(id: any) {
    this.SkletonLoader=true;
    this.Api.deleteTimeSlots(id).subscribe(
      (data: any) => {
        
        this.common.dismissloading();
        if (data.statusCode == 200) {
          this.toasterService.showToast(data.message, TOAST_TYPE.Warning);
          this.ListSlots();
        } else {
          this.SkletonLoader=false;
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
         this.SkletonLoader=false;
        console.log(err);
      }
    );
  }
}
