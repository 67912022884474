<div class="side-drawer-modal my-class">
  <app-sidebarload *ngIf="loading"></app-sidebarload>
  <div class="drawer-header modal-header">
    <h5 *ngIf="SlotItem.slotId.length !==0">Update TimeSlot</h5>
    <h5 *ngIf="SlotItem.slotId.length==0">
      Add Time Slot
  </h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="closeModal(true)"
    ></button>
  </div>

  <div class="drawer-container">
    <div class="row">
      <div class="col-md-12">
        <div class="card-body">
          <!-- <h5 class="card-title">Product Details</h5> -->

          <div class="position-relative mb-3">
            <label for="exampleEmail" class="form-label"
              >Slot Name <span class="mandate">*</span></label
            >
            <input
              name="email"
              id="txtSlotName"
              #txtSlotName
              [(ngModel)]="SlotItem.slotName"
              #slt="ngModel"
              [ngClass]="{ 'is-invalid':!slt.value && slt.touched }" 
              placeholder="Enter Slot Name"
              type="text"
              class="form-control"
            />
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="position-relative mb-3">
                <label for="exampleEmail" class="form-label"
                  >Start Time<span class="mandate">*</span></label
                >
                <input
                  type="time"
                  class="form-control"
                  id="txtStartTime"
                  placeholder="hh:mm AM/PM"
                  #txtStartTime #sltsttime="ngModel"
                  [(ngModel)]="SlotItem.startTime"
                    
                  [ngClass]="{ 'is-invalid':!sltsttime.value && sltsttime.touched }"
                />
                <div class="invalid-feedback d-block" >
                  hh:mm a
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="position-relative mb-3">
                <label for="exampleEmail" class="form-label"
                  >End Time<span class="mandate">*</span></label
                >
                <input
                  type="time"
                  class="form-control"
                  id="txtEndTime"
                  #txtEndTime
                  [(ngModel)]="SlotItem.endTime"
                  #sltendtime="ngModel"
                  [ngClass]="{ 'is-invalid':!sltendtime.value && sltendtime.touched }" 
                />
                <div class="invalid-feedback d-block" >
                  hh:mm a
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="position-relative mb-3">
            <label class="wraper" for="something">
              <div class="switch-wrap">
                <input
                  type="checkbox"
                  id="something"
                  [(ngModel)]="SlotItem.isActive"
                  [checked]="SlotItem.isActive"
                />
                <div class="switch"></div>
              </div>
              <span class="label-text">{{
                SlotItem.isActive ? "Active" : "Inactive"
              }}</span>
            </label>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="drawer-footer">
    <button
      type="button"
      class="btn-shadow align-items-center btn btn-lg btn-danger"
      (click)="closeModal(true)"
    >
      <i class="fa fa-close"></i>&nbsp; Close</button
    >&nbsp;
    <button
      type="button" *ngIf="SlotItem.slotId.length==0"
      class="btn-shadow align-items-center btn btn-lg btn-primary me-2"
      (click)="do__Save(true)" 
    >
      <i class="fa fa-floppy-o"></i>&nbsp; Save & Close
    </button>
    <button
      type="button" *ngIf="SlotItem.slotId.length==0"
      class="btn-shadow align-items-center btn btn-lg btn-success"
      (click)="do__Save(false)" 
    >
      <i class="fa fa-floppy-o"></i>&nbsp; Save & New
    </button>
    <button
      type="button" *ngIf="SlotItem.slotId.length !==0"
      class="btn-shadow align-items-center btn btn-lg btn-primary"
      (click)="UpdateSlots()"
    >
      <i class="fa fa-floppy-o"></i>&nbsp; Update
    </button>
  </div>
</div>
<div class="overlayDrawer my-class"></div>