import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Category, ReorderModal } from "src/app/InterFace/category";
import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { DataService } from "src/app/Services/data.service";
import { EventdriverService } from "src/app/Services/eventdriver.service";
import { ToasterService, TOAST_TYPE } from "src/app/Services/toaster.service";
import { environment, RefreshEvent } from "src/environments/environment";

@Component({
  selector: "app-category-reorder",
  templateUrl: "./category-reorder.component.html",
  styleUrls: ["./category-reorder.component.css"],
})
export class CategoryReorderComponent implements OnInit {
  categoryItem: Category[] = [];

  loading: boolean = false;
  @Output() newItemEvent = new EventEmitter<string>();
  @ViewChild("categoryNameInput") private txtCategoryName: ElementRef;
  @ViewChild("DisplayInput") private DisplayInput: ElementRef;

  constructor(
    private eventDriver: EventdriverService,
    private toasterService: ToasterService,
    private Data: DataService,
    private Api: ApiService,
    public common: CommonService
  ) {
    this.ListCategories();
  }
  ngOnInit(): void {}

  closeModal(val) {
  var obj:ReorderModal={
     modal:false,
     refresh:val
  }

    this.newItemEvent.emit(JSON.stringify(obj));
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   console.log(this.categoryItem, event.previousIndex, event.currentIndex);
  //   moveItemInArray(this.categoryItem, event.previousIndex, event.currentIndex);
  // }
  drop(event: CdkDragDrop<string[]>) {
     
    moveItemInArray(this.categoryItem, event.previousIndex, event.currentIndex);
  }
  do__Save(val: boolean) {
    this.loading=true;
    this.Api.categoryBulkReorder(this.categoryItem).subscribe({
      next: (data: any) => {
         
        this.loading=false;
        if(data.statusCode==200){ 
          this.closeModal(true);
          this.toasterService.showToast(data.message,TOAST_TYPE.Success);
        }else{
          this.toasterService.showToast(data.message,TOAST_TYPE.Error);
        }
      },
      error: (err) =>{this.loading=false; console.log(err)},
    });
  }

  ListCategories() {
    this.loading = true;
    this.Api.getCategory().subscribe(
      (data: any) => {
        this.loading = false;
        // var data=JSON.parse(res);
        if (data.statusCode == 200) {
          this.categoryItem = data.data;
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
        console.log(err, "API ERROR");
        this.loading = false;
      }
    );
  }
}
