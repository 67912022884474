import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment, CRYPTER_TYPE } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CrypterService {

  constructor() { }
  getCryptedString( processObj: any, crypterType: CRYPTER_TYPE){

    if (processObj == null)
      return;

    if (crypterType === CRYPTER_TYPE.ENCRYPT)
      return CryptoJS.AES.encrypt(processObj, environment.aes_salt_key).toString();
    else if (crypterType === CRYPTER_TYPE.DECRYPT)
      return CryptoJS.AES.decrypt(processObj, environment.aes_salt_key).toString(CryptoJS.enc.Utf8);
  }
}
