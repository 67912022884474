<app-page-title
  [heading]="heading"
  [subheading]="subheading"
  [icon]="icon"
  [pageid]="pageid"
  [bulkbtn]="false"
></app-page-title>

<div class="main-card mb-3 card p-2" [ngClass]="SkletonLoader ? 'd-none' : ''">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize fw-normal">
      Table Details
    </div>
  </div>
  <div class="table-responsive mt-3">
    <table
      class="align-middle text-truncate mb-0 table table-borderless table-hover table table-striped table-bordered"
      id="Tables"
      style="width: 100%"
    >
      <thead>
        <tr>
          <th class="text-left" style="width: 80px !important">S.No</th>
          <th class="text-left">Table Captions</th>
          <th class="text-left">AC</th>
          <th class="text-right">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of TablesList; index as i">
          <td class="text-left text-muted" style="width: 80px">{{ i + 1 }}</td>
          <td class="text-left">{{ item.tableCaption }}</td>
          <td class="text-left">
            <label
              class="wraper"
              for="{{ item.tableId }}"
              style="display: inline-block"
            >
              <div class="switch-wrap">
                <input
                  type="checkbox"
                  id="{{ item.tableId }}"
                  [(ngModel)]="item.isAc"
                  (change)="changeStatus(item)"
                />
                <div class="switch"></div>
              </div>
            </label>
            <span class="badge text-white d-none">{{
              item.isActive
                ? "Active" 
                : "InActive"
            }}</span>
          </td>
          <td class="text-right">
            <div role="group" class="btn-group-sm btn-group">
              <button
                class="btn-shadow btn btn-outline-dark"
                (click)="Edittables(item)"
              >
                <i class="fa fa-edit"></i>
                Edit</button
              >&nbsp;
              <button
                class="btn-shadow btn btn-outline-danger"
                (click)="do__Delete(item)"
              >
                <i class="fa fa-trash"></i>
                Delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="main-card mb-3 card p-2" [ngClass]="!SkletonLoader ? 'd-none' : ''">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize fw-normal">
      Table List
    </div>
  </div>
  <div class="table-responsive mt-3">
    <app-skletonloader [thead]="4" [tbody]="5"></app-skletonloader>
  </div>
</div>
