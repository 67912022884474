<div class="side-drawer-modal my-class {{ hideDiv }}">
  <app-sidebarload *ngIf="loading"></app-sidebarload>
  <div class="drawer-header modal-header">
    <h5 *ngIf="SliderItem.sliderId.length == 0">Add Slider</h5>
    <h5 *ngIf="SliderItem.sliderId.length !== 0">Update Slider</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="closeModal(true)"
    ></button>
  </div>

  <div class="drawer-container">
    <div class="row">
      <div class="col-md-12">
        <div class="card-body">
          <!-- <h5 class="card-title">Category Details</h5> -->

          <div class="position-relative mb-3">
            <div class="file-upload-container cursor-pointer">
              <!-- <i class="pe-7s-trash "></i> -->
              <div class="file-upload-core" (click)="openUploader()">
                <img
                  [src]="Apiurl + SliderItem.dpPath"
                  *ngIf="SliderItem.dpPath.length !== 0"
                />
                <i
                  class="pe-7s-cloud-upload fs-1 text-danger"
                  *ngIf="SliderItem.dpPath.length == 0"
                ></i>
                <span>Click to upload an image for the Slider</span>
                <input
                  type="file"
                  hidden
                  id="fileCDP"
                  (change)="UploadImage($event)"
                  accept="image/*"
                />
              </div>
            </div>
          </div>

          <!-- <div class="position-relative mb-3">
            <label class="wraper" for="something">
              <div class="switch-wrap">
                <input
                  type="checkbox"
                  id="something"
                  [(ngModel)]="SliderItem.isActive"
                  [checked]="SliderItem.isActive"
                />
                <div class="switch"></div>
              </div>
              <span class="label-text">{{
                SliderItem.isActive ? "Active" : "Inactive"
              }}</span>
            </label>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="drawer-footer">
    <button
      type="button"
      class="btn-shadow align-items-center btn btn-lg btn-danger"
      (click)="closeModal(true)"
    >
      <i class="fa fa-close"></i>&nbsp; Close</button
    >&nbsp;
    <button
      type="button"
      class="btn-shadow align-items-center btn btn-lg btn-primary me-2"
      (click)="do__Save(true)"
      *ngIf="SliderItem.sliderId.length == 0"
    >
      <i class="fa fa-floppy-o"></i>&nbsp; Save & Close
    </button>
    <button
      type="button"
      class="btn-shadow align-items-center btn btn-lg btn-success"
      (click)="do__Save(false)"
      *ngIf="SliderItem.sliderId.length == 0"
    >
      <i class="fa fa-floppy-o"></i>&nbsp; Save & New
    </button>
    <button
      type="button"
      class="btn-shadow align-items-center btn btn-lg btn-primary"
      (click)="updateSlider()"
      *ngIf="SliderItem.sliderId.length !== 0"
    >
      <i class="fa fa-floppy-o"></i>&nbsp; Save
    </button>
  </div>
</div>
<div class="overlayDrawer my-class"></div>
