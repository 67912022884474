import { Component, OnInit } from "@angular/core";
import { ThemeOptions } from "../../../../../theme-options";
import { Router } from "@angular/router";
import swal, { SweetAlertOptions } from "sweetalert2";
import { StorageutilityService } from "src/app/Services/storageutility.service";
import { CommonService } from "src/app/Services/common.service";
import { StorageKey } from "src/environments/environment";

@Component({
  selector: "app-user-box",
  templateUrl: "./user-box.component.html",
})
export class UserBoxComponent implements OnInit {
  isAdmin: boolean = false;
  constructor(
    public globals: ThemeOptions,
    private router: Router,
    private storage: StorageutilityService,
    public common: CommonService
  ) {
    var data = this.storage.getValue(StorageKey.UserDetails, true);
    if (data) {
      this.isAdmin = data.isSuperAdmin;
    }
  }

  ngOnInit() {}

  gotoPage() {
    setTimeout(() => {
      this.router.navigateByUrl("masters/hoteldetails");
    }, 10);
  }
  logout() {
    let soption: SweetAlertOptions = {
      title: "Are you sure Want to Logout?",
      // text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: "info",
      showConfirmButton: true,
      showCancelButton: true,
    };

    swal.fire(soption).then((willDelete) => {
      if (willDelete.value) {
        this.storage.removeKey(StorageKey.UserDetails);
        this.router.navigateByUrl("/");
      } else {
        // swal.fire("Fail");
      }

     
    });
  }
}
