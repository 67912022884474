<!-- <app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon" [addbtn]="false" pageid="admin" ></app-page-title> -->
<div class="row">
    <div class="col-md-12 text-end mb-4">
      <button
        type="button"
        class="btn-shadow d-inline-flex align-items-center btn btn-primary"
        (click)="openLarge(content, 0)"
      >
        <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
        Add New
      </button>
    </div>
  </div>
  <div class="main-card mb-3 card p-2" [ngClass]="SkletonLoader ? 'd-none' : ''">
    <div class="card-header">
      <div class="card-header-title font-size-lg text-capitalize fw-normal">
        Manage Subscriptions
      </div>
    </div>
    <div class="table-responsive mt-3">
      <table
        class="align-middle text-truncate mb-0 table table-borderless table-hover table table-striped table-bordered"
        id="CurrencyList"
        style="width: 100%"
      >
        <thead>
          <tr>
            <th class="text-left">S.No</th>
            <th class="text-left">Currency Name</th> 
            <th class="text-left">Currency Symbol</th> 
            <th class="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of CurrencyList; index as i"  >
            <td class="text-left text-muted" style="width: 80px">{{ i + 1 }}</td>
            <td class="text-left">{{ item.currencyName }}</td>
            <td class="text-left">
              <a>{{ item.currencySymbol }}</a>
            </td>
             
            <td class="text-right">
              <div role="group" class="btn-group-sm btn-group">
                <button
                class="btn-shadow btn btn-outline-dark me-2"
                (click)="updateHotel(item, content)"
              >
                <i class="fa fa-edit"></i>
              </button>
                <button
                  class="btn-shadow btn btn-outline-danger "
                  (click)="do__Delete(item.currencyId)"
                >
                  <i class="fa fa-trash"></i>
                </button>
               
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <ng-template #content let-c="close" let-d="dismiss" class="h-100">
    <div class="modal-header ">
      <h4 class="modal-title" *ngIf="currencyDetail.currencyId.length === 0">
        Add Currency
      </h4>
      <h4 class="modal-title" *ngIf="currencyDetail.currencyId.length !== 0">
        Update Currency
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="d('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      <div class="main-card mb-3 card" style="position: relative">
        <div class="card-body position-relative">
          <app-sidebarload *ngIf="loading"></app-sidebarload>
          <form>
            <div class="row">
              <div class="col-md-12">
                <div class="position-relative mb-3">
                  <label for="subscriptionName" class="form-label"
                    >Currency Name</label
                  >
                  <input
                    name="companyName"
                    id="cname"
                    placeholder="Enter Currency name"
                    class="form-control"
                    [(ngModel)]="currencyDetail.currencyName"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="position-relative mb-3">
                  <label for="contactno" class="form-label">Currency Symbol</label>
                  <input
                    name="contact"
                    id="csymbol"
                    placeholder="Enter Currency Symbol"
                    class="form-control"
                    type="text"  
                    [(ngModel)]="currencyDetail.currencySymbol"
                  />
                </div>
              </div>
           
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" id="closemodal" (click)="c('Close click')">
        <i class="fa fa-close"></i>&nbsp; Close
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="submit()"
        *ngIf="currencyDetail.currencyId.length === 0"
      >
        <i class="fa fa-floppy-o"></i>&nbsp; Save
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="UpdateApi()"
        *ngIf="currencyDetail.currencyId.length !== 0"
      >
        <i class="fa fa-floppy-o"></i>&nbsp; Update
      </button>
    </div>
  </ng-template>
  
  
  <div class="main-card mb-3 card p-2" [ngClass]="!SkletonLoader ? 'd-none' : ''">
    <div class="card-header">
      <div class="card-header-title font-size-lg text-capitalize fw-normal">
        Manage Currency
      </div>
    </div>
    <div class="table-responsive mt-3">
      <app-skletonloader [thead]="7" [tbody]="5"></app-skletonloader>
    </div>
  </div>
  