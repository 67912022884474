import { Injectable } from "@angular/core";
import {
  Category,
  Product,
  Slider,
  SubCategory,
  Tables,
  TimeSlots,
} from "../InterFace/category";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class DataService {
  public CategoryModalData: Category;
  public SubCategoryModalData: SubCategory;
  public TimeSlotModalData: TimeSlots;
  public ProductModalData: Product;
  public SliderModalData: Slider;
  public TableModalData: Tables;
  public CategoryList:any=[];
  public SubCategoryList:any=[];
  public ProductList:any=[];
  public dpOrder:number=0;
  constructor(private api: ApiService) {}

  async getPlan() {
    this.api.getCompanyDetails().subscribe({
      next: (data: any) => {
        // console.log(data);
        if (data.statusCode == 200) {
          localStorage.setItem("PLAN", data.data?.plan);
          localStorage.setItem('Currency',data.data?.currencySymbol);
          localStorage.setItem('ACORNON',data.data?.isAcTableAvailable);
          return data.data;
        } else {

        }
        return data
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
