import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { BaseLayoutComponent } from "./Layout/base-layout/base-layout.component";
import { PagesLayoutComponent } from "./Layout/pages-layout/pages-layout.component";

// DEMO PAGES

// Dashboards

import { AnalyticsComponent } from "./DemoPages/Dashboards/analytics/analytics.component";

// Pages

import { ForgotPasswordBoxedComponent } from "./DemoPages/UserPages/forgot-password-boxed/forgot-password-boxed.component";
import { LoginBoxedComponent } from "./DemoPages/UserPages/login-boxed/login-boxed.component";
import { RegisterBoxedComponent } from "./DemoPages/UserPages/register-boxed/register-boxed.component";

// Elements

import { StandardComponent } from "./DemoPages/Elements/Buttons/standard/standard.component";
import { DropdownsComponent } from "./DemoPages/Elements/dropdowns/dropdowns.component";
import { CardsComponent } from "./DemoPages/Elements/cards/cards.component";
import { ListGroupsComponent } from "./DemoPages/Elements/list-groups/list-groups.component";
import { TimelineComponent } from "./DemoPages/Elements/timeline/timeline.component";
import { IconsComponent } from "./DemoPages/Elements/icons/icons.component";

// Components

import { AccordionsComponent } from "./DemoPages/Components/accordions/accordions.component";
// import {TabsComponent} from './DemoPages/Components/tabs/tabs.component';
import { CarouselComponent } from "./DemoPages/Components/carousel/carousel.component";
import { ModalsComponent } from "./DemoPages/Components/modals/modals.component";
import { ProgressBarComponent } from "./DemoPages/Components/progress-bar/progress-bar.component";
import { PaginationComponent } from "./DemoPages/Components/pagination/pagination.component";
import { TooltipsPopoversComponent } from "./DemoPages/Components/tooltips-popovers/tooltips-popovers.component";

// Tables

import { TablesMainComponent } from "./DemoPages/Tables/tables-main/tables-main.component";

// Widgets

import { ChartBoxes3Component } from "./DemoPages/Widgets/chart-boxes3/chart-boxes3.component";

// Forms Elements

import { ControlsComponent } from "./DemoPages/Forms/Elements/controls/controls.component";
import { LayoutComponent } from "./DemoPages/Forms/Elements/layout/layout.component";
import { ManagecategoryComponent } from "./Masters/managecategory/managecategory.component";

// Charts

import { ChartjsComponent } from "./DemoPages/Charts/chartjs/chartjs.component";
import { ManageproductComponent } from "./Masters/manageproduct/manageproduct.component";
import { ManageslotComponent } from "./Masters/manageslot/manageslot.component";
import { HoteldetailsComponent } from "./Masters/hoteldetails/hoteldetails.component";
import { ManagesliderComponent } from "./Masters/manageslider/manageslider.component";
import { ChangepasswordComponent } from "./Masters/changepassword/changepassword.component";
import { ManagerestaruntComponent } from "./SuperAdmin/managerestarunt/managerestarunt.component";
import { AddrestaruntComponent } from "./SuperAdmin/addrestarunt/addrestarunt.component";
import { AuthGuard } from "./InterFace/guard";
import { ManagetablesComponent } from "./Masters/managetables/managetables.component";
import { SubcategoryComponent } from "./Masters/subcategory/subcategory.component";
import { CurrencyComponent } from "./SuperAdmin/currency/currency.component";
import { ClientsComponent } from "./SuperAdmin/clients/clients.component";
const routes: Routes = [
  {
    path: "",
    component: PagesLayoutComponent,
    children: [
      // User Pages
      {
        path: "",
        component: LoginBoxedComponent,
        data: { extraParameter: "" },
      },
      {
        path: "pages/login-boxed",
        component: LoginBoxedComponent,
        data: { extraParameter: "" },
      },
      {
        path: "pages/register-boxed",
        component: RegisterBoxedComponent,
        data: { extraParameter: "" },
      },
      {
        path: "pages/forgot-password-boxed",
        component: ForgotPasswordBoxedComponent,
        data: { extraParameter: "" },
      },
    ],
  },
  {
    path: "",
    component: BaseLayoutComponent,
    children: [
      // Dashboads

      {
        path: "dashboard",
        component: AnalyticsComponent,
        data: { extraParameter: "dashboardsMenu" },
      },
  
      // Elements

      {
        path: "elements/buttons-standard",
        component: StandardComponent,
        data: { extraParameter: "elementsMenu" },
      },
      {
        path: "elements/dropdowns",
        component: DropdownsComponent,
        data: { extraParameter: "elementsMenu" },
      },
      {
        path: "elements/icons",
        component: IconsComponent,
        data: { extraParameter: "elementsMenu" },
      },
      {
        path: "elements/cards",
        component: CardsComponent,
        data: { extraParameter: "elementsMenu" },
      },
      {
        path: "elements/list-group",
        component: ListGroupsComponent,
        data: { extraParameter: "elementsMenu" },
      },
      {
        path: "elements/timeline",
        component: TimelineComponent,
        data: { extraParameter: "elementsMenu" },
      },

      // Components

      // {path: 'components/tabs', component: TabsComponent, data: {extraParameter: 'componentsMenu'}},
      {
        path: "components/accordions",
        component: AccordionsComponent,
        data: { extraParameter: "componentsMenu" },
      },
      {
        path: "components/modals",
        component: ModalsComponent,
        data: { extraParameter: "componentsMenu" },
      },
      {
        path: "components/progress-bar",
        component: ProgressBarComponent,
        data: { extraParameter: "componentsMenu" },
      },
      {
        path: "components/tooltips-popovers",
        component: TooltipsPopoversComponent,
        data: { extraParameter: "componentsMenu" },
      },
      {
        path: "components/carousel",
        component: CarouselComponent,
        data: { extraParameter: "componentsMenu" },
      },
      {
        path: "components/pagination",
        component: PaginationComponent,
        data: { extraParameter: "componentsMenu" },
      },

      // Tables

      {
        path: "tables/bootstrap",
        component: TablesMainComponent,
        data: { extraParameter: "tablesMenu" },
      },

      // Widgets

      {
        path: "widgets/chart-boxes-3",
        component: ChartBoxes3Component,
        data: { extraParameter: "pagesMenu3" },
      },

      // Forms Elements

      {
        path: "forms/controls",
        component: ControlsComponent,
        data: { extraParameter: "formElementsMenu" },
      },
      {
        path: "forms/layouts",
        component: LayoutComponent,
        data: { extraParameter: "formElementsMenu" },
      },

      // Charts

      {
        path: "charts/chartjs",
        component: ChartjsComponent,
        data: { extraParameter: "" },
      },

      {
        path: "masters/managecategory",
        component: ManagecategoryComponent,
        data: { extraParameter: "elementsMenu" } ,canActivate: [AuthGuard],
      },

      {
        path: "masters/subcategory",
        component: SubcategoryComponent,
        data: { extraParameter: "elementsMenu" } ,canActivate: [AuthGuard],
      },
      {path:'masters/manageproduct',component:ManageproductComponent,data:{ extraParameter: "elementsMenu" },canActivate: [AuthGuard]},
      {path:'masters/manageslots',component:ManageslotComponent,data:{ extraParameter: "elementsMenu" },canActivate: [AuthGuard]},
      {path:'masters/hoteldetails',component:HoteldetailsComponent,data:{ extraParameter: "elementsMenu" },canActivate: [AuthGuard]},
      {path:'masters/manageslider',component:ManagesliderComponent,data:{ extraParameter: "elementsMenu" },canActivate: [AuthGuard]},
      {path:'masters/changepassword',component:ChangepasswordComponent,data:{ extraParameter: "elementsMenu" },canActivate: [AuthGuard]},
      {path:'masters/managetables',component:ManagetablesComponent,data:{ extraParameter: "elementsMenu" },canActivate: [AuthGuard]},
      {
        path: "superadmin/managerestarunt",
        component: ManagerestaruntComponent,
        data: { extraParameter: "elementsMenu" },canActivate: [AuthGuard],
      },
      {
        path: "superadmin/addrestarunt",
        component: AddrestaruntComponent,
        data: { extraParameter: "elementsMenu" },canActivate: [AuthGuard],
      },
      {
        path: "superadmin/currency",
        component: CurrencyComponent,
        data: { extraParameter: "elementsMenu" },canActivate: [AuthGuard],
      },
     
    ],
  },
  {
    path: "admin/view/tenants",
    component: ClientsComponent,
  },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      relativeLinkResolution: "legacy",
      useHash:true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
