<div class="side-drawer-modal my-class">
  <app-sidebarload *ngIf="loading"></app-sidebarload>
  <div class="drawer-header modal-header">
    <h5 *ngIf="ProductItem.productId.length == 0">Add Product</h5>
    <h5 *ngIf="ProductItem.productId.length !== 0">Update Product</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="closeModal(true)"
    ></button>
  </div>

  <div class="drawer-container">
    <div class="row">
      <div class="col-md-12">
        <div class="card-body">
          <!-- <h5 class="card-title">Product Details</h5> -->

          <div class="row">
            <div [class]="Plan !=0?'col-md-6':'col-md-5'">
              <div class="position-relative mb-3">
                <label for="exampleEmail" class="form-label"
                  >Category Name<span class="mandate">*</span></label
                >
                <ng-select
                  [(ngModel)]="ProductItem.categoryId"
                  placeholder="Select Category"
                  id="cat" #catefocus
                  #catval="ngModel" (change)="getsubcatebyid(ProductItem.categoryId)"
                  [ngClass]="{ 'is-invalid': catval.invalid && catval.touched }"
                >
                  <ng-option
                    *ngFor="let category of CategoryList"
                    [value]="category.categoryId"
                    >{{ category.categoryName }}</ng-option
                  >
                </ng-select>
              </div>
            </div>
            <div *ngIf="Plan ==0"
              class="col-md-1 d-flex align-items-center justify-content-center"
            >
              <div class="cursor-pointer mt-4" (click)="openLarge(showmodal)">
                <i class="pe-7s-plus fa-2x text-primary"></i>
              </div>
            </div>
            <div class="col-md-6" *ngIf="Plan != 0">
              <div class="position-relative mb-3">
                <label for="exampleEmail" class="form-label"
                  >SubCategory Name<span class="mandate">*</span></label
                >
                <ng-select
                  [(ngModel)]="ProductItem.subcategoryId"
                  placeholder="Select SubCategory"
                  id="subcat"
                  #subcatval="ngModel"
                  [ngClass]="{
                    'is-invalid': subcatval.invalid && subcatval.touched
                  }"
                >
                  <ng-option
                    *ngFor="let subcategory of subcategoryList"
                    [value]="subcategory.subcategoryId"
                    >{{ subcategory.subcategoryName }}</ng-option
                  >
                </ng-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="position-relative mb-3">
                <label for="exampleEmail" class="form-label"
                  >Slot Timing<span class="mandate">*</span></label
                >
                <!-- <select class="form-control" [(ngModel)]="ProductItem.slotId">
                  <option value="" disabled selected>Select Time Slot</option>
                  <option
                    *ngFor="let item of TimeSlotsList"
                    [value]="item.slotId"
                  >
                    {{ common.convertTo12HourFormat(item.startTime) }} to
                    {{ common.convertTo12HourFormat(item.endTime) }}
                  </option>
                </select> -->
                <ng-select
                  [(ngModel)]="ProductItem.slotId"
                  placeholder="Select Time Slots"
                  #slottime="ngModel"
                  [ngClass]="{
                    'is-invalid': !slottime.value && slottime.touched
                  }"
                >
                  <ng-option
                    *ngFor="let item of TimeSlotsList"
                    [value]="item.slotId"
                  >
                    {{ common.convertTo12HourFormat(item.startTime) }} to
                    {{ common.convertTo12HourFormat(item.endTime) }}</ng-option
                  >
                </ng-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="position-relative mb-3">
                <label for="exampleEmail" class="form-label"
                  >Veg</label
                > 
                <div class="checkbox-wrapper-6 mt-1">
                  <input class="tgl tgl-light" id="cb1-6" type="checkbox" [(ngModel)]="ProductItem.isVeg" />
                  <label class="tgl-btn" for="cb1-6"></label>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="position-relative mb-3">
                <label for="exampleEmail" class="form-label"
                  >Product Name <span class="mandate">*</span></label
                >
                <input
                  name="email"
                  id="txtProductName"
                  #txtProductName
                  #proname="ngModel"
                  [(ngModel)]="ProductItem.productName"
                 
                  [ngClass]="{
                    'is-invalid': !proname.value && proname.touched
                  }"
                  placeholder="Enter Product Name"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="position-relative mb-3">
                <label for="exampleEmail" class="form-label"
                  >Rate <span class="mandate">*</span></label
                >
                <input
                  name="email"
                  id="txtProductRate"
                  #txtProductRate
                  [(ngModel)]="ProductItem.saleRate"
                  (keypress)="common.allowonlynumeric($event)"
                  (change)="changeAmount($event, '')"
                  placeholder="Enter Product Rate"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="position-relative mb-3">
                <label for="exampleEmail" class="form-label"
                  >AC Rate <span class="mandate">*</span></label
                >
                <input
                  name="email"
                  id="txtProductRate"
                  #txtACProductRate
                  [readonly]="disableAc"
                  [disabled]="disableAc"
                  [(ngModel)]="ProductItem.acSaleRate"
                  (keypress)="common.allowonlynumeric($event)"
                  (change)="changeAmount($event, 'AC')"
                  placeholder="Enter Product Rate"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="position-relative mb-3" *ngIf="Plan != 0">
            <div class="file-upload-container">
              <div class="file-upload-core" (click)="openUploader()">
                <img
                  [src]="ImagePath + ProductItem.dpPath"
                  *ngIf="ProductItem.dpPath.length !== 0"
                />
                <i
                  class="pe-7s-cloud-upload fs-1 text-danger"
                  *ngIf="ProductItem.dpPath.length == 0"
                ></i>
                <span>Click to upload an image for the Product</span>
                <input
                  type="file"
                  hidden
                  id="fileCDP"
                  (change)="UploadImage($event)"
                  accept="image/*"
                />
              </div>
            </div>
          </div>
          <!-- <div class="position-relative mb-3">
            <label for="exampleEmail" class="form-label"
              >Display Order <span class="mandate">*</span></label
            >
            <input
              name="email"
              id=""
              (change)="validateDisplayOrder($event)"
              (keypress)="common.allowonlynumericMobile($event)"
              #dispin="ngModel"
              [(ngModel)]="ProductItem.displayOrder"
              placeholder="Enter your Display Order"
              type="number"
              class="form-control"
              #DisplayInput
              [ngClass]="{ 'is-invalid': !dispin.value && dispin.touched }"
            />
          </div> -->
          <div class="position-relative mb-3">
            <label for="exampleText" class="form-label">Description</label>
            <textarea
              name="text"
              id="txtDescription"
              [(ngModel)]="ProductItem.description"
              placeholder="Enter Your Description Here"
              class="form-control"
            ></textarea>
          </div>
          <!-- <div class="position-relative mb-3">
            <label class="wraper" for="something">
              <div class="switch-wrap">
                <input
                  type="checkbox"
                  id="something"
                  [(ngModel)]="ProductItem.isActive"
                  [checked]="ProductItem.isActive"
                />
                <div class="switch"></div>
              </div>
              <span class="label-text">{{
                ProductItem.isActive ? "Active" : "Inactive"
              }}</span>
            </label>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="drawer-footer">
    <button
      type="button"
      class="btn-shadow align-items-center btn btn-lg btn-danger"
      (click)="closeModal(true)"
    >
      <i class="fa fa-close"></i>&nbsp; Close</button
    >&nbsp;
    <button
      type="button"
      class="btn-shadow align-items-center btn btn-lg btn-primary me-2"
      (click)="do__Save(true)"
      *ngIf="ProductItem.productId.length == 0"
    >
      <i class="fa fa-floppy-o"></i>&nbsp; Save & Close
    </button>
    <button
      type="button"
      class="btn-shadow align-items-center btn btn-lg btn-success"
      (click)="do__Save(false)"
      *ngIf="ProductItem.productId.length == 0"
    >
      <i class="fa fa-floppy-o"></i>&nbsp; Save & New
    </button>
    <button
      type="button"
      class="btn-shadow align-items-center btn btn-lg btn-primary"
      (click)="updateProduct()"
      *ngIf="ProductItem.productId.length !== 0"
    >
      <i class="fa fa-floppy-o"></i>&nbsp; Update
    </button>
  </div>
</div>
<div class="overlayDrawer my-class"></div>

<ng-template #showmodal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Add Category</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="d('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="main-card card">
      <div class="card-body">
        <app-sidebarload *ngIf="loading"></app-sidebarload>
        <form>
          <div class="row">
            <div class="col-md-12">
              <div class="position-relative mb-3">
                <label for="subscriptionName" class="form-label"
                  >Category Name</label
                >
                <input
                  name="companyName"
                  id="subscriptionName"
                  placeholder="Enter Category name"
                  class="form-control"
                  [(ngModel)]="CategoryDetails.categoryName"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="position-relative mb-3">
                <label class="form-label">Descriptions</label>
                <textarea
                  name="address"
                  class="form-control"
                  placeholder="Enter address"
                  [(ngModel)]="CategoryDetails.description"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      id="closeModal"
      (click)="c('Close click')"
    >
      <i class="fa fa-close"></i>&nbsp; Close
    </button>
    <button type="button" class="btn btn-primary" (click)="CategoryInsert()">
      <i class="fa fa-floppy-o"></i>&nbsp; Save
    </button>
  </div>
</ng-template>
