<div class="side-drawer-modal my-class">
  <app-sidebarload *ngIf="loading"></app-sidebarload>
  <div class="drawer-header modal-header">
    <div>
      <h5>Change Order</h5>
      <span>Drag and drop the below items as you require in order to reorder the list.</span>
     
    </div>
    <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal(false)"
  ></button>
  </div>

  <div class="drawer-container">
    <div class="row">
      <div class="col-md-12">
        <div class="card-body">
          <!-- <div class="table-responsive mt-3" cdkDropList (cdkDropListDropped)="drop($event)"  [cdkDropListData]="categoryItem" > 
                        <table class="align-middle text-truncate mb-0 table table-borderless table-hover table-striped table-bordered" id="category" style="width: 100%">
                          <thead>
                            <tr>
                               
                              <th class="text-left">Category Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of categoryItem; index as i" cdkDrag>
                               
                              <td class="text-left">{{ item.categoryName }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div> -->
          <div
            cdkDropList
            class="example-list"
            (cdkDropListDropped)="drop($event)"
          >
            <div class="example-box" *ngFor="let item of categoryItem" cdkDrag>
              <i class="pe-7s-menu me-2 fa-lg"> </i> {{ item.categoryName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="drawer-footer">
    <button
      type="button"
      class="btn-shadow align-items-center btn btn-lg btn-danger"
      (click)="closeModal(false)"
    >
      <i class="fa fa-close"></i>&nbsp; Close</button
    >&nbsp;
    <button
      type="button"
      class="btn-shadow align-items-center btn btn-lg btn-primary me-2"
      (click)="do__Save(true)"
    >
      <i class="fa fa-floppy-o"></i>&nbsp; Save
    </button>
  </div>
</div>
<div class="overlayDrawer my-class"></div>
