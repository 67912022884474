<div class="d-flex">
  <div class="header-btn-lg pe-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown placement="bottom-right">
            <button type="button" class="btn btn-link p-0 me-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <img width="42" src="../../../../../../assets/images/digi_black.png" alt="" class="rounded-circle">
                </div>
              </span>
            </button>
            <div ngbDropdownMenu>
              <button class="dropdown-item" (click)="gotoPage()" *ngIf="!isAdmin">
                <i class="fa fa-bank"></i>
                Hotel Details
              </button>
              <button class="dropdown-item" routerLink="masters/changepassword">
                <span class="fa fa-key"></span>
                Change Password</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item" (click)="logout()">
                <span class="fa fa-sign-out"></span>
                Logout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


