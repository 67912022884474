<!-- <app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon" [addbtn]="false" pageid="admin" ></app-page-title> -->
<div class="row">
  <div class="col-md-12 text-end mb-4">
    <button
      type="button"
      class="btn-shadow d-inline-flex align-items-center btn btn-primary"
      (click)="openLarge(content, 0)"
    >
      <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
      Add New
    </button>
  </div>
</div>
<div class="main-card mb-3 card p-2" [ngClass]="SkletonLoader ? 'd-none' : ''">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize fw-normal">
      Manage Subscriptions
    </div>
  </div>
  <div class="table-responsive mt-3">
    <table
      class="align-middle text-truncate mb-0 table table-borderless table-hover table table-striped table-bordered"
      id="RestarauntList"
      style="width: 100%"
    >
      <thead>
        <tr>
          <th class="text-left">S.No</th>
          <th class="text-left">Subscribers Name</th>
          <th class="text-left">contact No</th>
          <th class="text-left">Company Code</th>
          <th class="text-left">Url</th>
          <th class="text-left">AC Url</th>
          <th class="text-left">Currency</th>
          <th class="text-left">Status</th>
          <th class="text-left">Expires On</th>
          <th class="text-right">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of RestaruntList; index as i" [ngClass]="{'bg-light':getdateValidate(item.expiresOn)}"
          class="{{ item.status == 3 ? 'bg-light' : '' }}"
        >
          <td class="text-left text-muted" style="width: 80px">{{ i + 1 }}</td>
          <td class="text-left">{{ item.companyName }}</td>
          <td class="text-left">
            <a>{{ item.contact }}</a>
          </td>
          <td class="text-left">
            <span
              class="badge bg-primary cursor-pointer"
              (click)="copyURLToClipboard(item.companyCode)"
            >
              Copy Code <i class="pe-7s-copy-file ms-1 fa-lg"></i>
            </span>
          </td>

          <td class="text-left" style="cursor: pointer">
            <span
              (click)="copyURLToClipboard(item.qrURL)"
              class="cursor-pointer badge bg-primary"
            >
              Copy Url <i class="pe-7s-copy-file fa-lg ms-1"></i
            ></span>
          </td>
          <td class="text-left" style="cursor: pointer">
            <span
              (click)="copyURLToClipboard(item.acQrURL)"
              class="cursor-pointer badge bg-primary"
            >
              Copy Url <i class="pe-7s-copy-file fa-lg ms-1"></i
            ></span>
          </td>
          <td class="text-left" style="cursor: pointer">
           {{item.currencyName}}-{{item.currencySymbol}}  
          </td>
          <td class="text-left">
            <div class="position-relative mb-3">
              <label
                class="wraper"
                for="switch{{ i }}"
                style="display: inline-block"
              >
                <div
                  class="switch-wrap"
                  style="width: 50px; height: 30px; padding: 5px"
                >
                  <input
                    type="checkbox"
                    id="switch{{ i }}"
                    [(ngModel)]="item.isActive"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="updateStatus(item)"
                  />
                  <div class="switch"></div>
                </div>
              </label>
            </div>
            <span class="badge text-white d-none">{{
              item.isActive
                ? "Active" 
                : "InActive"
            }}</span>
          </td>

          <td class="text-left">
            <a>{{ item.expiresOn | date }}</a>
          </td>
          <td class="text-right">
            <div role="group" class="btn-group-sm btn-group">
              <button
                class="btn-shadow btn btn-outline-danger me-2"
                (click)="showDetails(item, showmodal)"
              >
                <i class="fa fa-eye"></i>
              </button>
              <button
                class="btn-shadow btn btn-outline-dark "
                (click)="updateHotel(item, content)"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss" class=" ">
  <div class="modal-header ">
    <h4 class="modal-title" *ngIf="HotelDetail.companyId.length === 0">
      Add Subscription
    </h4>
    <h4 class="modal-title" *ngIf="HotelDetail.companyId.length !== 0">
      Update Subscription
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="d('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="main-card mb-3 card" style="position: relative">
      <div class="card-body position-relative">
        <app-sidebarload *ngIf="loading"></app-sidebarload>
        <form>
          <div class="row">
            <div class="col-md-6">
              <div class="position-relative mb-3">
                <label for="subscriptionName" class="form-label"
                  >Subscription Name</label
                >
                <input
                  name="companyName"
                  id="subscriptionName"
                  placeholder="Enter Subscription name"
                  class="form-control"
                  [(ngModel)]="HotelDetail.companyName"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="position-relative mb-3">
                <label for="contactno" class="form-label">Contact No</label>
                <input
                  name="contact"
                  id="contactno"
                  placeholder="Enter Contact Number"
                  class="form-control"
                  type="tel"
                  maxlength="15"
                  (keypress)="common.allowonlynumericMobile($event)"
                  [(ngModel)]="HotelDetail.contact"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="position-relative mb-3">
                <label for="SerialKKey" class="form-label">Serial Key</label>
                <input
                  name="serialKey"
                  id="SerialKKey"
                  placeholder="Serial Key"
                  class="form-control" 
                  [(ngModel)]="HotelDetail.serialKey"
                />
              </div>
            </div>
            <!-- <div
              class="col-md-1 d-flex align-items-center justify-content-start mt-2 ps-0"
              *ngIf="HotelDetail.serialKey"
            >
              <i
                class="pe-7s-close-circle text-danger fa-2x"
                *ngIf="!validatedKey"
                (click)="HotelDetail.serialKey = ''"
              >
              </i>
              <i class="pe-7s-check text-success fa-2x" *ngIf="validatedKey">
              </i>
            </div> -->
            <div class="col-md-6">
              <div class="position-relative mb-3">
                <label for="userEmail" class="form-label">E-mail</label>
                <input
                  name="email"
                  id="userEmail"
                  placeholder="Enter Email"
                  class="form-control"
                  [(ngModel)]="HotelDetail.email"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="position-relative mb-3">
                <label class="form-label d-block">Status</label>
                <label
                  class="wraper"
                  for="status150"
                  style="display: inline-block"
                >
                  <div class="switch-wrap">
                    <input
                      type="checkbox"
                      id="status150"
                      [(ngModel)]="HotelDetail.isActive"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <div class="switch"></div>
                  </div>
                </label>
              </div>
            </div>
            <div class="col-md-3">
              <div class="position-relative mb-3">
                <label class="form-label d-block">Ac Available</label>
                <label
                  class="wraper"
                  for="statusAc160"
                  style="display: inline-block"
                >
                  <div class="switch-wrap">
                    <input
                      type="checkbox"
                      id="statusAc160"
                      [(ngModel)]="HotelDetail.isAcTableAvailable"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <div class="switch"></div>
                  </div>
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="position-relative mb-3">
                <label class="form-label d-block">Plan</label>
               <select [(ngModel)]="HotelDetail.plan" class="form-control" [ngModelOptions]="{standalone: true}">
                <option class="" value="0">Standard</option>
                <option class="" value="1">Advanced</option>
                <option class="" value="2">Elite</option>
               </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="position-relative mb-3">
                <label class="form-label d-block">Currency Type</label>
                <ng-select
                [(ngModel)]="HotelDetail.currencyId"
                placeholder="Select Currency"
                id="subcat" (change)="eventhandler($event)"
                #subcatval="ngModel"
                [ngClass]="{
                  'is-invalid': subcatval.invalid && subcatval.touched
                }"
              >
                <ng-option 
                  *ngFor="let curr of CurrencyList"
                  [value]="curr.currencyId"
                  >{{ curr.currencyName }}-{{curr.currencySymbol}}</ng-option
                >
              </ng-select>
              </div>
            </div>
            <div class="col-md-12">
              <div class="position-relative mb-3">
                <label class="form-label">Address</label>
                <textarea
                  name="address" id="txtaddress"
                  class="form-control"
                  placeholder="Enter address"
                  [(ngModel)]="HotelDetail.address"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" id="closemodal" (click)="c('Close click')">
      <i class="fa fa-close"></i>&nbsp; Close
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="submit()"
      *ngIf="HotelDetail.companyId.length === 0"
    >
      <i class="fa fa-floppy-o"></i>&nbsp; Save
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="UpdateApi()"
      *ngIf="HotelDetail.companyId.length !== 0"
    >
      <i class="fa fa-floppy-o"></i>&nbsp; Update
    </button>
  </div>
</ng-template>

<ng-template #showmodal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Subscription Detail</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="d('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="main-card card">
      <div class="card-body">
        <form>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">Subscription Name</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">
                :&nbsp; {{ HotelDetail.companyName }}
              </p>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">Subscription Code</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">
                :&nbsp;<button
                  class="btn btn-primary"
                  (click)="copyURLToClipboard(HotelDetail.companyCode)"
                  style="font-size: 10px"
                >
                  {{ HotelDetail.companyCode }}
                  <i class="pe-7s-copy-file ms-2 fa-lg"></i>
                </button>
              </p>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">User Name</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">:&nbsp;
                <button *ngIf="LoadingText"
                class="btn btn-primary"
               >
                Loading...
                <i class="pe-7s-copy-file ms-2 fa-lg"></i>
              </button>
                <button *ngIf="!LoadingText"
                class="btn btn-primary"
                (click)="copyURLToClipboard(userCreditial.userName)"
                style="font-size: 12px"
              >
                 {{userCreditial.userName}}
                <i class="pe-7s-copy-file ms-2 fa-lg"></i>
              </button>
            </p>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">Password</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">:&nbsp;
                <button *ngIf="LoadingText"
                class="btn btn-primary"
               >
                Loading...
                <i class="pe-7s-copy-file ms-2 fa-lg"></i>
              </button>
                <button *ngIf="!LoadingText"
                class="btn btn-primary"
                (click)="copyURLToClipboard(userCreditial.password)"
                style="font-size: 12px"
              > {{userCreditial.password}}
                <!-- {{ HotelDetail.companyCode }} -->
                <i class="pe-7s-copy-file ms-2 fa-lg"></i>
              </button>
            </p>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">Serial Key</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">
                :&nbsp;
                <span class="" 
                  >{{ HotelDetail.serialKey }}
                  <!-- <i class="pe-7s-copy-file ms-2 fa-lg"></i > (click)="copyURLToClipboard(HotelDetail.serialKey)"-->
                </span>
              </p>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">Contact No</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">
                :&nbsp; {{ HotelDetail.contact }}
              </p>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">E-mail</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">
                :&nbsp; {{ HotelDetail.email }}
              </p>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">Status</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">
                :&nbsp;
                <span
                class="badge rounded-pill text-white {{
                  HotelDetail.status == 1
                    ? 'bg-primary'
                    : HotelDetail.status == 3
                    ? 'bg-warning'
                    : HotelDetail.status == 0
                    ? 'bg-info'
                    : 'bg-danger'
                }}"
              >
                {{
                  HotelDetail.status == 1
                    ? "Active"
                    : HotelDetail.status == 3
                    ? "Expired"
                    : HotelDetail.status == 0
                    ? "Pending"
                    : "Inactive"
                }}
              </span>
              
              </p>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">Plan</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">
                :&nbsp;
                <span
                class="badge rounded-pill text-white bg-primary">
                {{
                  HotelDetail.plan == 0 ? "Basic" : (HotelDetail.plan == 1 ? "Advanced" : "Elite")
                     
                }}
              </span>
              
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">
      <i class="fa fa-close"></i>&nbsp; Close
    </button>
  </div>
</ng-template>
<div class="main-card mb-3 card p-2" [ngClass]="!SkletonLoader ? 'd-none' : ''">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize fw-normal">
      Manage Subscriptions
    </div>
  </div>
  <div class="table-responsive mt-3">
    <app-skletonloader [thead]="7" [tbody]="5"></app-skletonloader>
  </div>
</div>
