import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Category } from "src/app/InterFace/category";
import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { DataService } from "src/app/Services/data.service";
import { EventdriverService } from "src/app/Services/eventdriver.service";
import { TOAST_TYPE, ToasterService } from "src/app/Services/toaster.service";
import { RefreshEvent, environment } from "src/environments/environment";

@Component({
  selector: "app-addeditcategory",
  templateUrl: "./addeditcategory.component.html",
  styleUrls: ["./addeditcategory.component.sass"],
})
export class AddeditcategoryComponent implements OnInit {
  availstatus: boolean;
  categoryItem: Category;
  ImagePath: string = environment.ImagePath;
  loading:boolean=false;
  Plan:any='0';

  @ViewChild("categoryNameInput") private txtCategoryName: ElementRef;
  @ViewChild("DisplayInput") private DisplayInput: ElementRef;

  constructor(
    private eventDriver: EventdriverService,
    private toasterService: ToasterService,
    private Data: DataService,
    private Api: ApiService,
    public common: CommonService,
  ) {
    this.Plan= localStorage.getItem('PLAN');
    if(this.Plan==null){
      this.Data.getPlan();
    }
    this.categoryItem = this.Data.CategoryModalData;
    if (this.categoryItem == undefined) {
      this.categoryItem = {
        categoryId: "",
        companyId: "",
        dpPath: "",
        categoryName: "",
        description: "",
         
        isActive: true,
        isArchived: false,displayOrder:0,isSystemRecord:false,
      };
    }
  }
  validateDisplayOrder(event){
    if(this.categoryItem.categoryId.length !==0){
      return 
     }
     var displayList=this.Data.CategoryList.find((data:any)=>data.displayOrder==this.categoryItem.displayOrder)
     if(displayList){
      this.toasterService.showToast(`Display Order Already Exits In ${displayList.categoryName}`,TOAST_TYPE.Warning);
      this.categoryItem.displayOrder=0;
      this.DisplayInput.nativeElement.focus();
     }
  }
  test(txtCategoryName){
     
  }
  ngOnInit(): void {}
  ngAfterViewInit() {
    this.txtCategoryName.nativeElement.focus();
  }

  closeModal(val) {
    this.eventDriver.triggerEvent({
      IsClosed: val,
      Modal: RefreshEvent.Category,
    });
    this.categoryItem = {
      categoryId: "",
      companyId: "",
      dpPath: "",
      categoryName: "",
      description: "",
      isActive: true,
      isArchived: false,displayOrder:0,isSystemRecord:false,
    };
    if(val)this.txtCategoryName.nativeElement.focus();
  }

   do__Save(val:boolean) {
    if (this.isValidObj()) {
      this.loading=true;
      this.categoryItem.companyId=this.Api.getCompanyId();
      this.Api.AddCategory(this.categoryItem).subscribe(
        (data: any) => {
           
          this.eventDriver.updated.next(RefreshEvent.Category);
           
          this.loading=false; 
          if (data.statusCode==200) {
            this.toasterService.showToast(data.message, TOAST_TYPE.Success);
            this.closeModal(val);
          } else {
            this.toasterService.showToast(data.message, TOAST_TYPE.Error);
          }
        },
        (err) => {
          this.loading=false;
          console.log(err);
           
        }
      );
    }
  }

  isValidObj() {
    if (this.categoryItem.categoryName.length == 0) {
      this.txtCategoryName.nativeElement.focus();
      this.toasterService.showToast(
        "Please enter category name to proceed",
        TOAST_TYPE.Warning
      );
      return false;
    }
    // if (this.categoryItem.displayOrder == 0) {
    //   this.DisplayInput.nativeElement.focus();
    //   this.toasterService.showToast(
    //     "Please enter Display Order to proceed",
    //     TOAST_TYPE.Warning
    //   );
    //   return false;
    // }
    return true;
  }

  openUploader() {
    document.getElementById("fileCDP").click();
  }
  UploadImage(event) {
    const formData = new FormData();
    const files = event.target.files[0];
    formData.append('files', files); // Append file with key 'files'
  
    this.loading = true;
    this.Api.imageUpload(formData).subscribe(
      (data: any) => {
        this.loading = false; 
        if (data.isSucceeded) {
          this.categoryItem.dpPath = data.errorNote;
        } else {
          this.toasterService.showToast(data.errorNote, 3);
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }
  
  updateCategory() {
    if (this.isValidObj) {
      this.Api.UpdateCategory(this.categoryItem).subscribe(
        (data: any) => { 
          if (data.statusCode==200) {
            this.toasterService.showToast(data.message, TOAST_TYPE.Success);
            this.closeModal(true);
            this.Data.CategoryModalData = undefined;
          } else {
            this.toasterService.showToast(data.message,  TOAST_TYPE.Error);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
}
