import { Injectable } from '@angular/core';
import { CRYPTER_TYPE } from 'src/environments/environment';
import { CrypterService } from './crypter.service';

@Injectable({
  providedIn: 'root'
})
export class StorageutilityService {


  constructor(private crypterService: CrypterService) {


  }

  getValue(key: string, isanObject: boolean) {

    if (isanObject) {
      let gotObj: any = this.crypterService.getCryptedString(localStorage.getItem(key), CRYPTER_TYPE.DECRYPT);
      if (gotObj != null && gotObj != undefined)
        return JSON.parse(gotObj);
      else
        return null;
    }
    else
      return this.crypterService.getCryptedString(localStorage.getItem(key), CRYPTER_TYPE.DECRYPT)

  }

  async setValue(key: string, store_obj: any, isanObject: boolean) {

    if (isanObject)
      store_obj = JSON.stringify(store_obj);

    localStorage.setItem(key, this.crypterService.getCryptedString(store_obj, CRYPTER_TYPE.ENCRYPT));

  }

  removeKey(key: string) {
    localStorage.removeItem(key);
  }

  clearLocalStorage() { 
    localStorage.clear();
  }
}