import { Component, OnInit } from "@angular/core";
import { EventdriverService } from "src/app/Services/eventdriver.service";
import swal, { SweetAlertOptions, SweetAlertIcon } from "sweetalert2";
import { TOAST_TYPE, ToasterService } from "src/app/Services/toaster.service";
import {
  DELETE_DESC,
  RefreshEvent,
  modalOpen,
} from "src/environments/environment";
import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { Category, ReorderModal } from "src/app/InterFace/category";
import { DataService } from "src/app/Services/data.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css'; // Import the default DataTables CSS
@Component({
  selector: "app-managecategory",
  templateUrl: "./managecategory.component.html",
  styleUrls: ["./managecategory.component.sass"],
})
export class ManagecategoryComponent implements OnInit {
  heading = "Manage Categories";
  subheading = "Manage the food categories you would like to organize.";
  icon = "pe-7s-note2 icon-gradient bg-happy-itmeo";
  pageid = modalOpen.CategoryModal;
  CategoryList: Category[] = [];
  SkletonLoader: boolean = true;
  TableLoad = "";
  Plan: any = 0;
  openReorderModal: boolean = false;
  constructor(
    private eventDriver: EventdriverService,
    private toasterService: ToasterService,
    private Api: ApiService,
    private common: CommonService,
    private data: DataService
  ) {
    this.eventDriver.refreshevent().subscribe((data) => {
      if (data == RefreshEvent.Category) {
        this.ListCategories();
      }
    });
    this.data.getPlan();
    this.Plan = localStorage.getItem("PLAN");
    this.data.getPlan();
  }

  ngOnInit(): void {
    this.ListCategories();
  }
  closeModal(ev) {
    var obj: ReorderModal = JSON.parse(ev);
    if (obj.refresh) {
      this.ListCategories();
    }
    this.openReorderModal = obj.modal;
  }
  openModel() {
    this.eventDriver.triggerEvent({
      IsClosed: false,
      Modal: RefreshEvent.Category,
    });
  }

  do__Delete(id) {
    let soption: SweetAlertOptions = {
      title: `${DELETE_DESC.title}`,
      text: `${DELETE_DESC.desc} ${id.categoryName} !`,
      icon: "error",
      showConfirmButton: true,
      showCancelButton: true,
    };

    swal.fire(soption).then((willDelete) => {
      if (willDelete.value) {
        this.DeleteCategory(id.categoryId);
      } else {
        // swal.fire("Fail");
      }
    });
  }

  async loadDataTable() {
    // new DataTable('#example');
    const tableElement = $("#category");

    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable("#category")) {
      // Destroy the existing DataTable
      tableElement.DataTable().destroy();
    }

    setTimeout(() => {
      $("#category").DataTable({
        pagingType: "full_numbers",
        pageLength: 5,
        processing: true,
        lengthMenu: [5, 10, 25],
        dom: "Blfrtip",
        buttons: [
          {
            extend: "print",
            text: " Print",
            className: "table-button button btn btn-primary mb-2",
          },
          {
            extend: "excel",
            text: " Excel",
            className: "table-button button btn btn-success mb-2",
          },
        ],
        columnDefs: [
          {
            orderable: false,
            targets: -1, // This targets the last column
          },
        ],
      } as any);
      setTimeout(() => {
        this.SkletonLoader = false;
        const searchInput = $("#category_filter input");
        // searchInput.wrap('<div class="search-wrapper"></div>');
        searchInput.after(
          '<span class="searchTableIcon"><i class="fa fa-search"></i></span>'
        );
      }, 10);
      // Use 'as any' to avoid type checking for this specific object
    }, 1);
  }

  ListCategories() {
    this.SkletonLoader = true;
    this.Api.getCategory().subscribe(
      (data: any) => {
        this.common.dismissloading();
        // var data=JSON.parse(res);
        if (data.statusCode == 200) {
          this.CategoryList = data.data;
          this.data.CategoryList = data.data;
          if (this.CategoryList.length != 0) {
            this.data.dpOrder =
              this.CategoryList[this.CategoryList.length - 1].displayOrder + 1;
          }
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
        this.loadDataTable();
      },
      (err) => {
        console.log(err, "API ERROR");
        this.common.dismissloading();
      }
    );
  }
  openReOrderModal() {
    this.openReorderModal = false;
    setTimeout(() => {
      this.openReorderModal = true;
    }, 10);
  }
  EditCategory(item: any) {
    this.data.CategoryModalData = item;
    this.openModel();
  }
  changeStatus(item) {
    this.common.showloading();
    this.Api.UpdateCategory(item).subscribe(
      (data: any) => {
        this.common.dismissloading();
        if (data.statusCode == 200) {
          this.ListCategories();
          // this.toasterService.showToast(data.message, TOAST_TYPE.Success);
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Success);
        }
      },
      (err) => {
        this.common.dismissloading();
        console.log(err);
      }
    );
  }
  DeleteCategory(id: any) {
    this.common.showloading();
    this.Api.deleteCategory(id).subscribe(
      (data: any) => {
        this.common.dismissloading();
        if (data.statusCode == 200) {
          this.toasterService.showToast(data.message, TOAST_TYPE.Warning);
          this.ListCategories();
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
        this.common.dismissloading();
        console.log(err);
      }
    );
  }
}
