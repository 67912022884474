<div class="table-container">
    <table>
      <thead>
        <tr> 
          <th *ngFor="let head of [].constructor(thead); let i = index"><div class="skeleton skeleton-header"></div></th>
          <!-- <th><div class="skeleton skeleton-header"></div></th>
          <th><div class="skeleton skeleton-header"></div></th>
          <th><div class="skeleton skeleton-header"></div></th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of [].constructor(tbody); let i = index">
          <td *ngFor="let head of [].constructor(thead); let i = index"><div class="skeleton skeleton-cell"></div></td>
        
        </tr>
       
      </tbody>
    </table>
  </div>