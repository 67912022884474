<div class="h-100 bg-plum-plate bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <!-- <div class="app-logo-inverse mx-auto mb-3"></div> -->
      <!-- <div>
        <img src="../../../../assets/images/logo.png"/>
      </div> -->
      <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
          <div class="modal-body">
            <div class="h5 modal-title text-center">
              <h4 class="mt-2">
                <div>Welcome back,</div>
                <span>Please sign in</span>
              </h4>
            </div>
            <div class="mb-3" *ngIf="!token">
              <div>
                <input
                  type="text"
                  [(ngModel)]="SerialKey"
                  #skey
                  placeholder="Enter Access Key"
                  class="form-control" autocomplete="off"
                  (keydown.enter)="logmein()"
                />
              </div>
            </div>
            <div class="mb-3">
              <div>
                <input
                  type="text"
                  [(ngModel)]="userName"
                  #uname autocomplete="false"
                  placeholder="Enter User Name"
                  class="form-control"
                  (keydown.enter)="logmein()"
                />
              </div>
            </div>
            <div class="mb-3">
              <div>
                <input
                  type="password" autocomplete="false"
                  [(ngModel)]="password"
                  #psw
                  placeholder="Enter password"
                  class="form-control"
                  (keydown.enter)="logmein()"
                />
              </div>
            </div>
            <!-- <div class="custom-control custom-checkbox form-check">
              <input id="exampleCheck" type="checkbox" name="check" autocomplete="off" class="form-check-input" value="true">
              <label for="exampleCheck" class="form-label form-check-label">
                Keep me logged in
              </label>
            </div> -->
            <!-- <div class="divider"></div>
            <h6 class="mb-0">
              No account?
              <a [routerLink]="" class="text-primary">Sign up now</a>
            </h6> -->
          </div>
          <div class="modal-footer   d-flex align-items-center {{token?'justify-content-between':'justify-content-end'}}">
            <!-- <div class="float-start"><a [routerLink]="" class="btn-lg btn btn-link">Recover
                Password</a></div> -->
                <div class="" *ngIf="token">
                  <button class="btn btn-danger " (click)="token=''">Signout</button>
                </div>
            <div class="">
              <button
                type="button"
                class="btn btn-primary btn-lg"
                (click)="logmein()"
                *ngIf="!Loading"
              >
                Login
              </button>
              <button
                type="button"
                *ngIf="Loading"
                class="btn btn-primary btn-lg"
              >
                Loading Please Wait
              </button>
            </div>
         
          </div>
        </div>
      </div>
      <div class="text-center text-white opacity-8 mt-3">
        Copyright © Green Hood Tech {{ year }}
      </div>
    </div>
  </div>
</div>
