import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Category, SubCategory } from "src/app/InterFace/category";
import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { DataService } from "src/app/Services/data.service";
import { EventdriverService } from "src/app/Services/eventdriver.service";
import { ToasterService, TOAST_TYPE } from "src/app/Services/toaster.service";
import { environment, RefreshEvent } from "src/environments/environment";

@Component({
  selector: "app-addsubcategory",
  templateUrl: "./addsubcategory.component.html",
  styleUrls: ["./addsubcategory.component.sass"],
})
export class AddsubcategoryComponent implements OnInit {
  availstatus: boolean;
  categoryItem: Category;
  SubcategoryItem: SubCategory;
  ImagePath: string = environment.ImagePath;
  loading: boolean = false;
  Plan: any = "0";

  @ViewChild("categoryNameInput") private txtCategoryName: ElementRef;
  @ViewChild("categoryNameSelect") private categoryNameSelect: ElementRef;
  @ViewChild("DisplayInput") private DisplayInput: ElementRef;

  constructor(
    private eventDriver: EventdriverService,
    private toasterService: ToasterService,
    private Data: DataService,
    private Api: ApiService,
    public common: CommonService
  ) {
    this.Plan = localStorage.getItem("PLAN");
    if (this.Plan == null) {
      this.Data.getPlan();
    }
    this.SubcategoryItem = this.Data.SubCategoryModalData;
    if (this.SubcategoryItem == undefined) {
      this.SubcategoryItem = {
        categoryId : "",
        categoryName : "",
        companyId :"",
        description : "",
        displayOrder : 0,
        dpPath : "",
        isActive : true,
        isArchived : false,
        isSystemRecord : false,
        subcategoryId :"",
        subcategoryName : "",
      };
    }
  }
  test(txtCategoryName) { 
  }
  getCategories(){
    this.Api.getCategory().subscribe({next:(data:any)=>{
     
      if (data.statusCode == 200) {
        this.categoryItem=data.data;
      }
    },error:(err)=>{console.log(err)}})
  }
  ngOnInit(): void {
    this.getCategories()
  }
  ngAfterViewInit() {
    this.txtCategoryName.nativeElement.focus();
  }
  validateDisplayOrder(event){
    if(this.SubcategoryItem.subcategoryId.length !==0){
      return
     }
    var displayList=this.Data.SubCategoryList.find((data:any)=>data.displayOrder==this.SubcategoryItem.displayOrder)
    if(displayList){
     this.toasterService.showToast(`Display Order Already Exits In ${displayList.categoryName}`,TOAST_TYPE.Warning);
     this.SubcategoryItem.displayOrder=0;
     this.DisplayInput.nativeElement.focus();
    }
 }
  closeModal(val) {
    this.eventDriver.triggerEvent({
      IsClosed: val,
      Modal: RefreshEvent.SubCategory,
    });
    this.SubcategoryItem = {
      categoryId : "",
      categoryName : "",
      companyId :"",
      description : "",
      displayOrder : 0,
      dpPath : "",
      isActive : true,
      isArchived : false,
      isSystemRecord : false,
      subcategoryId :"",
      subcategoryName : "",
    };
    if (val) this.txtCategoryName.nativeElement.focus();
  }

  do__Save(val: boolean) {
    if (this.isValidObj()) {
      this.loading = true;
      this.SubcategoryItem.companyId = this.Api.getCompanyId();
      
      this.Api.AddSubCategory(this.SubcategoryItem).subscribe(
        (data: any) => {
          this.eventDriver.updated.next(RefreshEvent.SubCategory);

          this.loading = false;
          if (data.statusCode == 200) {
            this.toasterService.showToast(data.message, TOAST_TYPE.Success);
            this.closeModal(val);
          } else {
            this.toasterService.showToast(data.message, TOAST_TYPE.Error);
          }
        },
        (err) => {
          this.loading = false;
          console.log(err);
        }
      );
    }
  }

  isValidObj() {
    if (this.SubcategoryItem.categoryId.length == 0) {
      // this.txtCategoryName.nativeElement.focus();
      this.toasterService.showToast(
        "Please Select category name to proceed",
        TOAST_TYPE.Warning
      );
      return false;
    }
    if (this.SubcategoryItem.subcategoryName.length == 0) {
      this.txtCategoryName.nativeElement.focus();
      this.toasterService.showToast(
        "Please enter Subcategory name to proceed",
        TOAST_TYPE.Warning
      );
      return false;
    }
    // if (this.SubcategoryItem.displayOrder== 0) {
    //   this.DisplayInput.nativeElement.focus();
    //   this.toasterService.showToast(
    //     "Please enter Display Order to proceed",
    //     TOAST_TYPE.Warning
    //   );
    //   return false;
    // }
    return true;
  }

  openUploader() {
    document.getElementById("fileCDP").click();
  }
  UploadImage(event) {
    const formData = new FormData();
    const files = event.target.files[0];
    formData.append("files", files); // Append file with key 'files'

    this.loading = true;
    this.Api.imageUpload(formData).subscribe(
      (data: any) => {
        this.loading = false; 
        if (data.isSucceeded) {
          this.SubcategoryItem.dpPath = data.errorNote;
        } else {
          this.toasterService.showToast(data.errorNote, 3);
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  updateCategory() {
    if (this.isValidObj) {
      this.Api.UpdateSubCategory(this.SubcategoryItem).subscribe(
        (data: any) => {
          if (data.statusCode == 200) {
            this.toasterService.showToast(data.message, TOAST_TYPE.Success);
            this.closeModal(true);
            this.Data.SubCategoryModalData = undefined;
          } else {
            this.toasterService.showToast(data.message, TOAST_TYPE.Error);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
}
