import { Component, OnInit } from "@angular/core";
import { Color } from "ng2-charts/ng2-charts";
import {
  faTh,
  faCheck,
  faTrash,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { TOAST_TYPE, ToasterService } from "src/app/Services/toaster.service";
import { StorageutilityService } from "src/app/Services/storageutility.service";
import { StorageKey } from "src/environments/environment";
import { DataService } from "src/app/Services/data.service";
 

@Component({
  selector: "app-analytics",
  templateUrl: "./analytics.component.html",
})
export class AnalyticsComponent implements OnInit {
  faTh = faTh;
  faCheck = faCheck;
  faTrash = faTrash;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;

  heading = " Dashboard";
  subheading =
    "Show All Details Total Counts Here.";
  icon = "pe-7s-plane icon-gradient bg-tempting-azure";
  isAdmin: boolean = false;
  loading: boolean = false;

  constructor(
    private Api: ApiService,
    private common: CommonService,
    private toaster: ToasterService,
    private storage: StorageutilityService,private dataService:DataService
  ) {
    var data = this.storage.getValue(StorageKey.UserDetails, true);
 this.dataService.getPlan();
    if (data) {
      this.isAdmin = data.isSuperAdmin;
    }

    if (this.isAdmin) {
      this.getAdminDashboard();
    } else {
      this.getVendorDashboard();
    }
  }

  ngOnInit(): void {}

  getVendorDashboard() {
    this.loading = true;
    this.Api.getVendorDashboard().subscribe(
      (data: any) => { 
        this.loading = false;
        if (data.statusCode==200) {
          this.DashboardDetails = data.data;
          console.log(this.DashboardDetails);
        }else{
          this.toaster.showToast(data.message,TOAST_TYPE.Error)
        }
      },
      (err) => {
        console.log(err);
        this.loading = false;
      }
    );
  }
  DashboardDetails: any = {};
  getAdminDashboard() {
    this.loading=true;
    this.Api.getDashboardForAdmin().subscribe(
      (data: any) => {
       this.loading=false;
        if (data.statusCode==200) {
          this.DashboardDetails = data.data;
          console.log(this.DashboardDetails);
        }else{
          this.toaster.showToast(data.message,TOAST_TYPE.Error)
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
