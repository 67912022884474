import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { StorageutilityService } from "src/app/Services/storageutility.service";
import { TOAST_TYPE, ToasterService } from "src/app/Services/toaster.service";
import { StorageKey } from "src/environments/environment";

@Component({
  selector: "app-changepassword",
  templateUrl: "./changepassword.component.html",
  styleUrls: ["./changepassword.component.sass"],
})
export class ChangepasswordComponent implements OnInit {
  @ViewChild("txtcurrentpsw") private txtcurrentpsw: ElementRef;
  @ViewChild("txtnewpsw") private txtnewpsw: ElementRef;
  @ViewChild("txtconfirmpsw") private txtconfirmpsw: ElementRef;
  PasswordDetail: psw = {
    confirmPassword: "",
    currentPassword: "",
    newPassword: "",
  };
  heading = "Change Password";
  subheading = "Manage the password you would like to organize.";
  icon = "pe-7s-door-lock icon-gradient bg-tempting-azure";
  constructor(
    private toasterService: ToasterService,
    private Api: ApiService,
    private Common: CommonService,
    private Toaster: ToasterService,
    private storage: StorageutilityService,
    private router: Router
  ) {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.txtcurrentpsw.nativeElement.focus();
  }
  loading: boolean = false;
  do__Save() {
    var userDetails = this.storage.getValue(StorageKey.UserDetails, true);
    
    if (this.isValidObj()) {
      this.loading = true;
      this.Api.changePassword(
        userDetails.userId,
        this.PasswordDetail.currentPassword,
        this.PasswordDetail.newPassword
      ).subscribe(
        (data: any) => {
         
          if (data.statusCode==200) {
            this.reset();
            this.Toaster.showToast(data.message,TOAST_TYPE.Success);
            this.storage.removeKey(StorageKey.UserDetails);
            this.router.navigateByUrl("/");
          }else{
            this.Toaster.showToast(data.message,TOAST_TYPE.Error);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  reset() {
    this.PasswordDetail = {
      confirmPassword: "",
      currentPassword: "",
      newPassword: "",
    };
    this.txtcurrentpsw.nativeElement.focus();
  }
  isValidObj() {
    if (this.PasswordDetail.currentPassword.length == 0) {
      this.txtcurrentpsw.nativeElement.focus();
      this.toasterService.showToast(
        "Please enter your Current Password to proceed",
        TOAST_TYPE.Warning
      );
      return false;
    }
    if (this.PasswordDetail.newPassword.length == 0) {
      this.txtnewpsw.nativeElement.focus();
      this.toasterService.showToast(
        "Please enter your New Password to proceed",
        TOAST_TYPE.Warning
      );
      return false;
    }
    if (this.PasswordDetail.confirmPassword.length == 0) {
      this.txtconfirmpsw.nativeElement.focus();
      this.toasterService.showToast(
        "Please enter your Confirm Password to proceed",
        TOAST_TYPE.Warning
      );
      return false;
    }
    if (
      this.PasswordDetail.confirmPassword != this.PasswordDetail.newPassword
    ) {
      this.txtconfirmpsw.nativeElement.focus();
      this.toasterService.showToast("password Incorrect", TOAST_TYPE.Warning);
      return false;
    }
    return true;
  }
}
export class psw {
  confirmPassword: string;
  currentPassword: string;
  newPassword: string;
}
