import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ReorderModal } from "src/app/InterFace/category";

import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { DataService } from "src/app/Services/data.service";
import { EventdriverService } from "src/app/Services/eventdriver.service";
import { ToasterService, TOAST_TYPE } from "src/app/Services/toaster.service";

@Component({
  selector: "app-product-reorder",
  templateUrl: "./product-reorder.component.html",
  styleUrls: ["./product-reorder.component.scss"],
})
export class ProductReorderComponent implements OnInit {
  categoryItem: Category[] = [];

  loading: boolean = false;
  @Output() newItemEvent = new EventEmitter<string>();
  @ViewChild("categoryNameInput") private txtCategoryName: ElementRef;
  @ViewChild("DisplayInput") private DisplayInput: ElementRef;
  ProductList: any = [
    {
      id: 1,
      name: "Category 1",
      expanded: false,
      products: [
        { id: 1, name: "Product 1" },
        { id: 2, name: "Product 2" },
      ],
    },
    {
      id: 2,
      name: "Category 2",
      expanded: false,
      products: [
        { id: 3, name: "Product 3" },
        { id: 4, name: "Product 4" },
      ],
    },
  ];
  constructor(
    private eventDriver: EventdriverService,
    private toasterService: ToasterService,
    private Data: DataService,
    private Api: ApiService,
    public common: CommonService
  ) {
    // this.ListCategories();
    this.getCategoryProduct();
  }
  ngOnInit(): void {}

  closeModal(val) {
    var obj:ReorderModal={
      modal:false,
      refresh:val
   }
 
     this.newItemEvent.emit(JSON.stringify(obj));
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   console.log(this.categoryItem, event.previousIndex, event.currentIndex);
  //   moveItemInArray(this.categoryItem, event.previousIndex, event.currentIndex);
  // }
  // drop(event: CdkDragDrop<string[]>) {
  //   console.log(this.categoryItem, event.previousIndex, event.currentIndex);
  //   moveItemInArray(this.categoryItem, event.previousIndex, event.currentIndex);
  // }
  do__Save(val: boolean) {
    this.loading=true;
    let obj: any[] = this.ProductList.flatMap(product => product.products);

    this.Api.ProductBulkUploadReorder(obj).subscribe({
      next: (data: any) => {
         
        this.loading=false;
        if(data.statusCode==200){ 
          this.closeModal(true);
          this.toasterService.showToast(data.message,TOAST_TYPE.Success);
        }else{
          this.toasterService.showToast(data.message,TOAST_TYPE.Error);
        }
      },
      error: (err) =>{this.loading=false; console.log(err)},
    });
  }
 
  toggleCategory(index: number) {
    this.ProductList[index].expanded = !this.ProductList[index].expanded;
  }

  drop(event: CdkDragDrop<Product[]>, categoryIndex: number) {
    const Product = this.ProductList[categoryIndex];
    if (event.previousContainer === event.container) {
      moveItemInArray(
        Product.products,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  getCategoryProduct() {
    this.loading = true;
    this.Api.GetCategoryProduct().subscribe({
      next: (data: any) => {
        this.loading = false; 
        if (data.statusCode == 200) {
          this.ProductList = data.data;
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
        console.log(data);
      },
      error: (err) => console.log(err),
    });
  }
}
interface Product {
  id: number;
  name: string;
}

interface Category {
  id: number;
  name: string;
  expanded: boolean;
  products: Product[];
}
