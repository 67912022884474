<!-- <div class="custom-loading" *ngIf="loader">
    <div class="body">
      <div class="ring">
        <img src="../assets/images/logo.png" style="    padding-top: 7px; width: 130px; margin-right: 3px;"/>
        <span></span>
      </div>
    </div>
  </div> -->
<router-outlet></router-outlet>

