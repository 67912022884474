import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { faStar, faPlus } from "@fortawesome/free-solid-svg-icons";
import swal, { SweetAlertOptions, SweetAlertIcon } from "sweetalert2";
import { NavigationExtras, Router } from "@angular/router";
import { TOAST_TYPE, ToasterService } from "src/app/Services/toaster.service";
import { ApiService } from "src/app/Services/api.service";
import { ValidateService } from "src/app/Services/validate.service";
import { HotelDetails } from "src/app/InterFace/category";
import { NgForm } from "@angular/forms";
import { CommonService } from "src/app/Services/common.service";
import { SUBSCRIPTION_STATUS, StorageKey } from "src/environments/environment";
import { StorageutilityService } from "src/app/Services/storageutility.service";
import { EventdriverService } from "src/app/Services/eventdriver.service"; 
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css'; // Import the default DataTables CSS

declare var $: any;
@Component({
  selector: "app-managerestarunt",
  templateUrl: "./managerestarunt.component.html",
  styleUrls: ["./managerestarunt.component.sass"],
})
export class ManagerestaruntComponent implements OnInit {
  heading = "Manage Restaraunt";
  subheading =
    " Add any pertinent details or updates about restaurant listings here for internal reference.";
  icon = "pe-7s-graph1 icon-gradient bg-tempting-azure";
  faStar = faStar;
  faPlus = faPlus;
  SkletonLoader: boolean = true;
  RestaruntList: any = [];
  // @ViewChild("bname") bnameInput: ElementRef;
  @ViewChild("bname") bname!: ElementRef;
  @ViewChild("contactno") contactnoInput: ElementRef;
  @ViewChild("skey") skeyInput: ElementRef;
  @ViewChild("email") emailInput: ElementRef;
  private modalRef: NgbModalRef | undefined;

  @ViewChild("content") content!: ElementRef;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private toasterService: ToasterService,
    private Api: ApiService,
    private storage: StorageutilityService,
    private validator: ValidateService,
    public common: CommonService,
    private eventmapper: EventdriverService
  ) {}

  ngOnInit(): void {
    this.getCompanyList();
    this.getCurrencyList();
   
  }

  ngAfterViewInit() {
    $(document).ready(function() {
      let modalContent = $('.modal-dialog');
      let modalHeader = $('.modal-header');
      modalHeader.addClass('cursor-all-scroll');
      modalContent.draggable({
        handle: '.modal-header'
      });
    });
  }
  getCompanyList() {
    this.SkletonLoader = true;
    this.Api.getAllCompany().subscribe(
      (res: any) => {
        this.SkletonLoader = false;
        if (res.statusCode == 200) {
          this.RestaruntList = res.data;
          this.loadDataTable();
        } else {
          this.toasterService.showToast(res.message, TOAST_TYPE.Error);
        }
         
      },
      (err) => {
        console.log(err);
        this.SkletonLoader = false;
      }
    );
  }

  async loadDataTable() {
    // new DataTable('#example');
    // new DataTable('#example');
    const tableElement = $("#RestarauntList");

    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable("#RestarauntList")) {
      // Destroy the existing DataTable
      tableElement.DataTable().destroy();
    }
    setTimeout(() => {
      $("#RestarauntList").DataTable({
        pagingType: "full_numbers",
        pageLength: 5,
        processing: true,
        lengthMenu: [5, 10, 25],
        dom: "Blfrtip",
        buttons: [
          {
            extend: "print",
            text: " Print",
            className: "table-button button btn btn-primary mb-2",
          },
          {
            extend: "excel",
            text: " Excel",
            className: "table-button button btn btn-success mb-2",
          },
        ],
        columnDefs: [
          {
            orderable: false,
            targets: -1, // This targets the last column
          },
        ],
      } as any);
      const searchInput = $("#RestarauntList_filter input");
      // searchInput.wrap('<div class="search-wrapper"></div>');
      searchInput.after(
        '<span class="searchTableIcon"><i class="fa fa-search"></i></span>'
      ); // Use 'as any' to avoid type checking for this specific object
    }, 1);
  }
  openModel(val: any) {
    const navigationExtras: NavigationExtras = {
      // state: {
      //   userData: val
      // },
      queryParams: {
        key: val, // Add your query parameters here
      },
    };
    this.router.navigate(["/superadmin/addrestarunt"], navigationExtras);
  }

  do__Delete() {
    let soption: SweetAlertOptions = {
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "error",
      showConfirmButton: true,
      showCancelButton: true,
    };

    swal.fire(soption).then((willDelete) => {
      if (willDelete.value) {
        swal.fire("Success");
      } else {
        swal.fire("Fail");
      }
    });
  }

  openLarge(content, val) {
    this.modalService.open(content, {
      size: "lg",
      backdrop: 'static',
      keyboard: true,
      scrollable:true,
      animation:true,
      centered:true,  
    });
    if (val == 0) {
      this.reset();
      document.getElementById("subscriptionName").focus();
    }
    this.ngAfterViewInit();
  }
  closeModal(): void {
    document.getElementById("closemodal").click();
    // if (this.modalRef) {
    //   this.modalRef.close();
    // }
  }
  copyURLToClipboard(url: string): void {
    if (!url && url.length == 0) return;

    const textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    this.toasterService.showToast("Copy to Clipboard...", TOAST_TYPE.Success);
  }
  reset() {
    this.HotelDetail = {
      companyId: "",
      companyName: "",
      address: "",
      email: "",
      contact: "",
      serialKey: "",
      companyCode: "",
      isActive: true,
      isArchived: false,
      logoPath: "",
      brandColor: "",
      status: 1,
      password: "",
      qrCode: "",
      userName: "",
      acQrCode: "",
      isAcTableAvailable: false,
      plan: 0,currencyId:'',currencyName:'',currencySymbol:''
    };
  }
  HotelDetail: HotelDetails = {
    companyId: "",
    companyName: "",
    address: "",
    email: "",
    contact: "",
    serialKey: "",
    companyCode: "",
    isActive: true,
    isArchived: false,
    logoPath: "",
    brandColor: "",
    status: 1,
    qrCode: "",
    userName: "",
    password: "",
    acQrCode: "",
    isAcTableAvailable: false,
    plan: 0,
    currencyId: "",
    currencyName: "",
    currencySymbol: "",
  };
  submit(form: NgForm) {
    if (this.isValObj()) {
      this.HotelDetail.plan = Number(this.HotelDetail.plan);
      this.loading = true;
      this.Api.addNewCompany(this.HotelDetail).subscribe(
        (data: any) => {
          this.loading = false;

          if (data.statusCode == 200) {
            this.toasterService.showToast(data.message, TOAST_TYPE.Success);
            this.getCompanyList();
            this.closeModal();
            this.eventmapper.AdminSearch.next(true);
          } else {
            this.toasterService.showToast(data.message, TOAST_TYPE.Error);
          }
        },
        (err) => {
          this.loading = false;
          console.log(err);
        }
      );
    }
  }
  CurrencyList:any=[]
  getCurrencyList() {
     
    this.Api.GetCurrency().subscribe(
      (res: any) => {
         
        if (res.statusCode == 200) {
          this.CurrencyList = res.data;
           
        } else {
          this.toasterService.showToast(res.message, TOAST_TYPE.Error);
        }
         
      },
      (err) => {
        console.log(err);
         
      }
    );
  }
  isValObj() {
    if (!this.HotelDetail.companyName.trim()) {
      document.getElementById("subscriptionName").focus();
      this.toasterService.showToast(
        "Please enter Subscription Name to proceed",
        TOAST_TYPE.Warning
      );
      return;
    } else if (
      !this.HotelDetail.contact ||
      this.HotelDetail.contact.length < 10
    ) {
      document.getElementById("contactno").focus();
      this.toasterService.showToast(
        "Please enter Contact Number to proceed",
        TOAST_TYPE.Warning
      );
      return;
    } else if (!this.HotelDetail.serialKey.trim()) {
      document.getElementById("SerialKKey").focus();
      this.toasterService.showToast(
        "Please enter Serial Key to proceed",
        TOAST_TYPE.Warning
      );
      return;
    } else if (!this.HotelDetail.email.trim()) {
      document.getElementById("userEmail").focus();
      this.toasterService.showToast(
        "Please enter  Email to proceed",
        TOAST_TYPE.Warning
      );
      return;
    } else if (!this.validator.validateEmail(this.HotelDetail.email)) {
      document.getElementById("userEmail").focus();
      this.toasterService.showToast(
        "Please enter valid Email to proceed",
        TOAST_TYPE.Warning
      );
      return;
    } else if (!this.HotelDetail.address.trim()) {
      document.getElementById("txtaddress").focus();
      this.toasterService.showToast(
        "Please enter valid Email to proceed",
        TOAST_TYPE.Warning
      );
      return;
    }
    return true;
  }
  loading: boolean = false;
  updateHotel(item: any, content) {
    this.HotelDetail = item;

    this.openLarge(content, 1);
  }
  showDetails(item, content) {
    this.getCredentcial(item.companyId);
    this.HotelDetail = item;
    this.openLarge(content, 1);
  }
  eventhandler(ev:any){
    console.log(ev)
    const obj=this.CurrencyList.find((data:any)=>data.currencyId==ev);
    this.HotelDetail.currencyId=obj.currencyId;
    this.HotelDetail.currencyName=obj.currencyName;
    this.HotelDetail.currencySymbol=obj.currencySymbol
  }
  UpdateApi() {
    if (this.isValObj()) {

      this.HotelDetail.status = this.HotelDetail.isActive
        ? SUBSCRIPTION_STATUS.ACTIVE
        : SUBSCRIPTION_STATUS.INACTIVE;
      this.loading = true;
      this.HotelDetail.plan = Number(this.HotelDetail.plan);
      this.Api.UpdateCompany(this.HotelDetail).subscribe(
        (data: any) => {
          this.loading = false;
          if (data.statusCode == 200) {
            this.closeModal();
            this.getCompanyList();
            this.toasterService.showToast(data.message, TOAST_TYPE.Success);
            this.eventmapper.AdminSearch.next(true);
          } else {
            this.toasterService.showToast(data.message, TOAST_TYPE.Error);
          }
        },
        (err) => {
          console.log(err);
          this.loading = false;
        }
      );
    }
  }
  updateStatus(item) {
    item.status = item.isActive
      ? SUBSCRIPTION_STATUS.ACTIVE
      : SUBSCRIPTION_STATUS.INACTIVE;
    this.Api.UpdateCompany(item).subscribe(
      (data: any) => {
        this.loading = false;
        if (data.statusCode == 200) {
          // this.toasterService.showToast(data.message, TOAST_TYPE.Success);
          this.getCompanyList();
          this.eventmapper.AdminSearch.next(true);
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
        console.log(err);
        this.loading = false;
      }
    );
  }
  validatedKey: boolean = false;
  verifyCompanyCode() {
    if (this.HotelDetail.companyId.length == 0) {
      this.Api.verifySerialKey(this.HotelDetail.serialKey).subscribe(
        (data: any) => {
          if (data.statusCode == 200) {
            this.validatedKey = true;
          } else {
            this.validatedKey = false;
          }
        },
        (err) => {
          this.validatedKey = false;
        }
      );
    }
  }
  LoadingText: boolean = false;
  userCreditial: any = {};
  getCredentcial(id) {
    this.LoadingText = true;
    this.Api.getCompanyCredencial(id).subscribe(
      (data: any) => {
        this.LoadingText = false;
        if (data.statusCode == 200) {
          this.userCreditial = data.data;
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getdateValidate(date:any){
    if(!date) return;
    const today=new Date();
    const olddate=new Date(date)
    if(olddate<today) return true
  }
}
