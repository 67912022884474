<div class="side-drawer-modal my-class">
  <app-sidebarload *ngIf="loading"></app-sidebarload>
  <div class="drawer-header modal-header">
    <div>
      <h5>Change Order</h5>
      <span>Drag and drop the below items as you require in order to reorder the list.</span>
    </div>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="closeModal(false)"
    ></button>
  </div>

  <div class="drawer-container">
    <div class="row">
      <div class="col-md-12">
        <div class="card-body">
          <!-- category.component.html -->
          <div
            *ngFor="let category of ProductList; let i = index"
            class="category"
          >
            <div class="category-header" (click)="toggleCategory(i)">
              <h2  class="active">
                {{ category.category?.categoryName
                   }}
                <i class="pe-7s-angle-up fa-lg ms-1" *ngIf="category?.expanded"></i>
                <i class="pe-7s-angle-down fa-lg ms-1" *ngIf="!category?.expanded"></i>
              </h2>
            </div>
            <div *ngIf="category.expanded" class="category-content">
              <div
                cdkDropList
                [cdkDropListData]="category.products"
                (cdkDropListDropped)="drop($event, i)"
                class="example-list"
              >
                <div
                  *ngFor="let product of category.products"
                  class="example-box"
                  cdkDrag
                >
                  <i class="pe-7s-menu me-2 fa-lg"> </i> {{ product.productName }}
                </div>
                <div   *ngIf="category.products.length==0" style="text-align: center;padding-top: 17px;">
                  No product found
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="drawer-footer">
    <button
      type="button"
      class="btn-shadow align-items-center btn btn-lg btn-danger"
      (click)="closeModal(false)"
    >
      <i class="fa fa-close"></i>&nbsp; Close</button
    >&nbsp;
    <button
      type="button"
      class="btn-shadow align-items-center btn btn-lg btn-primary me-2"
      (click)="do__Save(true)"
    >
      <i class="fa fa-floppy-o"></i>&nbsp; Save
    </button>
  </div>
</div>
<div class="overlayDrawer my-class"></div>
