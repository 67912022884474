<app-page-title
  [heading]="heading"
  [subheading]="subheading"
  [icon]="icon"
  [pageid]="pageid"
></app-page-title>

<div class="main-card mb-3 card p-2" [ngClass]="SkletonLoader?'d-none':''">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize fw-normal">
      Sliders Details
    </div>
  </div>
  <div class="table-responsive mt-3">
    <table
      class="align-middle text-truncate mb-0 table table-borderless table-hover table table-striped table-bordered"
      id="slider"
      style="width: 100%"
    >
      <thead>
        <tr>
          <th class="text-left">S.No</th>
          <th class="text-left">Slider Image</th>
          <th class="text-left" *ngIf="Plan !=0">Status</th>
          <th class="text-right">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of SliderList; index as i">
          <td class="text-left text-muted" style="width: 80px">
            {{ i + 1 }}
          </td>
          <td class="text-center">
            <img
              [src]="ApiUrl + item.dpPath"
              style="width: 100px"
              alt=""
              srcset=""
            />
          </td>
          <td class="text-left" *ngIf="Plan !=0">
            <label
              class="wraper"
              for="{{ item.sliderId }}"
              style="display: inline-block"
            >
              <div class="switch-wrap">
                <input
                  type="checkbox"
                  id="{{ item.sliderId }}" [(ngModel)]="item.isActive"
                  (change)="changeStatus(item)"
                />
                <div class="switch"></div>
              </div>
            </label>
            <span class="badge text-white d-none">{{
              item.isActive
                ? "Active" 
                : "InActive"
            }}</span>
          </td>
          <td class="text-center">
            <div role="group" class="btn-group-sm btn-group">
              <!-- <button
                class="btn-shadow btn btn-outline-dark"
                (click)="EditSlider(item)"
              >
                <i class="fa fa-edit"></i>
                Edit</button >&nbsp; -->
              <button
                class="btn-shadow btn btn-outline-danger"
                (click)="do__Delete(item)"
              >
                <i class="fa fa-trash"></i>
                Delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="main-card mb-3 card p-2" [ngClass]="!SkletonLoader?'d-none':''">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize fw-normal">
      Sliders List
    </div>
  </div>
  <div class="table-responsive mt-3">
    <app-skletonloader [thead]="4" [tbody]="5"></app-skletonloader>
  </div>
</div>
