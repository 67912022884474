import { Component, HostListener, OnInit } from "@angular/core";
import { ThemeOptions } from "../../../theme-options";
import { select } from "@angular-redux/store";
import { Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { StorageutilityService } from "src/app/Services/storageutility.service";
import { StorageKey } from "src/environments/environment";
import { DataService } from "src/app/Services/data.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  public extraParameter: any;
  isAdmin:boolean=false;
  UserDetails:any={};
  Plan:any=0;
  constructor(
    public globals: ThemeOptions,
    private activatedRoute: ActivatedRoute,private Storage:StorageutilityService,private Data:DataService
  ) {

    this.UserDetails= this.Storage.getValue(StorageKey.UserDetails,true);
    if(this.UserDetails){
      this.isAdmin= this.UserDetails.isSuperAdmin
    }
    this.Plan= localStorage.getItem('PLAN');
    if(this.Plan==null){
      this.Data.getPlan();
    }
  }

  @select("config") public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;
  activeId = "dashboardsMenu";

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    this.extraParameter =
      this.activatedRoute.snapshot.firstChild.data.extraParameter;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }
  }
}
