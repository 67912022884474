<div class="search-wrapper" [class.active]="isActive" *ngIf="isAdmin">
  <div class="input-holder">
    <input type="text" placeholder="Type to search" class="search-input" #search (input)="filterData($event)" [(ngModel)]="filterText">
    <button class="search-icon" (click)="openSearch()"><span></span></button>
  </div>
  <div class="search-results bg-white" *ngIf="filterText">
    <table class="table mb-0 table-hover">
      <tbody>
        <tr *ngFor="let item of FilterSubscription" (click)="openModal(item, showmodal)" style="cursor: pointer;">
          <td>{{item.companyName}}</td>
          <!-- <td class="text-end">
            <i class="fa fa-eye cursor-pointer" ></i>
          </td> -->
        </tr>
        <tr class="text-center fw-bold" *ngIf="FilterSubscription.length == 0">
          No Data Found
        </tr>
      </tbody>
    </table>
  </div>
  <button class="btn-close" (click)="closeSearch()"></button>
</div>
<div class="search-wrapper" [class.active]="isActive" *ngIf="!isAdmin">
  <div class="input-holder"> 
    <button   (click)="openQR(showQR)" class="btn "> <i class="fa fa-qrcode fa-2x"></i></button>
  </div>
   
 
</div>


<ng-template #showmodal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Subscription Detail</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="d('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="main-card card">
      <div class="card-body">
        <form>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">Subscription Name</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">
                :&nbsp; {{ HotelDetail.companyName }}
              </p>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">Subscription Code</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">
                :&nbsp;<button
                  class="btn btn-primary"
                  (click)="copyURLToClipboard(HotelDetail.companyCode)"
                  style="font-size: 10px"
                >
                  {{ HotelDetail.companyCode }}
                  <i class="pe-7s-copy-file ms-2 fa-lg"></i>
                </button>
              </p>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">User Name</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">:&nbsp;
                <button *ngIf="LoadingText"
                class="btn btn-primary"
               >
                Loading...
                <i class="pe-7s-copy-file ms-2 fa-lg"></i>
              </button>
                <button *ngIf="!LoadingText"
                class="btn btn-primary"
                (click)="copyURLToClipboard(userCreditial.userName)"
                style="font-size: 12px"
              >
                 {{userCreditial.userName}}
                <i class="pe-7s-copy-file ms-2 fa-lg"></i>
              </button>
            </p>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">Password</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">:&nbsp;
                <button *ngIf="LoadingText"
                class="btn btn-primary"
               >
                Loading...
                <i class="pe-7s-copy-file ms-2 fa-lg"></i>
              </button>
                <button *ngIf="!LoadingText"
                class="btn btn-primary"
                (click)="copyURLToClipboard(userCreditial.password)"
                style="font-size: 12px"
              > {{userCreditial.password}}
                <!-- {{ HotelDetail.companyCode }} -->
                <i class="pe-7s-copy-file ms-2 fa-lg"></i>
              </button>
            </p>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">Serial Key</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">
                :&nbsp;
                <span class="" 
                  >{{ HotelDetail.serialKey }}
                  <!-- <i class="pe-7s-copy-file ms-2 fa-lg"></i > (click)="copyURLToClipboard(HotelDetail.serialKey)"-->
                </span>
              </p>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">Contact No</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">
                :&nbsp; {{ HotelDetail.contact }}
              </p>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">E-mail</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">
                :&nbsp; {{ HotelDetail.email }}
              </p>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">Status</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">
                :&nbsp;
                <span
                class="badge rounded-pill text-white {{
                  HotelDetail.status == 1
                    ? 'bg-primary'
                    : HotelDetail.status == 3
                    ? 'bg-warning'
                    : HotelDetail.status == 0
                    ? 'bg-info'
                    : 'bg-danger'
                }}"
              >
                {{
                  HotelDetail.status == 1
                    ? "Active"
                    : HotelDetail.status == 3
                    ? "Expired"
                    : HotelDetail.status == 0
                    ? "Pending"
                    : "Inactive"
                }}
              </span>
              
              </p>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label">Plan</label>
            <div class="col-sm-9">
              <p class="form-control-plaintext">
                :&nbsp;
                <span
                class="badge rounded-pill text-white bg-primary">
                {{
                  HotelDetail.plan == 0 ? "Basic" : (HotelDetail.plan == 1 ? "Advanced" : "Elite")
                     
                }}
              </span>
              
              </p>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">
      <i class="fa fa-close"></i>&nbsp; Close
    </button>
  </div>
</ng-template>
<ng-template #showQR let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Scan QR</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="d('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="main-card card">
      <div class="card-body">
        <form>
          <div class="row mb-2"> 
            <div class="col-md-6 d-flex align-items-center justify-content-center mt-3" *ngIf="HotelDetails.qrCode">
              <div class="card qr p-3 mb-3" > 
                  <h5 class="fw-bolder text-center">QR Code</h5>
                  <div id="contentToPrint" class="m-3">
                      <img src="{{Imagepath+HotelDetails.qrCode}}" alt="" srcset="" class="w-100" >
                  </div> 
                  <div class="text-center">
                      <button (click)="downloadQRimage(HotelDetails.qrCode,0)" class="btn-shadow d-inline-flex align-items-center btn btn-primary me-3"><i class="fa fa-download fa-lg me-2"></i> Download</button>
                      <!-- <button type="button" (click)="printQr(0)" class="btn-shadow d-inline-flex align-items-center btn btn-primary me-3"> <i class="fa fa-print fa-lg me-2"></i> Print</button>   -->
                      <button class="btn-shadow d-inline-flex align-items-center btn btn-success" (click)="Common.copyURLToClipboard(HotelDetails.qrURL)"><i class="fa fa-copy fa-lg me-2"></i>  Copy</button>
                  </div>
              </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-center mt-3" *ngIf="HotelDetails.acQrCode&&HotelDetails.isAcTableAvailable">
              <div class="card qr p-3 mb-3" > 
                  <h5 class="fw-bolder text-center">AC QR Code</h5>
                  <div id="ACQrPrint" class="m-3">
                      <img src="{{Imagepath+HotelDetails.acQrCode}}" alt="" srcset="" class="w-100" >
                  </div> 
                  <div class="text-center">
                      <button (click)="downloadQRimage(HotelDetails.acQrCode,1)"class="btn-shadow d-inline-flex align-items-center btn btn-primary me-3"><i class="fa fa-download fa-lg me-2"></i> Download</button>
                      <!-- <button type="button" (click)="printQr(1)" class="btn-shadow d-inline-flex align-items-center btn btn-primary me-3"> <i class="fa fa-print fa-lg me-2"></i> Print</button>   -->
                      <button class="btn-shadow d-inline-flex align-items-center btn btn-success" (click)="Common.copyURLToClipboard(HotelDetails.acQrURL)"><i class="fa fa-copy fa-lg me-2"></i>  Copy</button>
                  </div>
              </div>
          </div>
          </div>
          
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">
      <i class="fa fa-close"></i>&nbsp; Close
    </button>
  </div>
</ng-template>
