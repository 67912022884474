import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToasterService, TOAST_TYPE } from 'src/app/Services/toaster.service';
import { ValidateService } from 'src/app/Services/validate.service';

@Component({
  selector: 'app-addrestarunt',
  templateUrl: './addrestarunt.component.html',
  styleUrls: ['./addrestarunt.component.sass']
})
export class AddrestaruntComponent implements OnInit {
  heading = "Add Restaraunt";
  subheading = "Add a note about the hotel here for internal reference.";
  icon = "pe-7s-graph text-success";
  @ViewChild("bname") private bname: ElementRef;
  @ViewChild("cpname") private cpname: ElementRef;
  @ViewChild("contactno") private contactno: ElementRef;
  @ViewChild("email") private email: ElementRef;
  @ViewChild("psw") private psw: ElementRef;
  constructor(
    private validator: ValidateService,private route:ActivatedRoute,
    private toasterService: ToasterService
  ) {
    this.route.queryParams.subscribe(queryParams => {
      // console.log(queryParams); // Access the query parameter 'key'
    });
  }
  resObj: any = {
    businessName: "",
    contactPersonName: "",
    contactNo: "",
    email: "",
    serialKey: "",
    subscribtion: "",
    active: false,
    tenentCode: "",
    password: "",
  };
  ngOnInit(): void {}
  ngAfterViewInit() {
    this.validator.focusElement(this.bname);
  }
  generatePassword(length: number) {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    this.resObj.password = password;
  }
  submit() {
    if (this.validator.validateString(this.resObj.businessName)) {
      this.validator.focusElement(this.bname);
      this.toasterService.showToast(
        "Please enter Business Name to proceed",
        TOAST_TYPE.Warning
      );
      return;
    } else if (this.validator.validateString(this.resObj.contactPersonName)) {
      this.validator.focusElement(this.cpname);
      this.toasterService.showToast(
        "Please enter Person Name to proceed",
        TOAST_TYPE.Warning
      );
      return;
    } else if (this.validator.validateString(this.resObj.contactNo)) {
      this.validator.focusElement(this.contactno);
      this.toasterService.showToast(
        "Please enter Contact Number to proceed",
        TOAST_TYPE.Warning
      );
      return;
    }  else if (this.validator.validateString(this.resObj.password)) {
      this.validator.focusElement(this.psw);
      this.toasterService.showToast(
        "Please enter Password to proceed",
        TOAST_TYPE.Warning
      );
      return;
    }
    if (!this.validator.validateString(this.resObj.email)) {
    
      if (!this.validator.validateEmail(this.resObj.email)) {
        this.validator.focusElement(this.email);
        this.toasterService.showToast(
          "Please enter valid Email to proceed",
          TOAST_TYPE.Warning
        );
        return;
      }
    }
  }
}
