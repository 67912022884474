import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import {
  Category,
  Product,
  SubCategory,
  TimeSlots,
} from "src/app/InterFace/category";
import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { DataService } from "src/app/Services/data.service";
import { EventdriverService } from "src/app/Services/eventdriver.service";
import { StorageutilityService } from "src/app/Services/storageutility.service";
import { ToasterService, TOAST_TYPE } from "src/app/Services/toaster.service";
import {
  RefreshEvent,
  StorageKey,
  environment,
} from "src/environments/environment";

@Component({
  selector: "app-addproduct",
  templateUrl: "./addproduct.component.html",
  styleUrls: ["./checkbox.css"],
})
export class AddproductComponent implements OnInit {
  ImagePath: string = environment.ImagePath;
  ProductItem: Product;
  CategoryList: Category[] = [];
  TimeSlotsList: TimeSlots;
  loading: boolean = false;
  private modalRef: NgbModalRef | undefined;
  @ViewChild("showmodal") content!: ElementRef;
  @ViewChild("catefocus") catefocus: ElementRef;
  CategoryDetails: Category = {
    categoryId: "",
    categoryName: "",
    companyId: "",
    description: "",
    dpPath: "",
    isActive: true,
    isArchived: false,
    displayOrder: 0,
    isSystemRecord: false,
  };
  // @Input() products={
  //   ProductId: 0,
  //   Description: "",
  //   ProductName: "",
  //   IsActive: false,
  // };
  @ViewChild("txtProductName") private txtProductName: ElementRef;
  @ViewChild("txtProductRate") private txtProductRate: ElementRef;
  @ViewChild("txtACProductRate") private txtACProductRate: ElementRef;
  @ViewChild("DisplayInput") private DisplayInput: ElementRef;
  @ViewChild("cat") private cat: ElementRef;
  Plan: any = 0;
  isAc: any = false;
  constructor(
    private eventDriver: EventdriverService,
    private Data: DataService,
    private toasterService: ToasterService,
    public common: CommonService,
    private Api: ApiService,
    private modalService: NgbModal,
    private Storage: StorageutilityService
  ) {
    this.Plan = localStorage.getItem("PLAN");
    this.isAc =localStorage.getItem("ACORNON");
 
    this.Data.getPlan();
    this.toggleIsAc();

    // this.getSubcategoryList();

    this.ProductItem = this.Data.ProductModalData;
    if (this.ProductItem == undefined) {
      this.reset();
    } else {
      this.getsubcatebyid(this.ProductItem.categoryId);
    }
 
  }
  disableAc: boolean = false;
  toggleIsAc() {
    if (this.isAc == "true") {
      this.disableAc = false;
    } else {
      this.disableAc = true;
    }
  }

  ngOnInit(): void {
    this.ListCategories();
    this.ListSlots();
  }
  ngAfterViewInit() {}
  reset() {
    this.ProductItem = {
      categoryId: "",
      categoryName: "",
      companyId: "",
      description: "",
      endTime: "",
      isActive: true,
      isArchived: false,
      productId: "",
      productName: "",
      rewardPoint: 0,
      slotId: "",
      startTime: "",
      dpPath: "",
      acSaleRate: "0.00",
      saleRate: "0.00",
      displayOrder: this.Data.dpOrder,
      isVeg: false,
      subcategoryId: "",
      subcategoryName: "",
    };
    setTimeout(() => {
      this.txtProductName.nativeElement.focus();
    }, 100);
  }
  validateDisplayOrder(event) {
    if (this.ProductItem.productId.length !== 0) {
      return;
    }
    var displayList = this.Data.ProductList.find(
      (data: any) => data.displayOrder == this.ProductItem.displayOrder
    );
    if (displayList) {
      this.toasterService.showToast(
        `Display Order Already Exits In ${displayList.productName}`,
        TOAST_TYPE.Warning
      );
      this.ProductItem.displayOrder = 0;
      this.DisplayInput.nativeElement.focus();
    }
  }
  closeModal(val) {
    this.eventDriver.triggerEvent({
      IsClosed: val,
      Modal: RefreshEvent.Product,
    });
    this.reset();
    if (val) this.txtProductName.nativeElement.focus();
  }
  changeAmount(event, val: string) {
    if (val === "AC") {
      this.ProductItem.acSaleRate = parseFloat(event.target.value).toFixed(2);
    } else {
      this.ProductItem.saleRate = parseFloat(event.target.value).toFixed(2);
    }
  }
  getCategoryName() {
    const category = this.CategoryList.find(
      (cat) => cat.categoryName === this.CategoryDetails.categoryName
    );
    this.ProductItem.categoryId = category ? category.categoryId : "";
  }
  do__Save(val: boolean) {
    if (this.isValidObj()) {
      
      const subcategory = this.subcategoryList.find((data) => {
        return data.subcategoryId == this.ProductItem.subcategoryId;
      });
  
      if (subcategory) {
        this.ProductItem.subcategoryName = subcategory.subcategoryName;
      } else {
        console.error(
          "Subcategory not found for ID:",
          this.ProductItem.subcategoryId
        );
      }
      this.loading = true;
      this.ProductItem.acSaleRate = Number(this.ProductItem.acSaleRate);
      this.ProductItem.saleRate = Number(this.ProductItem.saleRate);
      this.ProductItem.companyId = this.Api.getCompanyId();
      this.Api.addProduct(this.ProductItem).subscribe(
        (data: any) => {
          this.loading = false;
          this.eventDriver.updated.next(RefreshEvent.Product);

          if (data.statusCode == 200) {
            this.toasterService.showToast(data.message, TOAST_TYPE.Success);
            this.closeModal(val);
          } else {
            this.toasterService.showToast(data.message, TOAST_TYPE.Error);
          }
        },
        (err) => {
          console.log(err);
          this.loading = false;
        }
      );
    }
  }
  subcategoryList: SubCategory[];
  getSubcategoryList() {
    this.Api.getSubCategory().subscribe({
      next: (data: any) => { 
        this.subcategoryList = [];
        if (data.statusCode == 200) {
          this.subcategoryList = data.data;
          // if(this.subcategoryList.length !==0){
          // this.ProductItem.subcategoryId=this.subcategoryList[0]?.subcategoryId;
          // }
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
  getsubcatebyid(id) {
     
    this.subcategoryList = [];
    this.Api.getSubCategoryById(id).subscribe({
      next: (data: any) => {
        this.subcategoryList = [];
        if (data.statusCode == 200) {
          this.subcategoryList = data.data;
          // if (this.subcategoryList.length == 0) {
          //   this.ProductItem.subcategoryId =
          //     this.subcategoryList[0]?.subcategoryId;
          // }
        }
      },
      error: (err) => console.log(err),
    });
  }
  isValidObj() {
    
    if (
      this.ProductItem.categoryId == null ||
      this.ProductItem.categoryId.length == 0
    ) {
      document.getElementById("cat").click();

      this.toasterService.showToast(
        "Please Select Category name to proceed",
        TOAST_TYPE.Warning
      );
      return false;
    }
    if (
      (this.Plan != 0 && this.ProductItem.subcategoryId == null)  
    ) {
      document.getElementById("subcat").click();
      this.toasterService.showToast(
        "Please Select SubCategory  to proceed",
        TOAST_TYPE.Warning
      );
      return false;
    }
    if (
      this.ProductItem.slotId == null ||
      this.ProductItem.slotId.length == 0
    ) {
      this.toasterService.showToast(
        "Please Select Slot to proceed",
        TOAST_TYPE.Warning
      );
      return false;
    }
    if (this.ProductItem.productName.length == 0) {
      this.txtProductName.nativeElement.focus();
      this.toasterService.showToast(
        "Please enter Product name to proceed",
        TOAST_TYPE.Warning
      );

      return false;
    }
    if (
      this.ProductItem.saleRate == "0.00" ||
      this.ProductItem.saleRate.length == 0
    ) {
      this.txtProductRate.nativeElement.focus();
      this.toasterService.showToast(
        "Please enter Product Amount to proceed",
        TOAST_TYPE.Warning
      );
      return false;
    }
    if (
      (this.isAc=='true' && this.ProductItem.acSaleRate == "0.00") ||
      this.ProductItem.acSaleRate.length == 0
    ) {
      this.txtACProductRate.nativeElement.focus();
      this.toasterService.showToast(
        "Please enter AC Amount to proceed",
        TOAST_TYPE.Warning
      );
      return false;
    }
    // if (this.ProductItem.displayOrder == 0) {
    //   this.DisplayInput.nativeElement.focus();
    //   this.toasterService.showToast(
    //     "Please enter Display Order to proceed",
    //     TOAST_TYPE.Warning
    //   );
    //   return false;
    // }

    return true;
  }

  openUploader() {
    document.getElementById("fileCDP").click();
  }

  UploadImage(event) {
    const formData = new FormData();
    const files = event.target.files[0];
    formData.append("files", files); // Append file with key 'files'
    this.loading = true;
    this.Api.imageUpload(formData).subscribe(
      (data: any) => {
        this.loading = false; 
        if (data.isSucceeded) {
          this.ProductItem.dpPath = data.errorNote;
        } else {
          this.toasterService.showToast(data.errorNote, 3);
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }
  updateProduct() {
    if (this.isValidObj) {
 

      // this.ProductItem.subcategoryName=this.subcategoryList.find((data:any)=>data.subcategoryId==this.ProductItem.subcategoryId).subcategoryName;
      this.loading = true;
      this.ProductItem.acSaleRate = Number(this.ProductItem.acSaleRate);
      this.ProductItem.saleRate = Number(this.ProductItem.saleRate);
      this.Api.updateProduct(this.ProductItem).subscribe(
        (data: any) => {
          this.loading = false;
          this.eventDriver.updated.next(RefreshEvent.Product); 
          if (data.statusCode == 200) {
            this.toasterService.showToast(data.message, TOAST_TYPE.Success);
            this.closeModal(true);
            this.Data.ProductModalData = undefined;
          } else {
            this.toasterService.showToast(data.message, TOAST_TYPE.Error);
          }
        },
        (err) => {
          this.loading = false;
          console.log(err);
        }
      );
    }
  }
  ListCategories() {
    this.Api.getCategory().subscribe(
      (data: any) => {
        if (data.statusCode == 200) {
          this.CategoryList = data.data;
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  ListSlots() {
    this.Api.getTimeSlots().subscribe(
      (data: any) => {
        if (data.statusCode == 200) {
          this.TimeSlotsList = data.data;
        } else {
          this.toasterService.showToast(data.message, 3);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  openLarge(content) {
    this.modalService.open(content, {
      size: "lg",
    });

    document.getElementById("subscriptionName").focus();
  }
  closePopupModal(): void {
    this.ListCategories();
    document.getElementById("closeModal").click();
    // this.modalRef.close();
    setTimeout(() => {
      this.getCategoryName();
    }, 1000);
    setTimeout(() => {
      this.CategoryDetails = {
        categoryId: "",
        categoryName: "",
        companyId: "",
        description: "",
        dpPath: "",
        isActive: true,
        isArchived: false,
        displayOrder: 0,
        isSystemRecord: false,
      };
    }, 1500);
  }
  CategoryInsert() {
    if (this.CategoryDetails.categoryName.length == 0) {
      document.getElementById("subscriptionName").focus();
      this.toasterService.showToast(
        "Please enter category name to proceed",
        TOAST_TYPE.Warning
      );
      return false;
    }

    this.loading = true;
    this.CategoryDetails.companyId = this.Api.getCompanyId();
    this.Api.AddCategory(this.CategoryDetails).subscribe(
      (data: any) => {
        this.loading = false;
        if (data.statusCode == 200) {
          this.toasterService.showToast(data.message, TOAST_TYPE.Success);
          this.closePopupModal();
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }
}
