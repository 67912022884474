import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebarload',
  templateUrl: './sidebarload.component.html',
  styleUrls: ['./loader.css']
})
export class SidebarloadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
