import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidateService {

  constructor() { }
  
 public validateString(val: any) {
 
    if (val != undefined && val != null) {
      return val.length == 0;
    } else {
      return true;
    }
  }

  focusElement(val:any){

    val.nativeElement.focus();
  }
  validateEmail(email: string): boolean {
    // Regular expression for email validation
    const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}
}
