<app-page-title
  [heading]="heading"
  [subheading]="subheading"
  [icon]="icon"
  [pageid]="pageid"
  [bulkbtn]="true"
></app-page-title>

<div class="main-card mb-3 card p-2" [ngClass]="SkletonLoader ? 'd-none' : ''">
  <div class="card-header ps-0 pe-0 " >
    <div class="card-header-title font-size-lg text-capitalize fw-normal d-flex justify-content-between ">
      Category Details
      <button (click)="openReOrderModal()" class="btn btn-primary ">Reorder</button>
    </div>
    
  </div>
  <div
    class="table-responsive mt-3"
    
  >
    <table
      class="align-middle text-truncate mb-0 table table-borderless table-hover table table-striped table-bordered"
      id="category"
      style="width: 100%"
    >
      <thead>
        <tr>
          <th class="text-left" style="width: 80px !important">S.No</th>
          <th class="text-left">Category Name</th>
          <!-- <th class="text-left">Display Order</th> -->
          <th class="text-left" *ngIf="Plan != 0">Status</th>
          <th class="text-right">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of CategoryList; index as i"  >
          <td class="text-left text-muted" style="width: 80px" scope="row">
            {{ i + 1 }}
          </td>
          <td class="text-left">{{ item.categoryName }}</td>
          <!-- <td class="text-left">{{ item.displayOrder }}</td> -->
          <td class="text-left" *ngIf="Plan != 0">
            <label
              class="wraper"
              for="{{ item.categoryId }}"
              style="display: inline-block"
            >
              <div class="switch-wrap">
                <input
                  type="checkbox"
                  id="{{ item.categoryId }}"
                  [(ngModel)]="item.isActive"
                  (change)="changeStatus(item)"
                />
                <div class="switch"></div>
              </div>
            </label>
            <label class="" style="color: transparent">{{
              item.isActive ? "Active" : "InActive"
            }}</label>
          </td>
          <td class="text-right">
            <div role="group" class="btn-group-sm btn-group">
              <button
                class="btn-shadow btn btn-outline-dark"
                (click)="EditCategory(item)"
              >
                <i class="fa fa-edit"></i>
                Edit</button
              >&nbsp;
              <button
                class="btn-shadow btn btn-outline-danger"
                (click)="do__Delete(item)"
                *ngIf="!item.isSystemRecord"
              >
                <i class="fa fa-trash"></i>
                Delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <app-category-reorder *ngIf="openReorderModal" (newItemEvent)="closeModal($event)"></app-category-reorder>
  </div>

  <!-- <div class="d-block p-4 text-center card-footer">
          <button class="btn-pill btn-shadow btn-wide fsize-1 btn btn-dark btn-lg">
            <span class="me-2 opacity-7">
                <i class="fa fa-cog fa-spin"></i>
            </span>
            <span class="me-1">View Complete Report</span>
          </button>
        </div> -->
</div>
<div class="main-card mb-3 card p-2" [ngClass]="!SkletonLoader ? 'd-none' : ''">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize fw-normal">
      Category List
    </div>
  </div>
  <div class="table-responsive mt-3">
    <app-skletonloader [thead]="4" [tbody]="5"></app-skletonloader>
  </div>
</div>
