import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Slider } from "src/app/InterFace/category";
import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { DataService } from "src/app/Services/data.service";
import { EventdriverService } from "src/app/Services/eventdriver.service";
import { ToasterService, TOAST_TYPE } from "src/app/Services/toaster.service";
import { RefreshEvent, environment } from "src/environments/environment";

@Component({
  selector: "app-addslider",
  templateUrl: "./addslider.component.html",
  styleUrls: ["./addslider.component.sass"],
})
export class AddsliderComponent implements OnInit {
  @Input() hideDiv: string = "";
  loading:boolean=false;
  SliderItem: Slider;
Apiurl:string=environment.ImagePath;
  constructor(
    private eventDriver: EventdriverService,
    private toasterService: ToasterService,
    private Data: DataService,
    private Api: ApiService,
    private common: CommonService
  ) {
    this.SliderItem = this.Data.SliderModalData;
    if (this.SliderItem == undefined) {
      this.reset();
    }
  }

  ngOnInit(): void {}
  reset() {
    this.SliderItem = {
      companyId: "",
      dpPath: "",
      isActive: true,
      isArchived: false,
      sliderId: "",
    };
     
  }
  closeModal(val) {
    this.eventDriver.triggerEvent({
      IsClosed: val,
      Modal: RefreshEvent.Slider,
    });
    this.reset();
    
  }

  do__Save(val) {
    if (this.isValidObj()) {
      this.loading=true;
      this.SliderItem.companyId=this.Api.getCompanyId();
      this.Api.addSlider(this.SliderItem).subscribe(
        (data: any) => {
          this.loading=false;
          this.eventDriver.updated.next(RefreshEvent.Slider); 
          if (data.statusCode==200) {
            this.toasterService.showToast(data.message, TOAST_TYPE.Success);
            this.closeModal(val);
          } else {
            this.toasterService.showToast(data.message, TOAST_TYPE.Error);
          }
        },
        (err) => {
          console.log(err);
          this.loading=false;
        }
      );
    }
  }

  isValidObj() {
    if (this.SliderItem.dpPath.length == 0) {
      this.openUploader();
      this.toasterService.showToast(
        "Please Upload Slider Image to Procees.",
        TOAST_TYPE.Warning
      );
      return false;
    }
    return true;
  }

  openUploader() {
    document.getElementById("fileCDP").click();
  }
  UploadImage(event) {
    this.loading=true;
    var formdata = new FormData();
    var files = event.target.files[0];
    formdata.append('files', files);
 

    this.Api.imageUpload(formdata).subscribe(
      (data: any) => {
        this.loading=false; 
        if (data.isSucceeded) {
          this.SliderItem.dpPath = data.errorNote;
        } else {
          this.toasterService.showToast(data.errorNote, 3);
        }
      },
      (err) => {
        this.loading=false;
        console.log(err);
      }
    );
  }
  updateSlider() {
    if (this.isValidObj) {
      this.Api.updateSlider(this.SliderItem).subscribe(
        (data: any) => {
           
           
          if (data.statusCode==200) {
            this.toasterService.showToast(data.message, TOAST_TYPE.Success);
            this.closeModal(true);
            this.Data.CategoryModalData = undefined;
          } else {
            this.toasterService.showToast(data.message, TOAST_TYPE.Error);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
}
