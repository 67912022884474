import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { TOAST_TYPE, ToasterService } from 'src/app/Services/toaster.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.sass']
})
export class ClientsComponent implements OnInit {
  SkletonLoader:boolean=true;
  RestaruntList:any=[];
  constructor(private Api:ApiService,private toasterService:ToasterService) { }

  ngOnInit(): void {
    this.getCompanyList()
  }
  getCompanyList() {
    this.SkletonLoader = true;
    this.Api.getAllClients().subscribe(
      (res: any) => {
        this.SkletonLoader = false;
        if (res.statusCode == 200) {
          this.RestaruntList = res.data;
          this.loadDataTable();
        } else {
          this.toasterService.showToast(res.message, TOAST_TYPE.Error);
        }
         
      },
      (err) => {
        console.log(err);
        this.SkletonLoader = false;
      }
    );
  }

  async loadDataTable() {
    // new DataTable('#example');
    // new DataTable('#example');
    const tableElement = $("#clientList");

    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable("#clientList")) {
      // Destroy the existing DataTable
      tableElement.DataTable().destroy();
    }
    setTimeout(() => {
      $("#clientList").DataTable({
        pagingType: "full_numbers",
        pageLength: 5,
        processing: true,
        lengthMenu: [5, 10, 25],
        dom: "Blfrtip",
        buttons: [
          {
            extend: "print",
            text: " Print",
            className: "table-button button btn btn-primary mb-2",
          },
          {
            extend: "excel",
            text: " Excel",
            className: "table-button button btn btn-success mb-2",
          },
        ],
        columnDefs: [
          {
            orderable: false,
            targets: -1, // This targets the last column
          },
        ],
      } as any);
      const searchInput = $("#clientList_filter input");
      // searchInput.wrap('<div class="search-wrapper"></div>');
      searchInput.after(
        '<span class="searchTableIcon"><i class="fa fa-search"></i></span>'
      ); // Use 'as any' to avoid type checking for this specific object
    }, 1);
  }
  copyURLToClipboard(url: string): void {
    if (!url && url.length == 0) return;

    const textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    this.toasterService.showToast("Copy to Clipboard...", TOAST_TYPE.Success);
  }
  openUrl(url) {
    window.open(url, '_blank',);
  }
}
