 export const environment = {
  production: false,
  AdminToken:'Basic /AdCb93KEn6/KQhCdHgIlxfqQBClgM3E2T8Zel+0CdGsfcWFWY/ZorKe5vJjMATW',
  ApiUrl:'https://dm.api.greenhoodtech.co.in/api',
  ImagePath:'https://dm.api.greenhoodtech.co.in/',
  WebAppUrl:'https://dm.app.greenhoodtech.co.in/',
  aes_salt_key: 'V2mtU8&8K',
};

export enum modalOpen{
  CategoryReorderModal="Category-Reorder-modal",
  PeoductReorderModal="Product-Reorder-modal",
  CategoryModal='category',
  SubCategoryModal='subcategory',
  SlotModal='slot',
  ProductModal='product',
  SliderModal='slider',
  Table='Table'
}
export enum RefreshEvent{
  Category='Category',
  CategoryReorder="Category-Reorder",
  ProductReorder="Category-Reorder",
  SubCategory='SubCategory',
  ProductSlot='Slot',
  Product='Product',
  Slider='Slider',
  Tables='Table'
}
export enum StorageKey{
  Token='#4)9(@3T',
  UserDetails='Admin_Details'
}
export enum CRYPTER_TYPE {
  ENCRYPT = 1,
  DECRYPT = 2
}
export enum SUBSCRIPTION_STATUS{
  PENDING=0,
  ACTIVE=1,
  INACTIVE=2,
  EXPERIRED=3
}
export enum DELETE_DESC{
  title='Are you sure, Do you Want Delete?',
  desc='Once deleted, you will not be able to recover this ',
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
