
<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon" [addbtn]="true" ></app-page-title>
<div class="main-card card p-2">
    <div class="container ">
        <div class="row "> 
            
            <div class="col-md-6">
                <div  > 
                    <div class="position-relative mb-3">
                        <label for="exampleEmail" class="form-label">Current Password<span
                                class="mandate">*</span></label>
                        <input name="text" autocomplete="off" [(ngModel)]="PasswordDetail.currentPassword" #txtcurrentpsw   placeholder="Enter Your Current Password" type="text"
                            class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div  > 
                    <div class="position-relative mb-3">
                        <label for="exampleEmail" class="form-label">New Password<span
                                class="mandate">*</span></label>
                        <input name="tel" autocomplete="off" [(ngModel)]="PasswordDetail.newPassword" #txtnewpsw  placeholder="Enter Your New Password" type="text"
                            class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div  > 
                    <div class="position-relative mb-3">
                        <label for="exampleEmail" class="form-label">Confirm Password<span
                                class="mandate">*</span></label>
                        <input name="tel"   autocomplete="off" [(ngModel)]="PasswordDetail.confirmPassword" #txtconfirmpsw placeholder="Enter Confirm Password" type="text"
                            class="form-control">
                    </div>
                </div>
            </div>
             
            <div class="col-md-12 text-end">
                <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-danger" (click)="reset()"><i class="fa fa-close  me-2"></i>  Reset</button>
                <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success ms-1" (click)="do__Save()" ><i class="fa fa-floppy-o  me-2"></i>  Save</button>
            </div>

             
        </div>
    </div>
</div>
 