import { Component, OnInit } from '@angular/core';
import { Category, Tables } from 'src/app/InterFace/category';
import { ApiService } from 'src/app/Services/api.service';
import { CommonService } from 'src/app/Services/common.service';
import { DataService } from 'src/app/Services/data.service';
import { EventdriverService } from 'src/app/Services/eventdriver.service';
import { TOAST_TYPE, ToasterService } from 'src/app/Services/toaster.service';
import { DELETE_DESC, modalOpen, RefreshEvent } from 'src/environments/environment';
import swal ,{ SweetAlertOptions } from 'sweetalert2'; 
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css'; // Import the default DataTables CSS

@Component({
  selector: 'app-managetables',
  templateUrl: './managetables.component.html',
  styleUrls: ['./managetables.component.sass']
})
export class ManagetablesComponent implements OnInit {
  heading = "Manage Tables";
  subheading = "Manage the Tables you would like to organize.";
  icon = "pe-7s-note2 icon-gradient bg-happy-itmeo";
  pageid = modalOpen.Table;
  TablesList: Tables[]=[];
  SkletonLoader:boolean=true;
   
  constructor(
    private eventDriver: EventdriverService,
    private toasterService: ToasterService,
    private Api: ApiService,
    private common: CommonService,
    private data: DataService
  ) {
    this.eventDriver.refreshevent().subscribe((data) => {
      if (data == RefreshEvent.Tables) {
        this.ListTables();
      }
    });
  }

  ngOnInit(): void {
    this.ListTables();
  }

  openModel() {
    this.eventDriver.triggerEvent({
      IsClosed: false,
      Modal: RefreshEvent.Tables,
    });
  }

  do__Delete(id) {
    let soption: SweetAlertOptions = {
      title: `${DELETE_DESC.title}`,
      text: `${DELETE_DESC.desc} ${id.tableCaption} !`,
      icon: "error",
      showConfirmButton: true,
      showCancelButton: true,
    };

    swal.fire(soption).then((willDelete) => {
      if (willDelete.value) {
        this.Deletetables(id.tableId);
      } else {
        // swal.fire("Fail");
      }

       
    });
  }
  async loadDataTable() {
    // new DataTable('#example');
    const tableElement = $("#Tables");

    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable("#Tables")) {
      // Destroy the existing DataTable
      tableElement.DataTable().destroy();
    }
  
    setTimeout(() => {
      $("#Tables").DataTable({
        pagingType: "full_numbers",
        pageLength: 5,
        processing: true,
        lengthMenu: [5, 10, 25],
        dom: "Blfrtip",
        buttons: [
          {
            extend: "print",
            text: " Print",
            className: "table-button button btn btn-primary mb-2",
          },
          {
            extend: "excel",
            text: " Excel",
            className: "table-button button btn btn-success mb-2",
          },
        ],
        columnDefs: [
          { 
            orderable: false, 
            targets: -1 // This targets the last column
          }
        ]
      } as any); 
      setTimeout(() => {
        this.SkletonLoader=false;
        const searchInput = $('#Tables_filter input');
        // searchInput.wrap('<div class="search-wrapper"></div>');
        searchInput.after('<span class="searchTableIcon"><i class="fa fa-search"></i></span>');
      }, 10);
       // Use 'as any' to avoid type checking for this specific object
        

    }, 1);
  }
  
  ListTables() {
    this.SkletonLoader=true;
    this.Api.getTables().subscribe(
      (data: any) => {
        this.common.dismissloading();
         
        if (data.statusCode == 200) {
          this.TablesList = data.data;
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
         
        this.loadDataTable();
        
      
      },
      (err) => {
        console.log(err);
        this.common.dismissloading();
      }
    );
  }
  Edittables(item: any) {
    this.data.TableModalData = item;
    this.openModel();
  }
  changeStatus(item) {
    this.common.showloading();
    this.Api.updateTable(item).subscribe(
      (data: any) => {
        
        this.common.dismissloading();
         
        if (data.statusCode == 200) {
          this.ListTables();
          // this.toasterService.showToast(data.message, TOAST_TYPE.Success);
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
        this.common.dismissloading();
        console.log(err);
      }
    );
  }
  Deletetables(id: any) {
    this.common.showloading();
    this.Api.deleteTable(id).subscribe(
      (data: any) => {
       
        this.common.dismissloading();
        if (data.statusCode == 200) {
          this.toasterService.showToast(data.message, TOAST_TYPE.Warning);
          this.ListTables();
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
        this.common.dismissloading();
        console.log(err);
      }
    );
  }
}
