import { Injectable } from '@angular/core';
import { ToastrConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  toastConfig: any;

  
  constructor(private toastr: ToastrService) {
    this.toastConfig = {  
      closeButton : true,
     timeOut : 5000,
     easing : 'ease-in',
     progressBar : true, 
  }
}

  showToast(msg: string, type: TOAST_TYPE) {

    switch (type) {

      case TOAST_TYPE.Success:{
        this.toastr.success(msg, 'Success', this.toastConfig);
        break;
      }
      case TOAST_TYPE.Warning:{
        this.toastr.warning(msg, 'Warning', this.toastConfig);
        break;
      }
      case TOAST_TYPE.Error:{
        this.toastr.error(msg, 'Exception', this.toastConfig);
        break;
      }
    }

  }
}

export enum TOAST_TYPE {
  Success = 1,
  Warning = 2,
  Error = 3
}
