import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StorageKey, environment } from "src/environments/environment";
import { StorageutilityService } from "./storageutility.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  ApiUrl: string = environment.ApiUrl;
  AuthKey: string = "";

  headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
    },
  };

  constructor(
    private httpClient: HttpClient,
    private storage: StorageutilityService
  ) {}

  getKey() {
    return this.storage.getValue(StorageKey.Token, false);
  }
  getCompanyId() {
    var Id = this.storage.getValue(StorageKey.UserDetails, true);

    if (Id) {
      return Id.companyId;
    }
    return "";
  }
  //Category

  getCategory() {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.get(`${this.ApiUrl}/Category`, headers);
  }
  AddCategory(data: any) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.post(
      `${this.ApiUrl}/Category/AddNewCategoryDetail`,
      data,
      headers
    );
  }
  UpdateCategory(data: any) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.post(
      `${this.ApiUrl}/Category/UpdateCategoryDetail`,
      data,
      headers
    );
  }
  deleteCategory(id: any) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.delete(
      `${this.ApiUrl}/Category/DeleteCategoryDetail/${id}`,
      headers
    );
  }
  CategoryBulkUpload(file: any) {
    return this.httpClient.post(`${this.ApiUrl}/Category/BulkUpload`, file, {
      headers: {
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    });
  }
  categoryBulkReorder(obj:any){
    return this.httpClient.post(`${this.ApiUrl}/Category/BulkUpdateCategoryDetail`,obj,{
      headers: {
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    })
  }
  //SUBCategory

  getSubCategory() {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.get(`${this.ApiUrl}/Subcategory`, headers);
  }
  AddSubCategory(data: any) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.post(
      `${this.ApiUrl}/Subcategory/AddNewSubcategoryDetail`,
      data,
      headers
    );
  }
  UpdateSubCategory(data: any) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.post(
      `${this.ApiUrl}/Subcategory/UpdateSubcategoryDetail`,
      data,
      headers
    );
  }
  deleteSubCategory(id: any) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.delete(
      `${this.ApiUrl}/Subcategory/DeleteSubcategoryDetail/${id}`,
      headers
    );
  }
  getSubCategoryById(id:string){
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.get(
      `${this.ApiUrl}/Subcategory/LoadSubcategoriesForCategory/${id}`,
      headers
    );
  }
  // SubCategoryBulkUpload(file: any) {
  //   return this.httpClient.post(`${this.ApiUrl}/Category/BulkUpload`, file, {
  //     headers: {
  //       Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
  //     },
  //   });
  // }
  //Image Upload
  imageUpload(data) {
    return this.httpClient.post(
      this.ApiUrl + "/ImageUpload/imageupload",
      data,
      {
        headers: {
          Accept: "application/json",

          // 'Content-Type':'multipart/form-data',
          Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
        },
      }
    );
  }

  //Time Slots
  getTimeSlots() {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.get(`${this.ApiUrl}/Slot`, headers);
  }
  addTimeSlots(item) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.post(
      `${this.ApiUrl}/Slot/AddNewSlotDetail`,
      item,
      headers
    );
  }
  updateTimeSlots(item) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.post(
      `${this.ApiUrl}/Slot/UpdateSlotDetail`,
      item,
      headers
    );
  }
  deleteTimeSlots(id: string) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.delete(
      `${this.ApiUrl}/Slot/DeleteSlotDetail/${id}`,
      headers
    );
  }

  //Products
  getProducts() {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.get(`${this.ApiUrl}/Product`, headers);
  }
  addProduct(item: any) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.post(
      `${this.ApiUrl}/Product/AddNewProductDetail`,
      item,
      headers
    );
  }
  updateProduct(item: any) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.post(
      `${this.ApiUrl}/Product/UpdateProductDetail`,
      item,
      headers
    );
  }
  deleteProduct(id: any) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.delete(
      `${this.ApiUrl}/Product/DeleteProductDetail/${id}`,
      headers
    );
  }
  ProductBulkUpload(file: any) {
    return this.httpClient.post(`${this.ApiUrl}/Product/BulkUpload`, file, {
      headers: {
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    });
  }
  ProductBulkUploadReorder(obj:any){
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.post(`${this.ApiUrl}/Product/BulkUpdateProductDetail`,obj,headers)
  }
  GetCategoryProduct(){
    return this.httpClient.get(`${this.ApiUrl}/Category/GetCategorywithProduct`,{
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    })
  }
  //Sliders
  getSliders() {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.get(`${this.ApiUrl}/Slider`, headers);
  }
  addSlider(item: any) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.post(
      `${this.ApiUrl}/Slider/AddNewSliderDetail`,
      item,
      headers
    );
  }
  updateSlider(item: any) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.post(
      `${this.ApiUrl}/Slider/UpdateSliderDetail`,
      item,
      headers
    );
  }
  deleteSlider(id: any) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.delete(
      `${this.ApiUrl}/Slider/DeleteSliderDetail/${id}`,
      headers
    );
  }

  //User Login

  async Login(uname: string, psw: any, key: string) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: key,
      },
    };

    this.AuthKey = key;
    return this.httpClient.get(
      `${this.ApiUrl}/UserLogin/AdminLogin/${uname}/${psw}`,
      headers
    );
  }

  //Company

  getAllCompany() {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.get(`${this.ApiUrl}/Company`, headers);
  }
  getAllClients() {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: environment.AdminToken,
      },
    };
    return this.httpClient.get(`${this.ApiUrl}/Company`, headers);
  }
  addNewCompany(data: any) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.post(
      `${this.ApiUrl}/Company/AddNewCompanyDetail`,
      data,
      headers
    );
  }
  UpdateCompany(data: any) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.post(
      `${this.ApiUrl}/Company/UpdateCompanyDetail`,
      data,
      headers
    );
  }
  getCompanyFromId(id: string) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.get(
      `${this.ApiUrl}/Company/GetCompanyDetail/${id}`,
      headers
    );
  }
  deleteCompany(id) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.delete(
      `${this.ApiUrl}/Company/DeleteCompanyDetail/${id}`,
      headers
    );
  }
  verifySerialKey(key) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.post(
      `${this.ApiUrl}/License/CheckIfValidKey/${key}`,
      null,
      headers
    );
  }
  getCompanyCredencial(id) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.get(
      `${this.ApiUrl}/Company/GetCompanyCredentials/${id}`,
      headers
    );
  }

  getCompanyDetails(){
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.get(
      `${this.ApiUrl}/Company/GetCompanyByCompanyCode`,
      headers
    );
  }
  //Dashboard
  getDashboardForAdmin() {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.get(
      this.ApiUrl + "/Dashboard/GetAdminDashboard",
      headers
    );
  }
  getVendorDashboard() {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.get(
      `${this.ApiUrl}/Dashboard/GetVendorDashboard/${this.getCompanyId()}`,
      headers
    );
  }

  //Table
  getTables() {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.get(`${this.ApiUrl}/Table`, headers);
  }
  AddTables(data) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.post(
      `${this.ApiUrl}/Table/AddNewTableDetail`,
      data,
      headers
    );
  }
  updateTable(data) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.post(
      `${this.ApiUrl}/Table/UpdateTableDetail`,
      data,
      headers
    );
  }
  deleteTable(id) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.delete(
      `${this.ApiUrl}/Table/DeleteTableDetail/${id}`,
      headers
    );
  }
//CURRENCY
GetCurrency() {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
    },
  };
  return this.httpClient.get(`${this.ApiUrl}/Currency`, headers);
}
AddCurrency(data) {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
    },
  };
  return this.httpClient.post(
    `${this.ApiUrl}/Currency/AddNewCurrencyDetail`,
    data,
    headers
  );
}
UpdateCurrency(data) {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
    },
  };
  return this.httpClient.post(
    `${this.ApiUrl}/Currency/UpdateCurrencyDetail`,
    data,
    headers
  );
}
DeleteCurrency(id) {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
    },
  };
  return this.httpClient.delete(
    `${this.ApiUrl}/Currency/DeleteCurrencyDetail/${id}`,
    headers
  );
}
  //Change Password

  changePassword(uid, cpsw, newpsw) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
      },
    };
    return this.httpClient.get(
      `${this.ApiUrl}/UserLogin/ChangePassword/${uid}/${cpsw}/${newpsw}`,
      headers
    );
  }
  downloadQR(id:string,){
    const isAc = localStorage.getItem("ACORNON");
    const headers = new HttpHeaders().set('Authorization', `${ this.AuthKey ? this.AuthKey : this.getKey()}`);
    // const headers = {
    //   headers: {
    //     responseType: 'blob' ,
    //     accecpt:'/',
    //     Authorization: this.AuthKey ? this.AuthKey : this.getKey(),
    //   },
    // };
    return this.httpClient.get(`${this.ApiUrl}/Company/downloadqrimage/${id}/${Boolean(isAc)}`,{ headers, responseType: 'blob' })
  }
}
