<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{ icon }}"></i>
      </div>
      <div>
        {{ heading }}
        <div class="page-title-subheading">
          {{ subheading }}
        </div>
      </div>
    </div>
    <div class="page-title-actions" *ngIf="!addbtn">
      <!-- <button type="button" class="btn-shadow me-3 btn btn-dark">
        <fa-icon [icon]="faStar"></fa-icon>
      </button> -->
      <button
        *ngIf="bulkbtn"
        type="button"
        (click)="open(content)"
        class="btn-shadow d-inline-flex align-items-center btn btn-danger me-2"
      >
        <fa-icon [icon]="faexcel" class="me-2"></fa-icon>
        Bulk Upload
      </button>

      <button
        type="button"
        class="btn-shadow d-inline-flex align-items-center btn btn-primary"
        (click)="do__CreateNow(pageid)"
      >
        <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
        Add New
      </button>
    </div>
  </div>
</div>

<app-addeditcategory *ngIf="isCategoryOpen"></app-addeditcategory>

<app-addproduct *ngIf="openProductModal"></app-addproduct>

<app-addtimeslot *ngIf="openSlotModal"></app-addtimeslot>

<app-addslider *ngIf="openSliderModal"></app-addslider>
<app-addtables *ngIf="openTableModal"></app-addtables>
<app-addsubcategory *ngIf="OpenSubcatmodal"></app-addsubcategory>
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="pageid == 'product'">Product Bulk Upload</h4>
    <h4 class="modal-title" *ngIf="pageid !== 'product'">
      Category Bulk Upload
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="d('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="main-card card">
      <div class="card-body">
        <div class="row">
          <app-sidebarload *ngIf="loading"></app-sidebarload>
          <div class="col-md-6">
            <!-- <div class="card-header text-center">Upload Your Excel</div> -->
            <div class="upload-container">
              <div class="boxdrop">
                <div class="boxdrop-content" (click)="bulkupload()">
                  <input
                    type="file"
                    hidden
                    id="bulkupload"
                    accept=".xlsx, .xls"
                    (change)="getfile($event)"
                  />
                  <p class="text-decoration-underline">
                    Click to choose your Excel
                  </p>
                </div>
              </div>
            </div>
            <h6 class="mb-3" *ngIf="excelName.length != 0">
              Excel Name:&nbsp;{{ excelName }}
            </h6>
            <div class="text-center">
              <button
                class="btn btn-primary"
                (click)="UploadExcel()"
                *ngIf="selectedFile"
              >
                <i class="fa fa-upload fa-lg me-2"></i> Upload
              </button>
            </div>
          </div>
          <div class="col-md-6">
            <div class="sample-excel pt-3">
              <div class="sample-excel-content">
                <img
                  src="../../../../assets/images/excel.png"
                  alt="Sample Excel"
                  class="sample-excel-image"
                />
                <div class="mt-2">
                  <p *ngIf="productNames.length == 0">
                    <span class="fw-bolder">Note:</span> Click to download the
                    sample Excel file and open it to verify the format. Once you
                    have verified the sample Excel document, enter your data
                    into the appropriate fields. After entering all your data,
                    Upload your Excel document.
                  </p>
                  <p *ngIf="productNames.length !== 0" class="fw-bolder">
                    {{ productNames }}
                  </p>
                </div>
                <button
                  class="btn btn-secondary mt-2"
                  (click)="downloadExcel()"
                  *ngIf="productNames.length == 0"
                >
                  <i class="fa fa-download fa-lg download-icon me-2"></i>
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-secondary" (click)="c('Close click')">
      Close
    </button> -->
    <button
      type="button"
      class="btn-shadow align-items-center btn btn-lg btn-danger"
      (click)="c('Close click')"
    >
      <i class="fa fa-close"></i>&nbsp; Close
    </button>
    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
  </div>
</ng-template>
