import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Tables, TimeSlots } from "src/app/InterFace/category";
import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { DataService } from "src/app/Services/data.service";
import { EventdriverService } from "src/app/Services/eventdriver.service";
import { ToasterService, TOAST_TYPE } from "src/app/Services/toaster.service";
import { RefreshEvent, modalOpen } from "src/environments/environment";

@Component({
  selector: "app-addtables",
  templateUrl: "./addtables.component.html",
  styleUrls: ["./addtables.component.sass"],
})
export class AddtablesComponent implements OnInit {
  TablesItem: Tables;

  @ViewChild("txtSlotName") private txtSlotName: ElementRef;
  loading: boolean = false;
  constructor(
    private eventDriver: EventdriverService,
    private toasterService: ToasterService,
    private Api: ApiService,
    private Data: DataService,
    private common: CommonService
  ) {
    this.TablesItem = this.Data.TableModalData;
    if (this.TablesItem == undefined) {
      this.reset();
    }
  }
  reset() {
    this.TablesItem = {
      companyId: "",
      isAc: false,
      tableCaption: "",
      tableId: "",
      isArchived: false,
    };
  }
  ngOnInit(): void {}
  ngAfterViewInit() {
    this.txtSlotName.nativeElement.focus();
  }
  closeModal(val) {
    this.eventDriver.triggerEvent({
      IsClosed: val,
      Modal: RefreshEvent.Tables,
    });
    this.reset();
    if (val) this.txtSlotName.nativeElement.focus();
  }

  do__Save(val) {
    if (this.isValidObj()) {
      this.loading = true;
      this.TablesItem.companyId = this.Api.getCompanyId();
      this.Api.AddTables(this.TablesItem).subscribe(
        (data: any) => {
          this.loading = false;
          this.eventDriver.updated.next(RefreshEvent.Tables);
        

          if (data.statusCode==200) {
            this.toasterService.showToast(data.message, TOAST_TYPE.Success);
            this.closeModal(val);
            this.Data.TableModalData = undefined;
          } else {
            this.toasterService.showToast(data.message, TOAST_TYPE.Error);
          }
        },
        (err) => {
          console.log(err);
          this.loading = false;
        }
      );
    }
  }
  UpdateSlots() {
    if (this.isValidObj) {
      this.Api.updateTable(this.TablesItem).subscribe(
        (data: any) => {
         
          if (data.statusCode==200) {
            this.toasterService.showToast(data.message, TOAST_TYPE.Success);
            this.closeModal(true);
            this.Data.TableModalData = undefined;
          } else {
            this.toasterService.showToast(data.message, TOAST_TYPE.Error);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  isValidObj() {
    if (this.TablesItem.tableCaption.length == 0) {
      this.txtSlotName.nativeElement.focus();
      this.toasterService.showToast(
        "Please enter Table Captions to proceed",
        TOAST_TYPE.Warning
      );
      return false;
    }
    return true;
  }

  filesupdated() {
    var data = {
      pageid: modalOpen.Table,
      update: true,
    };
    this.eventDriver.updated.next(data);
  }
}
