<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()"
     (mouseout)="sidebarHover()">
  <div class="app-header__logo">
    <div class="logo-src">
      
      <!-- <img src="../../../../assets/images/digi_black.png" alt="" srcset=""> -->
    </div>
    <h2 *ngIf="!globals.toggleSidebar">Digi Menu</h2>
    <div class="header__pane ms-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
              [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar()">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true">
        <!-- <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <div class="vsm-item">
              <div class="vsm-header">Home</div>
            </div>
            <div class="vsm-dropdown" *ngIf="!isAdmin">
              <div class="vsm-list">
                <div class="vsm-item">
                  <a routerLink="dashboard" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                    <span class="vsm-title">Dashboard</span>
                  </a>
                </div>
              </div>
            </div> 
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="false" [activeIds]="extraParameter">
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Manage Masters</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="elementsMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-diamond"></i>
                    <span class="vsm-title">Masters</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list" *ngIf="!isAdmin">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="masters/managetables" class="vsm-link">
                          <span class="vsm-title">Manage Tables</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/masters/managecategory" class="vsm-link">
                          <span class="vsm-title">Manage Category</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/masters/manageslots" class="vsm-link">
                          <span class="vsm-title">Manage Slots</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/masters/manageproduct" class="vsm-link">
                          <span class="vsm-title">Manage Product</span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/masters/manageslider" class="vsm-link">
                          <span class="vsm-title">Manage Sliders</span>
                        </a>
                      </div>
                    </div>
                    <div class="vsm-dropdown" *ngIf="isAdmin">
                      <div class="vsm-list">
                        <div class="vsm-item">
                          <a routerLink="superadmin/managerestarunt" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                            <span class="vsm-title">Manage Subscription</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div> -->
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <!-- Home Section -->
            <div class="vsm-item" >
              <div class="vsm-header">Home</div>
            </div>
        
            <!-- Dashboard Section -->
            <div class="vsm-dropdown"  >
              <div class="vsm-list">
                <div class="vsm-item">
                  <a routerLink="dashboard" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                    <span class="vsm-title d-flex">
                      <svg xmlns="http://www.w3.org/2000/svg" style="width: 15px; " class="me-2" viewBox="0 0 512 512"><path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm320 96c0-26.9-16.5-49.9-40-59.3V88c0-13.3-10.7-24-24-24s-24 10.7-24 24V292.7c-23.5 9.5-40 32.5-40 59.3c0 35.3 28.7 64 64 64s64-28.7 64-64zM144 176a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm-16 80a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM400 144a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                       Dashboard</span>
                  </a>
                </div>
              </div>
            </div>
        
            <!-- Manage Masters Section -->
            <div class="vsm-item">
              <div class="vsm-header">Manage Masters</div>
            </div>
            <div class="vsm-dropdown">
              <div class="vsm-list" *ngIf="!isAdmin">
               
                <div class="vsm-item">
                  <a routerLinkActive="active-item" routerLink="/masters/managecategory" class="vsm-link">
                    <span class="vsm-title d-flex">
                      <svg xmlns="http://www.w3.org/2000/svg" style="width: 15px; " class="me-2" viewBox="0 0 512 512"><path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"/></svg>  Manage Category
                    </span>
                  </a>
                </div>
                <div class="vsm-item" *ngIf="Plan !=0">
                  <a routerLinkActive="active-item" routerLink="/masters/subcategory" class="vsm-link">
                    <span class="vsm-title d-flex">
                      <svg xmlns="http://www.w3.org/2000/svg" style="width: 15px; " class="me-2" viewBox="0 0 512 512"><path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"/></svg>  Manage SubCategory
                    </span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLinkActive="active-item" routerLink="/masters/manageslots" class="vsm-link">
                    <span class="vsm-title d-flex">
                      <svg xmlns="http://www.w3.org/2000/svg" style="width: 15px; " class="me-2" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>
                      Manage Slots</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLinkActive="active-item" routerLink="/masters/manageproduct" class="vsm-link">
                    <span class="vsm-title d-flex">
                      <svg xmlns="http://www.w3.org/2000/svg" style="width: 15px; " class="me-2" viewBox="0 0 448 512"><path d="M50.7 58.5L0 160H208V32H93.7C75.5 32 58.9 42.3 50.7 58.5zM240 160H448L397.3 58.5C389.1 42.3 372.5 32 354.3 32H240V160zm208 32H0V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192z"/></svg>
                      Manage Product</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLinkActive="active-item" routerLink="masters/managetables" class="vsm-link">
                    <span class="vsm-title d-flex">
                      <svg xmlns="http://www.w3.org/2000/svg" style="width: 15px; " class="me-2" viewBox="0 0 448 512"><path d="M416 0C400 0 288 32 288 176V288c0 35.3 28.7 64 64 64h32V480c0 17.7 14.3 32 32 32s32-14.3 32-32V352 240 32c0-17.7-14.3-32-32-32zM64 16C64 7.8 57.9 1 49.7 .1S34.2 4.6 32.4 12.5L2.1 148.8C.7 155.1 0 161.5 0 167.9c0 45.9 35.1 83.6 80 87.7V480c0 17.7 14.3 32 32 32s32-14.3 32-32V255.6c44.9-4.1 80-41.8 80-87.7c0-6.4-.7-12.8-2.1-19.1L191.6 12.5c-1.8-8-9.3-13.3-17.4-12.4S160 7.8 160 16V150.2c0 5.4-4.4 9.8-9.8 9.8c-5.1 0-9.3-3.9-9.8-9L127.9 14.6C127.2 6.3 120.3 0 112 0s-15.2 6.3-15.9 14.6L83.7 151c-.5 5.1-4.7 9-9.8 9c-5.4 0-9.8-4.4-9.8-9.8V16zm48.3 152l-.3 0-.3 0 .3-.7 .3 .7z"/></svg>
                      Manage Tables</span>
                  </a>
                </div>
                <div class="vsm-item">
                  <a routerLinkActive="active-item" routerLink="/masters/manageslider" class="vsm-link">
                    <span class="vsm-title d-flex">
                      <svg xmlns="http://www.w3.org/2000/svg" style="width: 15px; " class="me-2" viewBox="0 0 512 512"><path d="M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z"/></svg>Manage Sliders</span>
                  </a>
                </div>
              </div>
              <div class="vsm-dropdown" *ngIf="isAdmin">
                <div class="vsm-list">
                  <div class="vsm-item">
                    <a routerLink="superadmin/managerestarunt" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                      <span class="vsm-title d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" style="width: 15px; " class="me-2" viewBox="0 0 512 512"><path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"/></svg>  Manage Subscription</span>
                    </a>
                  </div>
                </div>
                <div class="vsm-list">
                  <div class="vsm-item">
                    <a routerLink="superadmin/currency" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                      <span class="vsm-title d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" style="width: 15px; " class="me-2" viewBox="0 0 512 512"><path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2c0 0 0 0 0 0s0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5l0 35.4c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336l0-35.4c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4l0 3.4 0 5.7 0 26.3zm32 0l0-32 0-25.9c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5l0 35.4c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5l0 35.4c0 44.2-86 80-192 80S0 476.2 0 432l0-35.4c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z"/></svg> Manage Currency</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </perfect-scrollbar>
    </div>
  </div>
</div>
