<div class="side-drawer-modal my-class">
    <app-sidebarload *ngIf="loading"></app-sidebarload>
    <div class="drawer-header modal-header">
        <h5 *ngIf="categoryItem.categoryId.length !==0">
            Update Category
        </h5>
        <h5 *ngIf="categoryItem.categoryId.length==0">
            Add Category
        </h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModal(true)">
        </button>
    </div>

    <div class="drawer-container">
        <div class="row">
            <div class="col-md-12">
                <div class="card-body">
                    <!-- <h5 class="card-title">Category Details</h5> -->

                    <div class="position-relative mb-3" *ngIf="Plan !=0">
                        <div class="file-upload-container" >
                            <!-- <i class="pe-7s-trash "></i> -->
                            <div class="file-upload-core" (click)="openUploader()">  
                                <img [src]="ImagePath+categoryItem.dpPath"  *ngIf="categoryItem.dpPath.length !==0"/>
                                <i class="pe-7s-cloud-upload fs-1 text-danger" *ngIf="categoryItem.dpPath.length==0"></i>
                                <span>Click to upload an image for the category</span>
                                <input type="file" hidden id="fileCDP" (change)="UploadImage($event)" accept="image/*"/>
                            </div>
                        </div>
                    </div>

                    <div class="position-relative mb-3">
                        <label for="exampleEmail" class="form-label">Category Name <span
                                class="mandate">*</span></label>
                        <input name="email" id="txtCategoryName"   #txtCategoryName="ngModel" [(ngModel)]="categoryItem.categoryName" placeholder="Enter Category Name" type="text"
                            class="form-control"  #categoryNameInput  [ngClass]="{ 'is-invalid':!txtCategoryName.value && txtCategoryName.touched }"  >
                    </div>
                    <!-- <div class="position-relative mb-3">
                        <label for="exampleEmail" class="form-label">Display Order <span
                                class="mandate">*</span></label>
                        <input name="email" id="txtCategoryName" (change)="validateDisplayOrder($event)"   (keypress)="common.allowonlynumeric($event)" #dispin="ngModel" [(ngModel)]="categoryItem.displayOrder" placeholder="Enter your Display Order" type="number"
                            class="form-control"  #DisplayInput  [ngClass]="{ 'is-invalid':!dispin.value && dispin.touched }"  >
                    </div> -->
                    <div class="position-relative mb-3">
                        <label for="exampleText" class="form-label">Description</label>
                        <textarea name="text" id="txtDescription" [(ngModel)]="categoryItem.description" placeholder="Enter Your Description Here"
                            class="form-control"></textarea>
                    </div>
                    <!-- <div class="position-relative mb-3">
                        <label class="wraper" for="something">
                            <div class="switch-wrap">
                              <input type="checkbox" id="something" [(ngModel)]="categoryItem.isActive" [checked]="categoryItem.isActive"  />
                              <div class="switch"></div>
                            </div>
                            <span class="label-text">{{categoryItem.isActive ? 'Active' : 'Inactive'}}</span>
                          </label>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="drawer-footer">
        <button type="button" class="btn-shadow align-items-center btn btn-lg btn-danger" (click)="closeModal(true)">
            <i class="fa fa-close"></i>&nbsp;
            Close
        </button>&nbsp;
        <button type="button" class="btn-shadow align-items-center btn btn-lg btn-primary me-2" (click)="do__Save(true)" *ngIf="categoryItem.categoryId.length==0">
            <i class="fa fa-floppy-o"></i>&nbsp;
            Save & Close
        </button>
        <button type="button" class="btn-shadow align-items-center btn btn-lg btn-success " (click)="do__Save(false)" *ngIf="categoryItem.categoryId.length==0">
            <i class="fa fa-floppy-o"></i>&nbsp;
            Save & New
        </button>
        <button type="button" class="btn-shadow align-items-center btn btn-lg btn-primary" (click)="updateCategory()" *ngIf="categoryItem.categoryId.length !==0">
            <i class="fa fa-floppy-o"></i>&nbsp;
            Update
        </button>
    </div>

</div>
<div class="overlayDrawer my-class"></div>
