import { Component, OnInit } from "@angular/core";
import { Product, ReorderModal } from "src/app/InterFace/category";
import { ApiService } from "src/app/Services/api.service";
import { CommonService } from "src/app/Services/common.service";
import { DataService } from "src/app/Services/data.service";
import { EventdriverService } from "src/app/Services/eventdriver.service";
import { TOAST_TYPE, ToasterService } from "src/app/Services/toaster.service";
import { DELETE_DESC, RefreshEvent, modalOpen } from "src/environments/environment";
import swal, { SweetAlertOptions, SweetAlertIcon } from "sweetalert2";
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css'; // Import the default DataTables CSS
@Component({
  selector: "app-manageproduct",
  templateUrl: "./manageproduct.component.html",
  styleUrls: ["./manageproduct.component.sass"],
})
export class ManageproductComponent implements OnInit {
  openModal: boolean = false;
  heading = "Manage Products";
  subheading = "Manage the Products you would like to organize.";
  icon = "pe-7s-box2 icon-gradient bg-happy-itmeo";
  pageid = modalOpen.ProductModal;
  ProductList: Product[]=[];
  SkletonLoader:boolean=true;
  Plan:any=0;
  openReorderModal:boolean=false;
  currency:string='';
  constructor(
    public common: CommonService,
    private data: DataService,
    private Api: ApiService,
    private toasterService: ToasterService,private eventDriver:EventdriverService
  ) {
    this.eventDriver.refreshevent().subscribe((data) => {
      if (data == RefreshEvent.Product) {
        this.ListProducts();
      }
    });
    this.data.getPlan();
    this.Plan= localStorage.getItem('PLAN'); 
      this.data.getPlan().then((data:any)=>{
        console.log(data)
      })

     this.currency=(localStorage.getItem('Currency')) || '₹';
  }

  ngOnInit(): void {
    this.ListProducts();
  }
  UserList: any = [];
  async loadDataTable() {
    // new DataTable('#example');
    const tableElement = $("#product");

    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable("#product")) {
      // Destroy the existing DataTable
      tableElement.DataTable().destroy();
      setTimeout(() => {
        $("#product").DataTable({
          pagingType: "full_numbers",
          pageLength: 5,
          processing: true,
          destroy: true,
          searching: false,
          lengthMenu: [5, 10, 25],
          dom: "Blfrtip", 
          columnDefs: [
            { 
              orderable: false, 
              targets: -1 // This targets the last column
            }
          ]
        } as any);
        setTimeout(() => {
          
          const searchInput = $('#product_filter input');
          // searchInput.wrap('<div class="search-wrapper"></div>');
          searchInput.after('<span class="searchTableIcon"><i class="fa fa-search"></i></span>');
        }, 100); // Use 'as any' to avoid type checking for this specific object
        this.SkletonLoader=false;
      }, 10);
      return
    } 
    setTimeout(() => {
      $("#product").DataTable({
        pagingType: "full_numbers",
        pageLength: 5,
        processing: true,
        lengthMenu: [5, 10, 25],
        dom: "Blfrtip", 
        columnDefs: [
          { 
            orderable: false, 
            targets: -1 // This targets the last column
          }
        ]
      } as any);
      setTimeout(() => {
        this.SkletonLoader=false;
        const searchInput = $('#product_filter input');
        // searchInput.wrap('<div class="search-wrapper"></div>');
        searchInput.after('<span class="searchTableIcon"><i class="fa fa-search"></i></span>');
      }, 100); // Use 'as any' to avoid type checking for this specific object
    }, 10);
  }
  openModel() {
    this.eventDriver.triggerEvent({
      IsClosed: false,
      Modal: RefreshEvent.Product,
    });
  }
  closeModal(ev){
    var obj:ReorderModal=JSON.parse(ev);
    if(obj.refresh){
      this.ListProducts();
    }
     this.openReorderModal= obj.modal; 
 }
 openReOrderModal(){
  this.openReorderModal=false;
  setTimeout(() => {
    this.openReorderModal=true;      
  }, 10);

}
  do__Delete(id) {
    let soption: SweetAlertOptions = {
      title:`${DELETE_DESC.title}`,
      text: `${DELETE_DESC.desc} ${id.productName} !`,
      icon: "error",
      showConfirmButton: true,
      showCancelButton: true,
    };

    swal.fire(soption).then((willDelete) => {
      if (willDelete.value) {
        this.Deleteproduct(id.productId);
      } else {
        // swal.fire("Fail");
      }
    });
  }
  TableLoad: string = "";
  ListProducts() {
 this.SkletonLoader=true;
    this.Api.getProducts().subscribe(
      (data: any) => {
        this.common.dismissloading();
        if (data.statusCode == 200) {
          this.ProductList = data.data;
          this.data.ProductList=data.data;
           
          if(this.ProductList.length !=0){
            this.data.dpOrder=this.ProductList[this.ProductList.length -1].displayOrder +1;
          }
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
        this.loadDataTable();
       
      },
      (err) => {
        console.log(err);
        this.common.dismissloading();
      }
    );
  }
  Editproduct(item: any) {
    this.data.ProductModalData = item;
    this.openModel();
  }
  changeStatus(item) {
    this.common.showloading();
    this.Api.updateProduct(item).subscribe(
      (data: any) => {
        
        this.common.dismissloading();
      
        if (data.statusCode == 200) {
          this.ListProducts();
          // this.toasterService.showToast(data.message, TOAST_TYPE.Success);
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
        this.common.dismissloading();
        console.log(err);
      }
    );
  }
  Deleteproduct(id: any) {
    this.common.showloading();
    this.Api.deleteProduct(id).subscribe(
      (data: any) => { 
        this.common.dismissloading();
        if (data.statusCode == 200) {
          this.toasterService.showToast(data.message, TOAST_TYPE.Warning);
          this.ListProducts();
        } else {
          this.toasterService.showToast(data.message, TOAST_TYPE.Error);
        }
      },
      (err) => {
        this.common.dismissloading();
        console.log(err);
      }
    );
  }
}
